import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import Modal from 'react-modal';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';




const LeadContact = () => {
  const [leads, setLeads] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [editingLeadId, setEditingLeadId] = useState(null);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [actionMenuVisible, setActionMenuVisible] = useState(null);

  const navigate = useNavigate();
  Modal.setAppElement('#root'); // Set the root element for accessibility
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);



  // Handle File Selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  // Handle File Upload
  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file.');
      return;
    }


    const token = localStorage.getItem('authToken'); // Fetch token from localStorage
    const formData = new FormData();
    formData.append('file', selectedFile);


    try {
      const response = await fetch('https://api.myntask.com/leads/lead_import', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Assuming Bearer token
        },
        body: formData, // FormData includes the file
      });


      if (response.ok) {
        const data = await response.json();
        setUploadStatus('File uploaded successfully!');
        console.log('API Response:', data);
      } else {
        setUploadStatus('Failed to upload the file.');
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      setUploadStatus('An error occurred during the upload.');
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    const fetchLeads = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;

      try {
        const response = await fetch('https://api.myntask.com/leads/list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch leads');
        const data = await response.json();
        setLeads(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching leads:', err);
        setError('Failed to fatch leads.');
        setIsLoading(false);
      }
    };




    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;




      try {
        const response = await fetch('https://api.myntask.com/leads/status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) throw new Error('Failed to fetch status options');
        const data = await response.json();
        setStatusOptions(data);
      } catch (err) {
        console.error('Error fetching status options:', err);
      }
    };
    const fetchMembers = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;




      try {
        const response = await fetch('https://api.myntask.com/employee/member', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) throw new Error('Failed to fetch members');
        const data = await response.json();
        setMembers(data);
      } catch (err) {
        console.error('Error fetching members:', err);
      }
    };




    fetchLeads();
    fetchStatusOptions();
    fetchMembers();
  }, []);


  const handleStatusChange = async (leadId, status) => {
    const lead = leads.find((lead) => lead.id === leadId);

    // Check if a member is assigned
    if (!selectedMember[leadId]) {
      Swal.fire({
        icon: 'error',
        title: 'Assign Lead First',
        text: 'You need to assign the lead before changing the status.',
        timer: 2000, // Auto-dismiss after 2 seconds
        showConfirmButton: false,
      });
      return;
    }

    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Authorization token is missing!',
        timer: 2000, // Auto-dismiss after 2 seconds
        showConfirmButton: false,
      });
      return;
    }

    try {
      const requestData = {
        lead_id: leadId,
        status: status,
      };

      const response = await fetch('https://api.myntask.com/leads/status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update status');
      }

      // SweetAlert success notification
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Status updated successfully!',
        timer: 1500, // Auto-dismiss after 1.5 seconds
        showConfirmButton: false,
      });

      setEditingLeadId(null);

      // Update the leads state with the new status
      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === leadId ? { ...lead, status: status } : lead
        )
      );
    } catch (err) {
      console.error('Error updating status:', err);

      // SweetAlert error notification
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message || 'Failed to update status. Please try again.',
        timer: 1500, // Auto-dismiss after 1.5 seconds
        showConfirmButton: false,
      });
    }
  };

  const handleMemberChange = async (leadId, memberId) => {


    setSelectedMember((prevSelected) => ({
      ...prevSelected,
      [leadId]: memberId,
    }));


    const requestData = {
      assign_id: parseInt(memberId),
      lead_id: parseInt(leadId),
    };


    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Authorization token is missing!',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/leads/lead_assign', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });


      if (!response.ok) throw new Error('Failed to assign member');


      // SweetAlert success notification
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'You assigned the member successfully!',
        timer: 1500, // Auto-dismiss after 2 seconds
        showConfirmButton: false,
      });
    } catch (err) {
      console.error('Error assigning member:', err);


      // SweetAlert error notification
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to assign member. Please try again.',
        timer: 1500, // Auto-dismiss after 2 seconds
        showConfirmButton: false,
      });
    }
  };
  const toggleActionMenu = (leadId) => {
    setActionMenuVisible((prevVisible) =>
      prevVisible === leadId ? null : leadId
    );
  };


  const handleDeleteClick = async (leadId) => {
    try {
      // Ask for confirmation using SweetAlert
      const confirmResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this lead? This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });


      // If user cancels, exit the function
      if (!confirmResult.isConfirmed) {
        return;
      }


      // Retrieve token from localStorage
      const token = localStorage.getItem('authToken');
      if (!token) {
        Swal.fire({
          title: 'Error',
          text: 'Authorization token is missing!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }


      // Make the DELETE API call
      const response = await fetch(`https://api.myntask.com/leads/lead_delete/${leadId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      // Handle API response
      if (response.ok) {
        Swal.fire({
          title: 'Deleted!',
          text: 'The lead has been deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });


        // Refresh the screen after success
        setTimeout(() => {
          window.location.reload(); // Refreshes the current page
        }, 1500); // Add a slight delay to allow the alert to show
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: 'Error',
          text: `Failed to delete lead: ${errorData.message || 'Unknown error'}`,
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the lead.',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      console.error('Error:', error);
    }
  };


  const toTitleCase = (str) => {
    if (!str) return 'N/A'; // Handle empty or undefined strings
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  const renderSortIcon = (key) => {
    if (sortConfig?.key === key) {
      if (sortConfig?.direction === 'asc') {
        return <span>↑</span>; // Upward arrow for ascending
      }
      return <span>↓</span>; // Downward arrow for descending
    }
    return <span>↕</span>; // Default sorting icon (unsorted)
  };


  const sortedLeads = [...leads].sort((a, b) => {
    if (sortConfig.key) {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle sorting for specific data types
      if (sortConfig.key === 'id' || sortConfig.key === 'lead_value') {
        // Numeric sorting
        aValue = aValue || 0; // Treat null as 0
        bValue = bValue || 0;
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (sortConfig.key === 'created_at') {
        // Date sorting
        aValue = new Date(aValue);
        bValue = new Date(bValue);
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        // String sorting
        aValue = (aValue || '').toString().toLowerCase();
        bValue = (bValue || '').toString().toLowerCase();
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      }
    }
    return 0;
  });


  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }




  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Leads
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={() => navigate('/addLead')}>
            Add Lead
          </button>
          <button className="color-button" onClick={openModal}>
            Import
          </button>


          {/* Modal for file upload */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="File Upload Modal"
            style={{
              content: {
                width: '400px',
                height: '250px',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '10px',
                padding: '20px',
                border: '1px solid #ccc',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              },
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.3)', // Makes the background dim but still visible
              },
            }}
          >
            <h1 className='title'>Leads
              <div className="underline"></div>
            </h1>
            <div style={{ marginBottom: '30px', marginTop: '15px' }}>
              <label className='label'>
                Select File
              </label>
              <input
                type="file"
                id="fileUpload"
                onChange={handleFileChange}
                className='input'
              />
            </div>
            <div className='buttons-row'>
              <a
                href="/Files/leads-sample.xlsx"
                download="leads-sample.xlsx"
                className='color-button'
              >
                Sample File
              </a>
              <button
                onClick={handleFileUpload}
                className='color-button'
              >
                Submit
              </button>
              <button className='border-button' onClick={closeModal} >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      </div>


      {sortedLeads.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('id')}>
                Lead Id
                {renderSortIcon('id')}
              </th>
              <th onClick={() => handleSort('client_name')}>
                Lead Name
                {renderSortIcon('client_name')}
              </th>
              <th onClick={() => handleSort('company_name')}>
                Company
                {renderSortIcon('company_name')}
              </th>
              <th onClick={() => handleSort('status')}>
                Status
                {renderSortIcon('status')}
              </th>
              <th onClick={() => handleSort('name')}>
                Assign To
                {renderSortIcon('name')}
              </th>
              <th onClick={() => handleSort('lead_value')}>
                Lead Value
                {renderSortIcon('lead_value')}
              </th>
              <th>Action</th>
            </tr>
          </thead>


          <tbody>
            {sortedLeads.map((lead) => (
              <tr key={lead.id}>
                <td>{lead.id || 'N/A'}</td>
                <td onClick={() => navigate(`/leadProfile/${lead.id}`)}>
                  {toTitleCase(lead.client_name)}
                </td>
                <td>{toTitleCase(lead.company_name)}</td>
                <td>
                  <select
                    value={lead.status || ''}
                    onChange={(e) => handleStatusChange(lead.id, e.target.value)}
                    className="table-filter"
                  >
                    <option value="">Select Status</option>
                    {statusOptions.map((status) => (
                      <option key={status} value={status}>
                        {toTitleCase(status)}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={selectedMember[lead.id] || ''}
                    onChange={(e) => handleMemberChange(lead.id, e.target.value)}
                    className="table-filter"
                  >
                    <option value="">Select Member</option>
                    {members.map((member) => (
                      <option key={member.id} value={member.id}>
                        {toTitleCase(member.member_name)}
                      </option>
                    ))}
                  </select>
                </td>
                <td>{lead.lead_value || 'N/A'}</td>
                <td className="action-container">
                  <div
                    className="action-dots"
                    onClick={() => toggleActionMenu(lead.id)}
                  >
                    <GridMoreVertIcon />
                  </div>
                  {actionMenuVisible === lead.id && (
                    <div className="action-menu">
                      <div
                        className="action-item"
                        onClick={() => navigate(`/editLead/${lead.id}`)}
                      >
                        Edit
                      </div>
                      <div
                        className="action-item"
                        onClick={() => handleDeleteClick(lead.id)}
                      >
                        Delete
                      </div>
                      <div
                        className="action-item"
                        onClick={() => navigate(`/leadProfile/${lead.id}`)}
                      >
                        View
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No leads available.</p>
      )}

    </div>
  );
};




export default LeadContact;






