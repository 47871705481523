// src/ClientVisit.js

import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography, Modal } from '@mui/material';


const ClientVisit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [image, setImage] = useState(null); // Store the image file object
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [clientVisits, setClientVisits] = useState([]); // State to store client visit data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [actionMenuVisible, setActionMenuVisible] = useState(null);


  // Function to open the modal
  const openModal = () => setIsModalOpen(true);


  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);


  // Fetch data from API when component mounts or after adding data
  const fetchClientVisits = async () => {
    const token = localStorage.getItem('authToken'); // Get token from local storage
    if (!token) {
      alert('Authorization token is missing!');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/home/client_visit_list', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch client visit list');
      }


      const data = await response.json();
      setClientVisits(data); // Set the fetched data in the state
      setIsLoading(false); // Stop loading once data is fetched
    } catch (error) {
      console.error('Error fetching client visits:', error);
      // setError("Failed to load ClientVisit.");
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchClientVisits();
  }, []);


  // Automatically fetch geolocation (latitude, longitude)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude); // Set the latitude
          setLongitude(longitude); // Set the longitude
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, []);


  // Handle form submission using fetch API
  const handleSubmit = async (e) => {
    e.preventDefault();


    const token = localStorage.getItem('authToken'); // Get token from local storage


    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authorization Error',
        text: 'Authorization token is missing!',
      });
      return;
    }


    const formData = new FormData();
    formData.append('client_name', clientName);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);


    // Append the image file if one is selected
    if (image) {
      formData.append('image', image);
    }


    try {
      const response = await fetch('https://api.myntask.com/home/client_visit', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });


      const responseText = await response.text(); // Get the response as text
      console.log('API Response:', responseText);


      // If the response is okay (status code 200-299)
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Client visit added successfully!',
        });
        closeModal(); // Close modal after successful submission


        // Fetch the updated client visits after successful submission
        fetchClientVisits();  // Automatically refetch the data
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add client visit!',
      });
    }
  };


  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Set the selected file
    }
  };


  const handleDelete = async (visitId) => {
    const token = localStorage.getItem('authToken'); // Get token from local storage

    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authorization Error',
        text: 'Authorization token is missing!',
      });
      return;
    }

    // Add confirmation dialog
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this client visit? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (!confirmation.isConfirmed) {
      // User canceled the deletion
      return;
    }

    try {
      const response = await fetch(`https://api.myntask.com/home/client_visit_delete/${visitId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Log response for debugging
      console.log('Response status:', response.status);
      const responseBody = await response.json();
      console.log('Response body:', responseBody);

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Deleted',
          text: 'Client visit successfully deleted!',
        });
        fetchClientVisits(); // Refresh the data
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: responseBody.message || 'Failed to delete the client visit!',
        });
      }
    } catch (error) {
      console.error('Error deleting client visit:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete the client visit!',
      });
    }
  };

  const toggleActionMenu = (visitId) => {
    setActionMenuVisible((prevVisible) =>
      prevVisible === visitId ? null : visitId
    );
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>
          Client Visit
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={openModal}>
            Add client visit
          </button>
        </div>
      </div>


      <table>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Visited On</th>
            <th>Client Name</th>
            <th>Location</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {clientVisits.length > 0 ? (
            clientVisits.map((visit) => (
              <tr key={visit.id}>
                <td>{visit.user_name}</td>
                <td >{visit.created_at}</td>
                <td>{visit.client_name}</td>
                <td>
                  <span
                    onClick={() => window.open(visit.location, '_blank', 'noopener,noreferrer')}
                    style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'none', fontWeight: "600" }}
                  >
                    View Location
                  </span>
                </td>
                <td>
                  {visit.image ? (
                    <span
                      onClick={() => window.open(visit.image, '_blank', 'noopener,noreferrer')}
                      style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'none', fontWeight: "600" }}
                    >
                      View Image
                    </span>
                  ) : (
                    'No Image'
                  )}
                </td>

                <td className="action-container">
                  <div className="action-dots" onClick={() => toggleActionMenu(visit.id)}>
                    <GridMoreVertIcon />
                  </div>
                  {actionMenuVisible === visit.id && (
                    <div className="action-menu">
                      <div
                        onClick={() => handleDelete(visit.id)} // Pass visit.id to handleDelete

                      >
                        Delete
                      </div>

                    </div>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: 'center', padding: '20px' }}>
                No client visits available.
              </td>
            </tr>
          )}
        </tbody>


      </table>


      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="add-client-visit-modal"
        aria-describedby="modal-to-add-client-visit-details"
      >
        <Box
          className='designation-modal'
        >
          <div className='designation-header'>
            <h1 className="designation-title" >
              Add Client Visit
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={closeModal}
            />
          </div>


          <img
            src={designationImage}
            alt="Designation"
            className='designation-image'
          />

          <div className='designation-form'>
            <label
              className='designation-label'
            >
              Client Name
            </label>
            <input
              type="text"
              id="clientName"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              placeholder="Enter client name"
              required
              className='designation-input'
            />


            {/* Image upload */}
            <label
              className='designation-label'
            >
              Select Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange} // Handle the file change
              accept="image/*" // Restrict to images only
              className='designation-input'
            />
          </div>

          <button
            className='designation-save'
            onClick={handleSubmit}
          >
            Save
          </button>
        </Box>
      </Modal>
    </div>
  );
};


export default ClientVisit;






