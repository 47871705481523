import React, { useEffect, useState } from 'react';
import { Modal, Box,Button} from '@mui/material';
import Swal from 'sweetalert2';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';


function EditDesignationModal({ open, handleClose, designationId, onDesignationUpdated}) {
  const [designation, setDesignation] = useState({ name: '', parent_id: '' });
  const [parents, setParents] = useState([]);


  useEffect(() => {
    // Fetch the current designation details
    const fetchDesignationDetails = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;


      try {
        const response = await fetch(`https://api.myntask.com/employee/designation_edit/${designationId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
            setDesignation({ name: data[0].name, parent_id: data[0].parent_id || '' });
          }
        } else {
          console.error('Failed to fetch designation details:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch designation details:', error);
      }
    };


    // Fetch the list of all designations for the dropdown
    const fetchParentOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;


      try {
        const response = await fetch(`https://api.myntask.com/employee/designation`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (response.ok) {
          const data = await response.json();
          setParents(data);
        } else {
          console.error('Failed to fetch designations:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch designations:', error);
      }
    };


    if (designationId) {
      fetchDesignationDetails();
    }
    fetchParentOptions();
  }, [designationId]);


  const handleSave = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;
  
    const payload = {
      designation_id: designationId,
      name: designation.name,
      parent_id: designation.parent_id || 0, // Set parent_id to 0 if it is null or undefined
    };
  
    try {
      const response = await fetch(`https://api.myntask.com/employee/designation_update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        // Show success alert
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Designation updated successfully!',
       
        });
  
        handleClose();
        onDesignationUpdated(); // Call the fetch function to refresh the designation list
        // Optionally refresh the designation list after saving
      } else {
        const errorMessage = await response.text(); // Get error message from response
        console.error('Failed to save designation:', errorMessage);
        
        // Show error alert
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: `Failed to save designation: ${errorMessage}`,
        
        });
      }
    } catch (error) {
      console.error('Failed to save designation:', error);
  
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An unexpected error occurred while saving designation.',
      
      });
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDesignation((prev) => ({ ...prev, [name]: value }));
  };


  return (
    <Modal open={open} onClose={handleClose}>
     <Box className='designation-modal'>
      <div className='designation-header'>
      <h1 className="designation-title" >
        Edit Designation
      </h1> 
      <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className='designation-close'
        onClick={handleClose}
      />
    </div>
    <img
      src={designationImage}
      alt="Designation"
      className='designation-image'
    />
     
     <div className='designation-form'>
      <label className='designation-label'>Designation Name</label>
      <input
        type="text"
        id="name"
        name="name"
        value={designation.name}
        onChange={handleChange}
        className='designation-input'
      />
  
      <label className='designation-label'>Parent Designation</label>
      <select
        id="parent_id"
        name="parent_id"
        value={designation.parent_id}
        onChange={handleChange}
        className='designation-input'
      >
        <option value="">None</option>
        {parents.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
  
      <button
     className='designation-save'
     nClick={handleSave}
    >
      Save
    </button>
      </div>
    </Box>
  </Modal>
  
  );
}


export default EditDesignationModal;
