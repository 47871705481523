import React, { useState, useEffect } from 'react';
import {Modal,Box } from '@mui/material';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';
import Swal from "sweetalert2";


const TaskSetting = () => {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


    const fetchCategories = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch('https://api.myntask.com/task/task_category', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Add your token here
            'Content-Type': 'application/json', // Optional
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        setCategories(data); // Assuming the API response is a JSON array of categories
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };


  useEffect(() => {
    fetchCategories();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewCategoryName(""); // Clear the input field when closing modal
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");

    const data = {
      name: newCategoryName,
    };

    try {
      const response = await fetch("https://api.myntask.com/task/task_category_add", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to add category");
      }

      fetchCategories();

       // Close the modal
       closeModal();
      // Success alert
      Swal.fire({
        title: "Success!",
        text: "Category added successfully.",
        icon: "success",
        toast: true,
        position: "top-right",
        timer: 2000,
        showConfirmButton: false,
      });

     
    } catch (error) {
      console.error("Error adding category:", error);

      // Error alert
      Swal.fire({
        title: "Error",
        text: "Failed to add category. Please try again.",
        icon: "error",
        toast: true,
        position: "top-right",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch(`https://api.myntask.com/task/task_category_delete/${categoryId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete category");
      }

      fetchCategories();
      // Success alert
    Swal.fire({
        title: "Deleted!",
        text: "Category deleted successfully.",
        icon: "success",
        toast: true,
        position: "top-right",
        timer: 2000,
        showConfirmButton: false,
      });

     
    } catch (error) {
      console.error("Error deleting category:", error);

      // Error alert
      Swal.fire({
        title: "Error",
        text: "Failed to delete category. Please try again.",
        icon: "error",
        toast: true,
        position: "top-right",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
 
  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Task Category Setting
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={openModal}>
            Add Task Category
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Task Categories</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.category_name}</td>
              <td><span onClick={() => handleDeleteCategory(category.id)} style={{ cursor: "pointer", color: "red" }}>
  Delete
</span>
</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
  open={isModalOpen}
  onClose={closeModal}

>
  <Box className="designation-modal">
    {/* Header with title and close button */}
    <div className="designation-header">
      <h1 className="designation-title">Add New Category</h1>
      <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className="designation-close"
        onClick={closeModal}
      />
    </div>

    {/* Form Section */}
    <div className="designation-form">
    
        <label className="designation-label" htmlFor="newCategoryName">
          Category Name:
        </label>
        <input
          type="text"
          id="newCategoryName"
          name="newCategoryName"
          value={newCategoryName}
          className="designation-input"
          onChange={(e) => setNewCategoryName(e.target.value)}
        />

          <button onClick={handleAddCategory} className="designation-save">
            Add Category
          </button>
         
    
    </div>
  </Box>
</Modal>

    </div>
  );
};

export default TaskSetting;
