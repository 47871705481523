import React, { useState, useEffect } from 'react';
import {
  Box,
  Pagination,
  Stack, Typography

} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Vortex } from 'react-loader-spinner';

const TaskReport = () => {
  const [taskReports, setTaskReports] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const tasksPerPage = 10;

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.myntask.com/report/task', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log('Response:', data);
        setTaskReports(data.task_list || []);
        setFilteredTasks(data.task_list || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching task report:', error);
        setError("Failed to Fetch Data");
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filtered = taskReports.filter(
      (task) =>
      (task.task?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        task.code?.toLowerCase().includes(event.target.value.toLowerCase()))
    );
    setFilteredTasks(filtered);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const totalTasks = filteredTasks.length;
  const displayedTasks = filteredTasks.slice((currentPage - 1) * tasksPerPage, currentPage * tasksPerPage);

  const taskStatusCount = {
    Completed: taskReports.filter(task => task.status === 'Completed').length,
    Incomplete: taskReports.filter(task => task.status === 'Incomplete').length,
    ToDo: taskReports.filter(task => task.status === 'To Do').length,
  };

  const pieData = [
    { name: 'Completed', value: taskStatusCount.Completed },
    { name: 'Incomplete', value: taskStatusCount.Incomplete },
    { name: 'To Do', value: taskStatusCount.ToDo },
  ];

  const COLORS = ['#00C49F', '#FFBB28', '#FF8042'];

  const toTitleCase = (str) => {
    if (!str) return 'N/A';  // Check for undefined or null values
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const truncateWords = (str, wordLimit) => {
    const words = str.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'; // Show only first 10 words with "..."
    }
    return str; // If less than 10 words, show full string
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Task Report
          <div className="underline"></div>
        </h1>
      </div>

      <Box sx={{ marginBottom: '20px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px' }}>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Box>


      <div className="top-controls">
        <div className="filters-and-search">

          <input
            type="text"
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Task</th>
            <th>Project</th>
            <th>Due Date</th>
            <th>Assigned To</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {displayedTasks.map((task) => (
            <tr key={task.task_id}>
              <td>{task.code || 'N/A'}</td>
              <td>{toTitleCase(truncateWords(task.task || 'N/A', 10))}</td>
              <td>{toTitleCase(task.project || 'N/A')}</td>
              <td >{task.due_date || 'N/A'}</td>
              <td>
                {task.assigned_users && task.assigned_users.length > 0 ? (
                  task.assigned_users.map((user) => (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                      <img
                        key={user.user_id}
                        src={user.image}
                        alt={toTitleCase(user.name)}
                        className="image"
                      />
                      <div>
                        <span style={{ cursor: 'pointer' }}>
                          {user.name || 'N/A'}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  'N/A'
                )}
              </td>
              <td>{toTitleCase(task.status || 'N/A')}</td>
            </tr>
          ))}
        </tbody>
      </table>



      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', paddingBottom: '20px' }}>
        <Pagination
          count={Math.ceil(totalTasks / tasksPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default TaskReport;
