import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar, Button, Box, Typography, Grid, Chip, Tabs, Tab, Paper,Checkbox, Modal } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify'; // For notifications
import 'react-toastify/dist/ReactToastify.css';
import iconImage from '../assets/Icon.png';
import designationImage from '../assets/designation.png';



const TaskDetail = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [taskDetails, setTaskDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [files, setFiles] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [comments, setComments] = useState([]); // State for comments
  const [modalOpen, setModalOpen] = useState(false); // For adding a subtask
  const [commentModalOpen, setCommentModalOpen] = useState(false); // For adding a comment
  const [newComment, setNewComment] = useState(''); // State for new comment
  const [members, setMembers] = useState([]); // To store employee members


  // Separate state for the modal input fields
  const [newSubtaskTitle, setNewSubtaskTitle] = useState('');
  const [newSubtaskStartDate, setNewSubtaskStartDate] = useState('');
  const [newSubtaskDueDate, setNewSubtaskDueDate] = useState('');
  const [newSubtaskAssignedTo, setNewSubtaskAssignedTo] = useState('');
  const [newSubtaskDescription, setNewSubtaskDescription] = useState('');


  // Fetch task details, files, subtasks, and comments
  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');


        // Parallel Fetch Task Details, Files, Subtasks, Comments, and Members
        const [taskRes, fileRes, subtaskRes, commentRes, memberRes] = await Promise.all([
          fetch(`https://api.myntask.com/task/task_view/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch(`https://api.myntask.com/task/file_list/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch(`https://api.myntask.com/task/sub_task_list/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch(`https://api.myntask.com/task/comments/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch('https://api.myntask.com/employee/member', {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
        ]);


        if (!taskRes.ok || !fileRes.ok || !subtaskRes.ok || !commentRes.ok || !memberRes.ok) {
          throw new Error('Failed to fetch task details or files.');
        }


        const taskData = await taskRes.json();
        const fileData = await fileRes.json();
        const subtaskData = await subtaskRes.json();
        const commentData = await commentRes.json();
        const membersData = await memberRes.json();


        console.log('Comments API response:', commentData); // Added console log for comment API response


        setTaskDetails(taskData[0]);
        setFiles(fileData);
        setSubtasks(subtaskData);
        setComments(commentData);
        setMembers(membersData);
      } catch (error) {
        console.error('Error fetching task details or files:', error);
        setError('Failed to fetch task details or files.');
      } finally {
        setLoading(false);
      }
    };


    if (taskId) {
      fetchTaskDetails();
    }
  }, [taskId]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const handleModalOpen = () => {
    setModalOpen(true);
  };


  const handleModalClose = () => {
    setModalOpen(false);
    clearModalFields(); // Reset modal fields when modal is closed
  };


  const handleCommentModalOpen = () => {
    setCommentModalOpen(true);
  };


  const handleCommentModalClose = () => {
    setCommentModalOpen(false);
    setNewComment(''); // Clear comment field when modal is closed
  };


  const refreshSubtasks = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const subtaskResponse = await fetch(`https://api.myntask.com/task/sub_task_list/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const subtaskData = await subtaskResponse.json();
      setSubtasks(subtaskData);
    } catch (error) {
      console.error('Error refreshing subtasks:', error);
    }
  };


  const refreshComments = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const commentResponse = await fetch(`https://api.myntask.com/task/comments/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const commentData = await commentResponse.json();
      setComments(commentData);
    } catch (error) {
      console.error('Error refreshing comments:', error);
    }
  };


  const handleSaveComment = async () => {
    const token = localStorage.getItem('authToken');
    const payload = {
      task_id: taskId,
      comment: newComment,
    };


    try {
      const response = await fetch('https://api.myntask.com/task/comment_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });


      if (!response.ok) throw new Error('Failed to save comment');


      toast.success('Comment saved successfully');
      refreshComments(); // Refresh comments after saving
      handleCommentModalClose(); // Close the comment modal
    } catch (error) {
      console.error('Error saving comment:', error);
      toast.error('Failed to save comment');
    }
  };


  const clearModalFields = () => {
    setNewSubtaskTitle('');
    setNewSubtaskStartDate('');
    setNewSubtaskDueDate('');
    setNewSubtaskAssignedTo('');
    setNewSubtaskDescription('');
  };


  const handleSaveSubtask = async () => {
    const token = localStorage.getItem('authToken');
    const payload = {
      task_id: taskId,
      title: newSubtaskTitle,
      start_date: newSubtaskStartDate,
      due_date: newSubtaskDueDate,
      assigned_to: newSubtaskAssignedTo,
      description: newSubtaskDescription,
    };


    try {
      const response = await fetch('https://api.myntask.com/task/sub_task_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });


      if (!response.ok) throw new Error('Failed to save subtask');


      toast.success('Subtask saved successfully');
      refreshSubtasks(); // Refresh subtasks after saving
      handleModalClose(); // Close the modal
    } catch (error) {
      console.error('Error saving subtask:', error);
      toast.error('Failed to save subtask');
    }
  };


  if (loading) {
    return <Typography>Loading...</Typography>;
  }


  if (error) {
    return (
      <Box textAlign="center">
        <Typography color="error">{error}</Typography>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
      </Box>
    );
  }


  return (
    <Box sx={styles.container}>
      <ToastContainer /> {/* Toast notification container */}
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" sx={styles.header}>
          Task #{taskDetails.code}
        </Typography>
        {/* <Button variant="contained" color="primary" sx={styles.completeButton}>
          Mark As Complete
        </Button> */}
      </Box>


      {/* <Typography variant="h5" gutterBottom sx={styles.taskTitle}>
        {taskDetails.task}
      </Typography> */}


      <Grid container spacing={3}>
  {/* Task Details Container */}
  <Grid item xs={12} md={8}>
    <Paper sx={{ ...styles.paper, padding: "30px", borderRadius: "10px" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "20px", color: "#333" }}>
        {taskDetails.task || "Task Title"}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {/* Priority */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography sx={{ fontWeight: "bold", color: "#666", fontSize: "14px" }}>Priority:</Typography>
          <Chip
            label={taskDetails.priority || "Normal"}
            sx={{
              backgroundColor: taskDetails.priority === "High" ? "#FF4D4D" : "#007bff",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          />
        </Box>


        {/* Assignees */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography sx={{ fontWeight: "bold", color: "#666", fontSize: "14px" }}>Assignees:</Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {(taskDetails.assigned_users || []).map((user) => (
              <Chip
                key={user.id}
                avatar={<Avatar src={user.image} alt={user.name} />}
                label={user.name}
                sx={{
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #ddd",
                  fontSize: "14px",
                }}
              />
            ))}
          </Box>
        </Box>


        {/* Deadline */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography sx={{ fontWeight: "bold", color: "#666", fontSize: "14px" }}>Deadline:</Typography>
          <Typography sx={{ fontWeight: "bold", color: "#333", fontSize: "14px" }}>
            {taskDetails.due_date ? new Date(taskDetails.due_date).toLocaleDateString() : "N/A"}
          </Typography>
        </Box>


        {/* Tags */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography sx={{ fontWeight: "bold", color: "#666", fontSize: "14px" }}>Tags:</Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {(taskDetails.tags || []).map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                sx={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  fontSize: "12px",
                }}
              />
            ))}
            <Chip
              label="Add more"
              sx={{
                backgroundColor: "#f5f5f5",
                color: "#007bff",
                border: "1px dashed #ddd",
                fontSize: "12px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  </Grid>


  {/* Status Box */}
 


<Grid item xs={12} md={4}>
  <Paper
    sx={{
      ...styles.statusPaper,
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#f9f9f9",
      textAlign: "center",
    }}
  >
    <Typography
      sx={{
        ...styles.label,
        fontWeight: "bold",
        fontSize: "16px",
        color: "#555",
        marginBottom: "20px",
      }}
    >
      <strong>Status:</strong>
    </Typography>


    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        marginBottom: "20px",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={
          taskDetails.status === "Incomplete"
            ? 10
            : taskDetails.status === "ToDo"
            ? 30
            : taskDetails.status === "Doing"
            ? 50
            : taskDetails.status === "Completed"
            ? 100
            : 0
        }
        size={80}
        thickness={8}
        sx={{
          color:
            taskDetails.status === "Completed"
              ? "#4caf50"
              : taskDetails.status === "Doing"
              ? "#ffa726"
              : taskDetails.status === "ToDo"
              ? "#29b6f6"
              : "#f44336",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "14px", color: "#007bff" }}
        >
          {taskDetails.status}
        </Typography>
      </Box>
    </Box>


    <Typography sx={{ ...styles.label, fontSize: "14px", color: "#333" }}>
      Start Date: {taskDetails.start_date ? new Date(taskDetails.start_date).toLocaleDateString() : "N/A"}
    </Typography>
    <Typography sx={{ ...styles.label, fontSize: "14px", color: "#e63946" }}>
      Due Date: {taskDetails.due_date ? new Date(taskDetails.due_date).toLocaleDateString() : "N/A"}
    </Typography>
  </Paper>
</Grid>




</Grid>






      {/* Tabs Section */}
      <Box mt={4}>
  <Tabs
    value={selectedTab}
    onChange={handleTabChange}
    sx={{
      ...styles.tabs,
      "& .MuiTab-root": {
        fontSize: "16px",
        fontWeight: "bold",
        textTransform: "none",
        color: "#555",
        padding: "10px 20px",
      },
      "& .Mui-selected": {
        color: "#007bff",
        borderBottom: "2px solid #007bff",
      },
    }}
  >
    <Tab label="Files" />
    <Tab label="Sub Task" />
    <Tab label="Comment" />
  </Tabs>


  {/* Tab Content */}
  <Paper sx={{ ...styles.tabContent, padding: "20px", borderRadius: "10px" }}>
    <Box>
      {selectedTab === 0 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", color: "#333" }}>
            Files
          </Typography>
          {files.length > 0 ? (
            files.map((file) => (
              <Box
                key={file.id}
                sx={{
                  ...styles.fileCard,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography>{file.filename}</Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.open(file.filepath, "_blank")}
                >
                  View
                </Button>
              </Box>
            ))
          ) : (
            <Typography>No files available.</Typography>
          )}
        </Box>
      )}
      {selectedTab === 1 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", color: "#333" }}>
            Subtasks
          </Typography>
          <Button
            onClick={handleModalOpen}
            variant="contained"
            sx={{ mb: 2, backgroundColor: "#007bff", color: "#fff" }}
          >
            Add Subtask
          </Button>
          {subtasks.length > 0 ? (
            subtasks.map((subtask) => (
              <Box
                key={subtask.task_id}
                sx={{
                  ...styles.subtaskCard,
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                }}
              >
                <Checkbox checked={subtask.completed} />
                <Avatar
                  src={subtask.user_image}
                  sx={{ width: 40, height: 40, marginRight: "10px" }}
                />
                <Box>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}>
                    {subtask.title}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#666" }}>
                    Due: {new Date(subtask.due_date).toLocaleDateString()}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography>No subtasks available.</Typography>
          )}
        </Box>
      )}
      {selectedTab === 2 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", color: "#333" }}>
            Comments
          </Typography>
          <Button
            onClick={handleCommentModalOpen}
            variant="contained"
            sx={{ mb: 2, backgroundColor: "#007bff", color: "#fff" }}
          >
            Add Comment
          </Button>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <Box
                key={comment.comment_id}
                sx={{
                  ...styles.commentCard,
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                }}
              >
                <Avatar
                  src={comment.user_image}
                  alt={comment.user_name}
                  sx={{ width: 40, height: 40, marginRight: "10px" }}
                />
                <Box>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}>
                    {comment.user_name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "12px", color: "#666" }}
                    dangerouslySetInnerHTML={{ __html: comment.comment }}
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Typography>No comments available.</Typography>
          )}
        </Box>
      )}
    </Box>
  </Paper>
</Box>




    {/* Add Comment Modal */}
    <Modal open={commentModalOpen} onClose={handleCommentModalClose}>
        <Box
          className='designation-modal'
        >


          <div className='designation-header'>
            <h1 className="designation-title" >
              Add Comment
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={handleCommentModalClose}
            />
          </div>


          <img
            src={designationImage}
            alt="Designation"
            className='designation-image'
          />


          <div className='designation-form'>
            <label className="form-label-deal">Comment:</label>
            <textarea
              type="text"
              rows={5}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              margin="normal"
              className='designation-input'
            />
          </div>


          <div className="form-button-group-deal">
            <button className="submit-button-deal" onClick={handleSaveComment}>
              Save
            </button>
          </div>
        </Box>
      </Modal>


    





      {/* Add Subtask Modal */}


      <Modal className="modal-overlay-deal" open={modalOpen} onClose={handleModalClose}>
        <div className="modal-content-deal" style={{ width: '500px' }}>
          <h1 className="modal-title-deal">Add New Subtask</h1>
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
          <form>
            <div className="form-field-group-deal">
              <label className="form-label-deal">Title</label>
              <input
                label="Title"
                value={newSubtaskTitle}
                onChange={(e) => setNewSubtaskTitle(e.target.value)}
                margin="normal"
                className="form-input-deal"
              />
            </div>
            {/* <TextField
              label="Start Date"
              type="date"
              fullWidth
              value={newSubtaskStartDate}
              onChange={(e) => setNewSubtaskStartDate(e.target.value)}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <div className="form-field-group-deal">
              <label className="form-label-deal">Start Date:</label>
              <input
                type="date"
                name="close_date"
                value={newSubtaskStartDate}
                onChange={(e) => setNewSubtaskStartDate(e.target.value)}
                className="form-input-deal"
                margin='normal'
                required
              />
            </div>
            {/* <TextField
              label="Due Date"
              type="date"
              fullWidth
              value={newSubtaskDueDate}
              onChange={(e) => setNewSubtaskDueDate(e.target.value)}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <div className="form-field-group-deal">
              <label className="form-label-deal">Due Date:</label>
              <input
                type="date"
                name="close_date"
                value={newSubtaskDueDate}
                onChange={(e) => setNewSubtaskDueDate(e.target.value)}
                className="form-input-deal"
                required
              />
            </div>
            <div className="form-field-group-deal">
              <label className="form-label-deal">Assigned To:</label>
              <select
                className="form-input-deal"
                value={newSubtaskAssignedTo}
                onChange={(e) => setNewSubtaskAssignedTo(e.target.value)}
                margin="normal"
              >
                <option value="">Select Member</option>
                {members.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.member_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group-deal">
              <label className="form-label-deal">Description:</label>
              <textarea
                multiline
                rows={4}
                value={newSubtaskDescription}
                onChange={(e) => setNewSubtaskDescription(e.target.value)}
                margin="normal"
              />
            </div>
          </form>


          {/* <Button onClick={handleModalClose}>Cancel</Button> */}
          <div className="form-button-group-deal">
            <button onClick={handleSaveSubtask} className="submit-button-deal">
              Save
            </button>


          </div>


        </div>
      </Modal>







    </Box>
  );
};


// Styles
const styles = {
  container: {
    padding: '30px',
    maxWidth: '100%',
    // backgroundColor: '#f9f9f9',
    // color: '#333',
    margin: '0 auto',
  },
  header: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  taskTitle: {
    fontWeight: '500',
    fontSize: '20px',
    color: '#444',
    marginBottom: '20px',
  },
  completeButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'none',
  },
  paper: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    height: 300,
  },
  statusPaper: {
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
  },
  tabs: {
    borderBottom: '1px solid #ddd',
  },
  tabContent: {
    backgroundColor: '#fff',
    padding: '16px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  label: {
    fontSize: '16px',
    color: '#666',
  },
  fileCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  userAvatar: {
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  subtaskCard: {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  subtaskAvatar: {
    width: 40,
    height: 40,
  },
  subtaskTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  subtaskDueDate: {
    fontSize: '14px',
    color: '#666',
  },
  commentCard: {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
};


export default TaskDetail;




