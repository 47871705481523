import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const VendorEdit = () => {
  const { id } = useParams(); // Extract the vendor ID from the URL
  const [currencies, setCurrencies] = useState([]); // To store currency options
  const [categories, setCategories] = useState([]); // To store vendor category options
  const [error, setError] = useState(""); // To handle errors during API calls
  const [countries, setCountries] = useState([]);
  const [vendorData, setVendorData] = useState({
    company_name: '',
    city: '',
    email: '',
    vendor_category: '',
    country: '',
    state: '',
    zip_code: '',
    website: '',
    vat_no: '',
    currency: '',
    address: '',
    retailer_policy: '',
    bank_name: '',
    holder_name: '',
    ifsc_code: '',
    branch: '',
    account_no: '',
  });

  useEffect(() => {
    const token = localStorage.getItem('authToken');  // Retrieve the token from local storage
  
    if (!token) {
      console.error('No authentication token found.');
      return;  // Exit if no token is found
    }
  
    fetch(`https://api.myntask.com/vendor/edit/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,  // Include the token in the request
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch vendor data');
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setVendorData(data[0]);  // Assuming the API returns an array with a single object
        }
      })
      .catch((error) => console.error('Error fetching vendor data:', error));
  }, [id]);  // Re-run when `id` changes


  useEffect(() => {
    // Fetch currencies and vendor categories from the API
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");




      if (!token) {
        setError("Authentication token not found. Please log in.");
        return;
      }




      try {
        // Fetch currencies
        const currencyResponse = await fetch("http://api.myntask.com/expenses/currency", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass token in header
          },
        });




        if (!currencyResponse.ok) {
          throw new Error("Failed to fetch currencies");
        }




        const currencyData = await currencyResponse.json();
        setCurrencies(currencyData); // Store fetched currencies




        // Fetch vendor categories
        const categoryResponse = await fetch("http://api.myntask.com/vendor/category/list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass token in header
          },
        });




        if (!categoryResponse.ok) {
          throw new Error("Failed to fetch vendor categories");
        }




        const categoryData = await categoryResponse.json();
        setCategories(categoryData); // Store fetched categories
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      }
    };


    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data); // Assuming the API returns an array of countries

      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchData();
    fetchCountries();
  }, []);
  

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendorData({ ...vendorData, [name]: value });
  };

    // Handle form submission
     // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve token from local storage
    const token = localStorage.getItem('authToken');

    // Prepare data for the API request
    const updatedData = {
      vendor_id: id, // Replace with the actual vendor ID if dynamic
      ...vendorData,
    };

    try {
      // Send PUT request using fetch
      const response = await fetch('https://api.myntask.com/vendor/update', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData), // Convert data to JSON string
      });

      // Check if the response is OK (status 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update vendor');
      }

      // Parse the JSON response
      const result = await response.json();
      console.log('Vendor updated successfully:', result);
      alert('Vendor information updated successfully');
    } catch (error) {
      // Handle error
      console.error('Error updating vendor:', error.message);
      alert('Failed to update vendor information');
    }
  };

  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Edit Vendor
          <div className="underline"></div>
        </h1>
      </div>
      <div>
      <section className="section">
          <h3 className="sectionTitle">Basic Information</h3>
          <div className="formRow">
            <div className="formColumn">
              <div className="formGroup">
                <label className="label">Company Name:</label>
                <input
                  type="text"
                  name="company_name"
                  value={vendorData.company_name}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <label className="label">Email:</label>
                <input
                  type="email"
                  name="email"
                  value={vendorData.email}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
             
            </div>

            <div className="formColumn">
            <div className="formGroup">
                <label className="label">Website:</label>
                <input
                  type="text"
                  name="website"
                  value={vendorData.website}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
              <div className="formGroup">
        <label className="label">Category:</label>
        <select
          name="vendor_category"
          value={vendorData.vendor_category}
          onChange={handleInputChange}
          className="input"
        >
          <option value="">Select a category</option>  {/* Default option */}
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
            </div>
          </div>
        </section>

        {/* Section 2: Location Details */}
        <section className="section">
          <h3 className="sectionTitle">Location Details</h3>
          <div className="formRow">
            <div className="formColumn">
              <div className="formGroup">
                <label className="label">City:</label>
                <input
                  type="text"
                  name="city"
                  value={vendorData.city}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <label className="label">State:</label>
                <input
                  type="text"
                  name="state"
                  value={vendorData.state}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
            </div>

            <div className="formColumn">
              <div className="formGroup">
                <label className="label">Country:</label>
                <input
                  type="text"
                  name="country"
                  value={vendorData.country}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <label className="label">Zip Code:</label>
                <input
                  type="text"
                  name="zip_code"
                  value={vendorData.zip_code}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
            </div>
            <div className="formGroup">
                <label className="label">Address:</label>
                <textarea
                  type="text"
                  name="address"
                  value={vendorData.address}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
          </div>
        </section>

        {/* Section 3: Financial Information */}
        <section className="section">
          <h3 className="sectionTitle">Financial Information</h3>
          <div className="formRow">
            <div className="formColumn">
              <div className="formGroup">
                <label className="label">VAT No:</label>
                <input
                  type="text"
                  name="vat_no"
                  value={vendorData.vat_no}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
        <label className="label">Currency:</label>
        <select
          name="currency"
          value={vendorData.currency}
          onChange={handleInputChange}
          className="input"
        >
          <option value="">Select a currency</option>  {/* Default option */}
          {currencies.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.currency_code} ({currency.currency_symbol})
            </option>
          ))}
        </select>
      </div>

            </div>
            <div className="formColumn">
              <div className="formGroup">
                <label className="label">Retailer Policy:</label>
                <input
                  type="text"
                  name="retailer_policy"
                  value={vendorData.retailer_policy}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
              </div>
          </div>
        </section>

        {/* Section 4: Bank Details */}
        <section className="section">
          <h3 className="sectionTitle">Bank Details</h3>
          <div className="formRow">
            <div className="formColumn">
              <div className="formGroup">
                <label className="label">Bank Name:</label>
                <input
                  type="text"
                  name="bank_name"
                  value={vendorData.bank_name}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
              <div className="formGroup">
                <label className="label">Holder Name:</label>
                <input
                  type="text"
                  name="holder_name"
                  value={vendorData.holder_name}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <label className="label">Account No:</label>
                <input
                  type="text"
                  name="account_no"
                  value={vendorData.account_no}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
             
            </div>

            <div className="formColumn">
              <div className="formGroup">
                <label className="label">IFSC Code:</label>
                <input
                  type="text"
                  name="ifsc_code"
                  value={vendorData.ifsc_code}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="formGroup">
                <label className="label">Branch:</label>
                <input
                  type="text"
                  name="branch"
                  value={vendorData.branch}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Submit Button */}
        <button onClick={handleSubmit} className="color-button">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default VendorEdit;
