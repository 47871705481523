import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditLead = () => {
  const { id } = useParams(); // Get the lead ID from the URL
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);
  const [formData, setFormData] = useState({
    company_name: "",
    client_email: "",
    lead_value: "",
    industry: "",
    status: "",
    office_phone_number: "",
    source_id: "",
    website: "",
    mobile: "",
    office: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
    name: "",
  });

  // Fetch lead data when component mounts
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Retrieve the token from localStorage
        const response = await fetch(`https://api.myntask.com/leads/lead_edit/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch lead data");
        }
  
        const data = await response.json();
        setLeadData(data);
        setFormData(data[0]); // Assuming data is an array with one object
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching lead data:", error);
        setIsLoading(false);
      }
    };
  
    fetchLeadData();
  }, [id]);
  
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchSources = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/leads/lead_source", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch Sources");
        }
        const data = await response.json();
        setSources(data);
      } catch (error) {
        console.error("Error fetching Sources:", error);
      }
    };

    fetchCountries();
    fetchSources();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
      const payload = {
        lead_id: id, // Assuming `id` is the lead_id
        name: formData.name,
        email: formData.client_email,
        source_id: formData.source_id,
        company_name: formData.company_name,
        website: formData.website,
        mobile: formData.mobile,
        office_phone_number: formData.office_phone_number,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        postal_code: formData.postal_code,
        address: formData.address,
        status: formData.status,
        lead_value: formData.lead_value,
        industry: formData.industry,
      };

      const response = await fetch("https://api.myntask.com/leads/lead_update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Lead updated successfully!");
        navigate("/"); // Navigate to the main page or list page
      } else {
        const errorData = await response.json();
        console.error("Failed to update lead:", errorData);
        alert("Failed to update lead!");
      }
    } catch (error) {
      console.error("Error updating lead:", error);
      alert("An error occurred while updating the lead!");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={formStyles.body}>
    <div style={formStyles.addLeadForm}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Edit Lead</h1>

      <form onSubmit={handleSubmit}>
        {/* Lead Information Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Lead Information</div>
          <div style={formStyles.formGroup}>
          <label style={formStyles.formLabel}>Source ID:</label>
          <select
            name="source_id"
            value={formData.source_id || ""}
            onChange={handleChange}
            style={formStyles.formInput}
          >
            <option value="">Select source</option>
            {sources.map((source) => (
              <option key={source.id} value={source.id}>
                {source.type} {/* Replace "type" with the field to display */}
              </option>
            ))}
          </select>
        </div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Status:</label>
            <select
              name="status"
              value={formData.status || ""}
              onChange={handleChange}
              style={formStyles.formInput}
            >
              <option value="new">New</option>
              <option value="contacted">Contacted</option>
              <option value="qualified">Qualified</option>
              <option value="lost">Lost</option>
              <option value="closed">Closed</option>
            </select>
          </div>
        
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Lead Value:</label>
            <input
              type="number"
              name="lead_value"
              value={formData.lead_value || ""}
              onChange={handleChange}
              style={formStyles.formInput}
            />
          </div>
         
        </div>

        {/* Contact Details Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Contact Details</div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name || ""}
                onChange={handleChange}
                style={formStyles.formInput}
              />
            </div>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Mobile:</label>
              <input
                type="tel"
                name="mobile"
                value={formData.mobile || ""}
                onChange={handleChange}
                style={formStyles.formInput}
              />
            </div>
          </div>
          <div style={formStyles.formRow}>
          <div style={formStyles.formGroup}>
    <label style={formStyles.formLabel}>Client Email:</label>
    <input
      type="email"
      name="client_email"
      value={formData.client_email || ""}
      onChange={handleChange}
      style={formStyles.formInput}
    />
          </div>
          <div style={formStyles.formGroup}>
    <label style={formStyles.formLabel}>Phone No:</label>
    <input
      type="tel"
      name="office_phone_number"
      value={formData.office_phone_number || ""}
      onChange={handleChange}
      style={formStyles.formInput}
    />
          </div>
  </div>
        </div>


         {/* Company Information Section */}
         <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Company Information</div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Company Name:</label>
            <input
              type="text"
              name="company_name"
              value={formData.company_name || ""}
              onChange={handleChange}
              style={formStyles.formInput}
            />
          </div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Industry:</label>
            <input
              type="text"
              name="industry"
              value={formData.industry || ""}
              onChange={handleChange}
              style={formStyles.formInput}
            />
          </div>
          <div style={formStyles.formGroup}>
    <label style={formStyles.formLabel}>Website:</label>
    <input
      type="text"
      name="website"
      value={formData.website || ""}
      onChange={handleChange}
      style={formStyles.formInput}
    />
  </div>
          </div>
          

        {/* Address Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Address</div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Address:</label>
            <textarea
              name="address"
              value={formData.address || ""}
              onChange={handleChange}
              style={{ ...formStyles.formInput, height: "80px" }}
            />
          </div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>City:</label>
              <input
                type="text"
                name="city"
                value={formData.city || ""}
                onChange={handleChange}
                style={formStyles.formInput}
              />
            </div>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>State:</label>
              <input
                type="text"
                name="state"
                value={formData.state || ""}
                onChange={handleChange}
                style={formStyles.formInput}
              />
            </div>
          </div>
          <div style={formStyles.formRow}>
          <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Postal Code:</label>
    <input
      type="text"
      name="postal_code"
      value={formData.postal_code || ""}
      onChange={handleChange}
      style={formStyles.formInput}
    />
         </div>
         <div style={formStyles.formGroup}>
         <label style={formStyles.formLabel}>Country:</label>
    <input
      type="text"
      name="country"
      value={formData.country || ""}
      onChange={handleChange}
      style={formStyles.formInput}
    />
  </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          <button type="submit" className="color-button">
            Save Changes
          </button>
          <button
            type="button"
            onClick={() => window.history.back()}
            className="color-button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  );
};

export default EditLead;




const formStyles = {
    addLeadForm: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      padding: '15px 40px',
      width: '100%',
      maxWidth: '850px',
      borderRadius: '15px',
      boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
      margin: 'auto',
    },
    body: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    
    },
    formGroup: {
      marginBottom: '20px',
      width :'100%'
    },
    formRow: {
      display: 'flex',
      gap: '20px',
    },
    formLabel: {
      display: 'block',
      fontWeight: '500',
      marginBottom: '8px',
      color: '#333',
    },
    formInput: {
      width: '100%',
      padding: '12px',
      fontSize: '16px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      boxShadow: 'inset 0 3px 6px rgba(0, 0, 0, 0.05)',
      transition: 'border 0.3s, box-shadow 0.3s',
    },
  
    formRow: {
      display: 'flex',
      gap: '20px',          // space between the two input fields
      justifyContent: 'space-between',  // distribute them evenly
      width: '100%',
    },
    
  
    section: {
      marginBottom: '20px',
    },
    sectionHeading: {
      fontSize: '20px',
      color: '#007bff',
      borderBottom: '2px solid #eee',
      paddingBottom: '5px',
      marginBottom: '15px',
    },
    submitButton: {
      background: 'linear-gradient(135deg, #6b73ff, #000dff)',
      color: 'white',
      padding: '15px 20px',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '10px',
      width: '100%',
      fontSize: '18px',
      transition: 'background 0.3s, transform 0.2s',
    },
  };