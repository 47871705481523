import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import ticketImage from '../assets/ticket.png'; // Import the ticket image
import closeIcon from '../assets/Icon.png'; // Import the close icon image
import attachmentIcon from '../assets/Attachments.png'; // Import the attachment image


function AdminTicket() {

  const [departments, setDepartments] = useState([]);
  const [ticketList, setTicketList] = useState([]);



  // Retrieve token from localStorage
  const authToken = localStorage.getItem('authToken');


  // Function to fetch department data
  const fetchDepartments = async () => {
    try {
      const response = await fetch('https://api.myntask.com/employee/department', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch departments');
      }


      const data = await response.json();
      setDepartments(data);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };


  // Function to fetch ticket list
  const fetchTicketList = async () => {
    try {
      const response = await fetch('https://api.myntask.com/ticket/list', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch ticket list');
      }


      const data = await response.json();
      console.log('data coming from tickets', data);
      setTicketList(data);
    } catch (error) {
      console.error('Error fetching ticket list:', error);
    }
  };


  // Fetch departments and tickets on component mount
  useEffect(() => {
    fetchDepartments();
    fetchTicketList();
  }, []);


  return (
    <div style={{ position: 'relative', padding: '20px' }}>



      <h1>Ticket List</h1>
      {/* Ticket List Table */}
      <table
      >
        <thead>
          <tr>
            <th >Ticket ID</th>
            <th >Subject</th>
            <th >Raised By</th>
            <th >Status</th>
            <th >Date</th>
            <th >Attachment</th>
          </tr>
        </thead>
        <tbody>
          {ticketList.map((ticket) => (
            <tr key={ticket.id}>
              <td >{ticket.ticket_id}</td>
              <td >{ticket.subject}</td>
              <td >{ticket.raised_by}</td>
              <td >{ticket.status || 'N/A'}</td>
              <td >
                {new Date(ticket.datetime).toLocaleString()}
              </td>
              <td >
                {ticket.attacement ? (
                  <a href={ticket.attacement} target="_blank" rel="noopener noreferrer">
                    View Attachment
                  </a>
                ) : (
                  'No Attachment'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>


    </div>      
  );
}


export default AdminTicket;
   


