import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {Modal,Box } from '@mui/material';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';


const ProductSetting = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
 const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
 const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);

const fetchCategories = async () => {
  const token = localStorage.getItem("authToken");
  if (!token) return;


  try {
    const response = await fetch("https://api.myntask.com/product/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) throw new Error("Failed to fetch product categories");


    const data = await response.json();
    setCategories(data);
  } catch (error) {
    console.error("Error fetching product categories:", error);
  }
};

// Fetch Category Options
useEffect(() => {
 
  fetchCategories();
}, []);


const fetchSubCategories = async (categoryId) => {
    const token = localStorage.getItem("authToken");
    if (!token) return;
  
    try {
      const response = await fetch(
        `https://api.myntask.com/product/sub_category/${categoryId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!response.ok) throw new Error("Failed to fetch sub-categories");
  
      const data = await response.json();
      // Store the fetched subcategories for the specific category
      setSubCategories((prev) => ({ ...prev, [categoryId]: data }));
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };
  

// Fetch Sub-Category Options based on Product Category
useEffect(() => {
    if (!selectedCategoryId) return; // Skip if no category is selected
    fetchSubCategories(selectedCategoryId);
  }, [selectedCategoryId]);
  


  // Open and close modals
  const openCategoryModal = () => setIsCategoryModalOpen(true);
  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
    setCategoryName("");
  };

  const openSubCategoryModal = () => {
    setIsSubCategoryModalOpen(true);
  };

  const closeSubCategoryModal = () => {
    setIsSubCategoryModalOpen(false);
    setSubCategoryName("");
  };


  const handleAddCategory = async () => {
    if (!categoryName.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Category name cannot be empty.",
        position: "top-end",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://api.myntask.com/product/category/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: categoryName }),
      });

      if (!response.ok) throw new Error("Failed to add category.");
      fetchCategories();
      closeCategoryModal();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Category added successfully!",
        position: "top-end",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
      });
      setCategoryName("");
    } catch (error) {
      console.error("Error adding category:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while adding the category.",
        position: "top-end",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleAddSubCategory = async () => {
    if (!subCategoryName.trim() || !selectedCategoryId) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Subcategory name and category must be selected.",
        position: "top-end",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://api.myntask.com/product/sub_category/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          category_id: selectedCategoryId,
          name: subCategoryName,
        }),
      });

      if (!response.ok) throw new Error("Failed to add subcategory.");
      closeSubCategoryModal();
      fetchSubCategories();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Subcategory added successfully!",
        position: "top-end",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
      });
      setSubCategoryName("");
    } catch (error) {
      console.error("Error adding subcategory:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while adding the subcategory.",
        position: "top-end",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
      });
    } 
  };

  const toggleSubCategories = (categoryId) => {
    console.log("Fetching subcategories for category ID:", categoryId);
  
    // Check if subcategories are already loaded
    if (subCategories[categoryId]) {
      // Collapse the subcategories
      setSubCategories((prev) => {
        const updated = { ...prev };
        delete updated[categoryId];
        return updated;
      });
    } else {
      // Fetch and expand the subcategories
      fetchSubCategories(categoryId);
    }
  };
  

  return (
    <div className="main-container">
       <div className="top-controls">
        <h1 className="title">
          Product Category Setting
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
        <button className="color-button" onClick={openCategoryModal}>
            Add Category
          </button>
          <button className="color-button" onClick={openSubCategoryModal}>
            Add Sub Category
          </button>
        </div>
      </div>

      <table className="category-table">
        <thead>
          <tr>
            <th>Category Name</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <React.Fragment key={category.id}>
              <tr>
                <td onClick={() => toggleSubCategories(category.id)}>{category.category_name}</td>
                {/* <td>
                  <button onClick={() => deleteCategory(category.id)}>Delete</button>
                </td> */}
              </tr>
              {subCategories[category.id] && (
                <tr>
                  <td colSpan="2">
                    <table className="subcategory-table">
                      <thead>
                        <tr>
                          <th>Sub-category Name</th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {subCategories[category.id].map((subCategory) => (
                          <tr key={subCategory.id}>
                            <td>{subCategory.category_name}</td>
                            {/* <td>
                              <button onClick={() => deleteSubCategory(subCategory.id)}>
                                Delete
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    
          {/* Category Modal */}
    <Modal
  open={isCategoryModalOpen}
  onClose={closeCategoryModal}
  aria-labelledby="category-modal"
  aria-describedby="modal-for-adding-category"
>
  <Box className="designation-modal">
    {/* Header with title and close button */}
    <div className="designation-header">
      <h1 className="designation-title">Add Category</h1>
      <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className="designation-close"
        onClick={closeCategoryModal}
      />
    </div>

    {/* Form Section */}
    <div className="designation-form">
      <label className="designation-label" >
        Category Name
      </label>
      <input
        type="text"
        id="category-name"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        placeholder="Enter category name"
        className="designation-input"
      />
    </div>

      <button
        className="designation-save"
        onClick={handleAddCategory}
      
      >
      Save
      </button>
     
 
  </Box>
</Modal>


      {/* SubCategory Modal */}
      <Modal
  open={isSubCategoryModalOpen}
  onClose={closeSubCategoryModal}
  aria-labelledby="subcategory-modal"
  aria-describedby="modal-for-adding-subcategory"
>
  <Box className="designation-modal">
    {/* Header with title and close button */}
    <div className="designation-header">
      <h1 className="designation-title">Add Subcategory</h1>
      <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className="designation-close"
        onClick={closeSubCategoryModal}
      />
    </div>

    {/* Form Section */}
    <div className="designation-form">
         {/* Category Selection */}
      <label className="designation-label" htmlFor="category-select">
        Select Category
      </label>
      <select
        id="category-select"
        value={selectedCategoryId}
        onChange={(e) => setSelectedCategoryId(e.target.value)}
        className="designation-input"
      >
        <option value="">-- Select a Category --</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.category_name}
          </option>
        ))}
      </select>

      <label className="designation-label" htmlFor="subcategory-name">
        Subcategory Name
      </label>
      <input
        type="text"
        id="subcategory-name"
        value={subCategoryName}
        onChange={(e) => setSubCategoryName(e.target.value)}
        placeholder="Enter subcategory name"
        className="designation-input"
      />
    </div>

    <button
      className="designation-save"
      onClick={handleAddSubCategory}
     
    >
     Save 
    </button>
  </Box>
</Modal>

    </div>
  );
};

export default ProductSetting;
