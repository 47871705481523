import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, TextField, Stack, Typography } from '@mui/material';
import EditDesignationModal from '../Components/EditDesignationModal';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import noDataImage from '../assets/NoDataFound.png';
import { Vortex } from 'react-loader-spinner';



function Designation() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [newDesignation, setNewDesignation] = useState({ name: '', parent_id: '' });
  const [parentOptions, setParentOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [action, setAction] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Sorting state
  const actionMenuRefs = useRef({});
  const [isLoading, setIsLoading] = useState(true);




  // Sort data based on key and direction
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        const aValue = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
        const bValue = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];


        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);




  // Fetch designation data function
  const fetchDesignationData = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/employee/designation_list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }


      const data = await response.json();
      setData(data.designation);
      setFilteredData(data.designation);
      setPermissions(data.permission); // Set the permissions data
      setIsLoading(false);
    } catch (error) {
      setError("Failed to fetch Designation Data", error.message);
      setIsLoading(false);
    }
  };




  useEffect(() => {
    const fetchParentOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/employee/designation', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch parent options');
        }


        const options = await response.json();
        setParentOptions(options);
      } catch (error) {
        setError(error.message);
      }
    };


    fetchParentOptions();
  }, []);






  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);








  const handleAddDesignation = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/employee/designation_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: newDesignation.name,
          parent_id: newDesignation.parent_id || null,
        }),
      });


      if (!response.ok) {
        throw new Error('Failed to save designation');
      }


      const result = await response.json();


      // Update the list with the new designation
      setData((prevData) => [...prevData, { id: result.insertId, name: newDesignation.name, parent: newDesignation.parent_id || 'None' }]);
      setFilteredData((prevData) => [...prevData, { id: result.insertId, name: newDesignation.name, parent: newDesignation.parent_id || 'None' }]);


      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Designation added successfully!',


      });


      handleClose();
    } catch (err) {
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Error saving designation',


      });
      setError('Error saving designation');
    }
  };




  useEffect(() => {
    fetchDesignationData();
  }, []);






  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDesignation((prev) => ({ ...prev, [name]: value }));
  };








  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);


    const filtered = data.filter((designation) =>
      designation.name.toLowerCase().includes(query) ||
      (designation.parent && designation.parent.toLowerCase().includes(query))
    );


    setFilteredData(filtered);
  };








  const handleCheckboxChange = (id) => {
    if (selectedId === id) {
      setSelectedId(null);
      setDropdownOpen(false);
    } else {
      setSelectedId(id);
      setDropdownOpen(true);
    }
  };






  const handleAction = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    try {
      if (action === 'delete') {
        // Show SweetAlert confirmation before deletion
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'You won’t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        });


        if (result.isConfirmed) {
          // Proceed with the delete API call if confirmed
          const response = await fetch('https://api.myntask.com/employee/designation_delete', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ designation_id: selectedId }),
          });


          if (!response.ok) {
            throw new Error('Failed to delete designation');
          }


          // Show success message after successful deletion
          Swal.fire('Deleted!', 'The designation has been deleted.', 'success');


          // Remove the deleted designation from the list
          setData((prevData) => prevData.filter((designation) => designation.id !== selectedId));
          setFilteredData((prevData) => prevData.filter((designation) => designation.id !== selectedId));
        }
      } else if (action === 'edit') {
        setEditModalOpen(true); // Open the edit modal
      } else if (action === 'view') {
        const selectedDesignation = data.find((designation) => designation.id === selectedId);
        setViewData(selectedDesignation); // Set the data for viewing
        setViewModalOpen(true); // Open the view modal
      }


      // Close the dropdown after any action
      setActionMenuVisible((prevState) => ({
        ...prevState,
        [selectedId]: false,
      }));
    } catch (err) {
      setError('Error performing action');
    }
  };




  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setSelectedId(id);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      const refsArray = Object.values(actionMenuRefs.current);


      // Check if the click is outside of any of the action menus
      const clickedOutside = refsArray.every((ref) => {
        return ref && !ref.contains(event.target);
      });


      if (clickedOutside) {
        setActionMenuVisible({}); // Close all menus
      }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  // Function to convert string to Title Case
  const toTitleCase = (str) => {
    if (!str) return '';  // Handle cases where the value might be null or undefined
    return str
      .toLowerCase() // Convert entire string to lowercase
      .split(' ')    // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
      .join(' ');   // Join back to a single string
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Designation
          <div className="underline"></div>
        </h1>


        <div className="buttons-row">
          {dropdownOpen && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <select onChange={(e) => setAction(e.target.value)} value={action} className="color-button">
                <option value="">Select Action</option>
                {permissions.edit_designation === 'all' && <option value="edit">Edit</option>}
                {permissions.delete_designation === 'all' && <option value="delete">Delete</option>}
              </select>
              <button onClick={handleAction} className="color-button">
                Apply
              </button>
            </div>
          )}
          {permissions.add_designation === 'all' && (
            <button className="color-button" onClick={() => setOpen(true)}>
              Add Designation
            </button>
          )}
        </div>
      </div>








      <div className="top-controls">
        <div className="filters-and-search">
          <button className="border-button">
            Clear Filters
          </button>

          <input
            type="text"
            placeholder="Search Designation"
            value={searchQuery}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>


      {filteredData.length > 0 ? (




        <table>
          <thead>
            <tr>
              <th>Select</th>
              <th onClick={() => handleSort('name')}>
                Name{' '}
                {sortConfig.key === 'name' ? (
                  sortConfig.direction === 'ascending' ? (
                    '↑'
                  ) : (
                    '↓'
                  )
                ) : (
                  '↕'
                )}
              </th>


              <th onClick={() => handleSort('parent')}>
                Parent{' '}
                {sortConfig.key === 'parent' ? (
                  sortConfig.direction === 'ascending' ? (
                    '↑'
                  ) : (
                    '↓'
                  )
                ) : (
                  '↕'
                )}
              </th>


              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((designation) => (
              <tr key={designation.id}>
                <td >
                  <input
                    type="checkbox"
                    checked={selectedId === designation.id}
                    onChange={() => handleCheckboxChange(designation.id)}
                  />
                </td>
                <td>{toTitleCase(designation.name)}</td>
                <td>{designation.parent ? toTitleCase(designation.parent) : 'None'}</td>
                <td >
                  <div className="action-container">
                    <div className="action-dots" onClick={() => toggleActionMenu(designation.id)}>
                      <GridMoreVertIcon />
                    </div>
                    {actionMenuVisible[designation.id] && (
                      <div className="action-menu"
                        ref={(el) => (actionMenuRefs.current[designation.id] = el)}>
                        {permissions.edit_designation === 'all' && (
                          <div className="action-item" onClick={() => { setAction('edit'); handleAction(); }}>
                            Edit
                          </div>
                        )}
                        {permissions.delete_designation === 'all' && (
                          <div className="action-item" onClick={() => { setAction('delete'); handleAction(); }}>
                            Delete
                          </div>
                        )}
                        <div className="action-item" onClick={() => { setAction('view'); handleAction(); }}>
                          View
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No data available</p>
        </div>
      )}


      {error && <p style={{ color: 'red' }}>{error}</p>}





      <EditDesignationModal
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        designationId={selectedId}
        onDesignationUpdated={fetchDesignationData} // Pass the fetch function as a prop
      />






      <Modal open={open} onClose={handleClose}>
        <Box
          className='designation-modal'
        >
          {/* Close Button Replaced with Image */}
          <div className='designation-header'>
            <h1 className="designation-title" >
              Add Designation
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={handleClose}
            />
          </div>


          <img
            src={designationImage}
            alt="Designation"
            className='designation-image'
          />


          <div className='designation-form'>
            <label
              className='designation-label'
            >
              Designation Name
            </label>
            <input
              type="text"
              id="designation-name"
              name="name"
              value={newDesignation.name}
              onChange={handleChange}
              className='designation-input'
            />


            <label
              className='designation-label'
            >
              Parent Designation
            </label>
            <select
              id="parent-designation"
              name="parent_id"
              value={newDesignation.parent_id}
              onChange={handleChange}
              className='designation-input'
            >
              <option value="">
                <em>None</em>
              </option>
              {parentOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>


          <button
            className='designation-save'
            onClick={handleAddDesignation}
          >
            Save
          </button>

        </Box>
      </Modal>














      {/* View Modal */}
      <Modal open={viewModalOpen} onClose={() => setViewModalOpen(false)}>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '400px',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
          }}
        >
          {viewData && (
            <>
              <h2 style={{ marginBottom: '20px' }}>View Designation</h2>
              <p>
                <strong>Name:</strong> {viewData.name}
              </p>
              <p>
                <strong>Parent:</strong> {viewData.parent || 'None'}
              </p>
              {/* Close button */}
              <button
                onClick={() => setViewModalOpen(false)}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  position: 'absolute',   // Add absolute positioning
                  bottom: '20px',         // Position it 20px from the bottom
                  right: '20px',          // Position it 20px from the right
                }}
              >
                Close
              </button>




            </>
          )}
        </Box>
      </Modal>




















    </div>
  );
}








export default Designation;














































































