import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Overview from '../Components/Overview';
import Members from '../Components/Members';
import Files from '../Components/Files';
import Tasks from '../Components/Tasks';
import Expense from '../Components/Expense';
import TaskBoard from '../Components/TaskBoard';
import GanttChart from '../Components/GanttChart';
import Milestones from '../Components/Milestones';

// Other components for different tabs
const Invoices = ({ projectId }) => <Typography>Invoices Component for Project ID: {projectId}</Typography>;
const Orders = ({ projectId }) => <Typography>Orders Component for Project ID: {projectId}</Typography>;
const Timesheet = ({ projectId }) => <Typography>Timesheet Component for Project ID: {projectId}</Typography>;

// Custom styles for the component
const useStyles = makeStyles((theme) => ({
  projectDashboardContainer: {
    width: '100%', // Full width for the container
    // padding: '24px',
 
  },
  projectDashboardTabs: {
    borderBottom: '2px solid #ddd', // Light border at the bottom for separation
  },
  projectDashboardTab: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#666666', // Gray color for the text
    textTransform: 'none',
    minHeight: '40px', // Minimum height for tabs
    minWidth: '100px', // Minimum width for consistent tab sizes
    padding: '8px 16px',
    '&.Mui-selected': {
      color: '#1976d2', // Blue color for the selected tab text
      fontWeight: '600', // Slightly bold for selected tab
   
    },
  
  },
  projectDashboardTabPanel: {
    // marginTop: '20px',
    // padding: '16px',
    // backgroundColor: 'white',
    // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
    borderRadius: '8px',
    overflowX: 'hidden', // Prevent horizontal scroll overflow
  },
  projectDashboardHeading: {

    fontWeight: 'bold',
    // marginBottom: '16px',
  },
  projectDashboardTabBox: {
    // borderBottom: '1px solid #ddd',
    width: '100%',
    marginBottom: '13px',
  },
}));

const Projectdashboard = () => {
  const { projectId } = useParams(); // Get the projectId from the URL params
  const [selectedTab, setSelectedTab] = useState(0); // Control which tab is active
  const classes = useStyles(); // Apply custom styles

  // Function to handle tab change
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Render the component based on the selected tab and pass projectId to each tab component
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <Overview projectId={projectId} />;
      case 1:
        return <Members projectId={projectId} />;
      case 2:
        return <Files projectId={projectId} />;
      case 3:
        return <Milestones projectId={projectId} />;
      case 4:
        return <Tasks projectId={projectId} />;
      case 5:
        return <TaskBoard projectId={projectId} />;
      case 6:
        return <GanttChart projectId={projectId} />;
      case 7:
        return <Expense projectId={projectId} />;
      case 8:
        return <Orders projectId={projectId} />;
      case 9:
        return <Timesheet projectId={projectId} />;
      case 10:
        return <Invoices projectId={projectId} />;
      default:
        return <Overview projectId={projectId} />;
    }
  };

  return (
    <Box component="main" className={classes.projectDashboardContainer}>
      <div className="top-controls">
        <h1 className='title'>Project Detail
          <div className="underline"></div>
        </h1>
      </div>

      <Box className={classes.projectDashboardTabBox}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="project details tabs"
          className={classes.projectDashboardTabs}
        >
          <Tab label="Overview" className={classes.projectDashboardTab} />
          <Tab label="Members" className={classes.projectDashboardTab} />
          <Tab label="Files" className={classes.projectDashboardTab} />
          <Tab label="Milestones" className={classes.projectDashboardTab} />
          <Tab label="Tasks" className={classes.projectDashboardTab} />
          <Tab label="Task Board" className={classes.projectDashboardTab} />
          <Tab label="Gantt Chart" className={classes.projectDashboardTab} />
          <Tab label="Expenses" className={classes.projectDashboardTab} />
          {/* <Tab label="Orders" className={classes.projectDashboardTab} />
          <Tab label="Timesheet" className={classes.projectDashboardTab} />
          <Tab label="Invoices" className={classes.projectDashboardTab} /> */}
        </Tabs>
      </Box>

      <Box className={classes.projectDashboardTabPanel}>
        {renderTabContent()}
      </Box>
    </Box>
  );
};

export default Projectdashboard;
