import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Swal from 'sweetalert2';
import noDataImage from '../assets/NoDataFound.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';



const Vendor = () => {
  const [vendors, setVendors] = useState([]); // State to store fetched vendor data
  const navigate = useNavigate(); // Create navigate function
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);



  // Function to fetch vendors data
  const fetchVendors = async () => {
    const token = localStorage.getItem('authToken'); // Get token from localStorage


    try {
      const response = await fetch('https://api.myntask.com/vendor/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });


      console.log('Response:', response); // Log the response for debugging


      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response Error Text:', errorText);
        throw new Error('Network response was not ok');
      }


      const data = await response.json(); // Parse JSON response
      console.log('Fetched vendors:', data); // Log the fetched data for debugging


      // Check the structure of the data and set it correctly
      setVendors(data); // Adjust this based on the actual structure
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError('Failed to Fetch Vendors');
      setIsLoading(false);
    }
  };




  useEffect(() => {
    fetchVendors();
  }, []);




  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };


  const handleDeleteClick = async (id) => {
    try {
      const token = localStorage.getItem('authToken');


      if (!token) {
        Swal.fire({
          title: 'Error',
          text: 'Authorization token is missing!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }


      // Confirm deletion with SweetAlert
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
      });


      // Proceed only if user confirmed
      if (result.isConfirmed) {
        const url = `https://api.myntask.com/vendor/delete`;


        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ vendor_id: id }), // Send the vendor ID in the request body
        });


        const data = await response.json();
        console.log('Item deleted successfully:', data);


        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'The item has been deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });




        fetchVendors();
      }
    } catch (error) {
      console.error('Error deleting item:', error);


      // Show error message
      Swal.fire({
        title: 'Error',
        text: 'Failed to delete the item. Please try again.',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };




  const handleVendorClick = (vendorId) => {
    navigate(`/vendor-detail/${vendorId}`);
  };




  const toTitleCase = (str) => {
    if (!str) return 'N/A';
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };




  const handleEditClick = (vendorId) => {
    navigate(`/vendor-edit/${vendorId}`);
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }


  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Vendors
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">


          <button className="color-button" onClick={() => navigate('/add-vendor')}>
            Add Vendor
          </button>


        </div>
      </div>




      {/* Vendors Table */}
      <div >
        {vendors.length > 0 ? (


          <table>
            <thead>
              <tr>


                <th>Company</th>
                <th>Email</th>
                <th>City</th>
                <th>Phone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {vendors.map((vendor) => (
                <tr key={vendor.id}>

                  <td>
                    <span
                      style={{ fontWeight: '600', cursor: 'pointer' }}
                      onClick={() => handleVendorClick(vendor.id)}
                    >
                      {toTitleCase(vendor.company_name || 'N/A')}
                    </span>
                  </td>
                  <td>{toTitleCase(vendor.email || 'N/A')}</td>
                  <td>{toTitleCase(vendor.city || 'N/A')}</td>
                  <td>{toTitleCase(vendor.phone || 'N/A')}</td>
                  <td>
                    <div className="action-container">
                      <div className="action-dots" onClick={() => toggleActionMenu(vendor.id)}>
                        <GridMoreVertIcon />
                      </div>
                      {actionMenuVisible === vendor.id && (
                        <div className="action-menu">
                          <div className="action-item" onClick={() => handleEditClick(vendor.id)}>Edit </div>
                          <div className="action-item" onClick={() => handleDeleteClick(vendor.id)}>Delete</div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-data-container">
            <img src={noDataImage} alt="No data available" className="no-data-image" />
            <p className="no-data-text">No data found</p>
          </div>
        )}


      </div>
    </div>


  );
};


export default Vendor;






