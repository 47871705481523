import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import PullToRefresh from 'react-pull-to-refresh';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { Box, Modal } from '@mui/material';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';



function TaxSettings() {
  const [taxData, setTaxData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useState({
    tax_name: '',
    rate_percent: '',
  });
  const [showActions, setShowActions] = useState(null); // Track which row's actions menu is open


  const fetchTaxList = async () => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      setError('No token found. Please log in.');
      setLoading(false);
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/setting/tax_list', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('API Error Details:', errorDetails);
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }


      const result = await response.json();
      setTaxData(result);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchTaxList();
  }, []);


  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setForm({
      tax_name: '',
      rate_percent: '',
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };


  const handleSave = async () => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      Swal.fire('Error', 'No token found. Please log in.', 'error');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/setting/tax_save', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tax_name: form.tax_name,
          rate_percent: parseFloat(form.rate_percent), // Ensure rate is a number
        }),
      });


      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error Saving Tax:', errorDetails);
        Swal.fire('Error', 'Failed to save the tax.', 'error');
        return;
      }


      const newTax = await response.json();
      setTaxData([...taxData, newTax]);
      setIsModalOpen(false);
      fetchTaxList();
      setForm({
        tax_name: '',
        rate_percent: '',
      });
      Swal.fire('Success', 'Tax added successfully!', 'success');
    } catch (err) {
      console.error('Error saving data:', err);
      Swal.fire('Error', 'Failed to save the tax.', 'error');
    }
  };


  const handleDelete = async (taxId) => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      Swal.fire('Error', 'No token found. Please log in.', 'error');
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/setting/tax_delete/${taxId}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error Deleting Tax:', errorDetails);
        Swal.fire('Error', 'Failed to delete the tax.', 'error');
        return;
      }


      // Remove the deleted tax from the table
      setTaxData(taxData.filter((tax) => tax.id !== taxId));
      fetchTaxList();
      Swal.fire('Success', 'Tax deleted successfully!', 'success');
    } catch (err) {
      console.error('Error deleting tax:', err);
      Swal.fire('Error', 'Failed to delete the tax.', 'error');
    }
  };


  const handleRefresh = async () => {
    setLoading(true);
    await fetchTaxList(); // Re-fetch the tax list
    return Promise.resolve(); // Return a resolved promise for PullToRefresh
  };


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  return (
    <PullToRefresh onRefresh={handleRefresh}>
       <div className="main-container">
       <div className="top-controls">
     <h1 className='title'>Tax Settings
       <div className="underline"></div>
     </h1>
     <div className="buttons-row">
     <button className='color-button' onClick={handleOpenModal}>
     Add Tax
           </button> </div>
      </div>

        <table >
          <thead>
            <tr>
              <th >ID</th>
              <th >Tax Name</th>
              <th >Rate (%)</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
            {taxData.map((tax) => (
              <tr key={tax.id}>
                <td>{tax.id}</td>
                <td>{tax.tax_name}</td>
                <td>{tax.rate_percent}</td>
                <td>
                   <div className="action-container">
                  <div className="action-dots"
                      onClick={() => setShowActions(tax.id === showActions ? null : tax.id)}
                    >
                      <GridMoreVertIcon/>
                    </div>
                    {showActions === tax.id && (
                      <div  className="action-menu">
                      <div className="action-item"
                          onClick={() => handleDelete(tax.id)}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="add-tax-modal"
      aria-describedby="modal-to-add-tax"
    >
      <Box className="designation-modal">
        <div className="designation-header">
          <h1 className="designation-title">Add Tax</h1>
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            className="designation-close"
            onClick={handleCloseModal}
          />
        </div>

        
    <img
      src={designationImage}
      alt="Designation"
      className='designation-image'
    />

        <div className="designation-form">
          <label className="designation-label">Tax Name</label>
          <input
            className="designation-input"
            name="tax_name"
            placeholder="Tax Name"
            value={form.tax_name}
            onChange={handleInputChange}
          />

          <label className="designation-label">Rate Percent</label>
          <input
            className="designation-input"
            name="rate_percent"
            placeholder="Rate (%)"
            value={form.rate_percent}
            onChange={handleInputChange}
            type="number"
          />
        </div>

          <button className="designation-save" onClick={handleSave}>
            Save
          </button>
         
      </Box>
    </Modal>


      </div>
    </PullToRefresh>
  );
}





export default TaxSettings;






