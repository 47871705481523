import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Settings.css';
import ProfileSettingg from '../Components/ProfileSettingg';
import RolePermissionSetting from '../Components/RolePermissionSetting';
import LeavesSetting from '../Components/LeavesSetting';
import AttendanceSetting from '../Components/AttendanceSetting';
import ProjectSetting from '../Components/ProjectSetting';
import FinanceSettingg from '../Components/FinanceSettingg';
import MessageSetting from '../Components/MessageSetting';
import TaskSetting from '../Components/TaskSetting';
import Banner from '../Components/Banner'; // Import Banner component
import TaxSettings from '../Components/TaxSettings';
import LeadSettings from '../Components/LeadSettings';
import BusinessAddress from '../Components/BusinessAddress';
import ProductSetting from '../Components/ProductSetting';








function Settings() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();








  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('authToken');








      if (!token) {
        setError('No token found. Please log in.');
        return;
      }








      try {
        const response = await fetch('https://api.myntask.com/auth/setting_sidebar', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });








        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }








        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError(error.message);
      }
    };








    fetchSettings();
  }, []); // Fetch settings once when the component mounts








  const handleTabClick = (index, item) => {
    setActiveTab(index);
    // Navigate to Banner component if "Banner" tab is clicked
    if (item.toLowerCase() === 'banner') {
      setActiveTab(2); // Set active tab to Banner
    }
  };








  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <ProfileSettingg />;
      case 1:
        return <RolePermissionSetting />;
      case 2:
        return <Banner />; // Display Banner component for "Banner" tab
      case 3:
        return <BusinessAddress />;
      case 4:
        return <TaxSettings />;
      case 5:
        return <LeadSettings />;
      case 6:
        return <ProjectSetting />;
      case 7:
        return <TaskSetting />;
      case 8:
        return <ProductSetting />;


      //  case :
      //    return <FinanceSettingg />;
      //  case :
      //    return <MessageSetting />;

      default:
        return <div>No content available for this tab.</div>;
    }
  };








  return (
    <div className="settings-container">
      {error && <p>Error: {error}</p>}
      {data.length > 0 ? (
        <div className="vertical-tabs">
          <div className="tab-list">
            {data.map((item, index) => (
              <div
                key={index}
                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleTabClick(index, item)}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="tab-content">{renderTabContent()}</div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}








export default Settings;
























