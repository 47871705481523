import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const ExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [totalExpense, setTotalExpense] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);


  const navigate = useNavigate();  // Initialize useNavigate


  useEffect(() => {
    const fetchExpenseReport = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch('https://api.myntask.com/report/expense', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch expense report');
        }


        const data = await response.json();
        setTotalExpense(data.totalExpense);
        setExpenses(data.expenses);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching expense report:', error);
        setError('Failed to load expense report');
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };


    fetchExpenseReport();
  }, []);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleExpenseClick = (expenseId) => {
    navigate(`/view-expense/${expenseId}`);  // Navigate to the view-expense screen with expense ID
  };


  const filteredExpenses = expenses.filter((expense) =>
    Object.values(expense).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentExpenses = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredExpenses.length / itemsPerPage);


  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };


  return (
    <div className='main-container'>
      <div style={styles.row}>
        <div style={styles.card}>
          <p style={styles.total}>Total Expense</p>
          <p style={styles.total}>{totalExpense}</p>
        </div>


        <input
          type="text"
          placeholder="Search expenses..."
          value={searchTerm}
          onChange={handleSearch}
          className='searchbar'
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Purchase Date</th>
            <th>Team Member</th>
            <th>Item Name</th>
            <th>Price</th>
            <th>Purchase From</th>
            <th>Status</th>
            <th>Bill</th>
          </tr>
        </thead>
        <tbody>
          {currentExpenses.map((expense) => (
            <tr key={expense.expense_id}>
              <td style={{ textAlign: 'center' }}>
                {new Date(expense.purchase_date).toLocaleDateString()}
              </td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={expense.user_image || 'default-image-url.jpg'}
                    alt={expense.user_name ? expense.user_name : 'user'}
                    className="image"
                  />
                  <div>
                    <span>{toTitleCase(expense.user_name || 'N/A')}</span>
                    <p style={{ color: '#777' }}>
                      {toTitleCase(expense.designation || 'N/A')}
                    </p>
                  </div>
                </div>
              </td>
              <td
                style={{ cursor: 'pointer' }}
                onClick={() => handleExpenseClick(expense.expense_id)}
              >
                {toTitleCase(expense.item_name)}
              </td>
              <td>{expense.price}</td>
              <td>{toTitleCase(expense.purchase_from || 'N/A')}</td>
              <td>{toTitleCase(expense.expense_status)}</td>
              <td>
                {expense.bill ? (
                  <a href={expense.bill} target="_blank" rel="noopener noreferrer">
                    View Bill
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={styles.pagination}>
        <button
          onClick={() => paginate(currentPage - 1)}
          style={styles.pageButton}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span style={styles.pageNumber}>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => paginate(currentPage + 1)}
          style={styles.pageButton}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};


const styles = {
  container: {
    width: '90%',
    margin: '0 auto',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  card: {
    display: 'flex',           // Use flexbox
    justifyContent: 'space-between', // Space between items
    alignItems: 'center',      // Align items vertically in the center
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    width: '400px',
    height: 'auto',
  },
  total: {
    margin: 0,                // Remove default margin
    fontSize: '20px',         // Adjust font size as needed
    fontWeight: 'bold',       // Optional: Make the text bold
    color: '#000'
  },
  pagination: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageButton: {
    padding: '8px 16px',
    margin: '0 8px',
    border: '1px solid #ddd',
    backgroundColor: '#3b82f6',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  pageNumber: {
    margin: '0 8px',
  },
};


export default ExpenseReport;


