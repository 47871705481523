import React, { useEffect, useState, useRef } from 'react';
import AddDealModal from '../Components/AddDealModal';
import AddFollowUpModal from '../Components/AddFollowUpModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FollowUpModal from '../Components/FollowUpModal';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [isAddFollowUpModalOpen, setIsAddFollowUpModalOpen] = useState(false); // New state for follow-up modal
  const [followUpDetails, setFollowUpDetails] = useState(null);  // State to hold follow-up details
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dropdownRef = useRef(null);


  const fetchDealsList = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/leads/deals_list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch deals');
      }
      const data = await response.json();
      setDeals(data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching deals:', err);
      setError('Failed to load deals.');
      setIsLoading(false);
    }
  };



  useEffect(() => {
    fetchDealsList();
  }, []);




  const handleAddDealClick = () => {
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleAddFollowUp = (dealId) => {
    setSelectedDealId(dealId);
    setIsAddFollowUpModalOpen(true);
  };


  const handleFollowUpAdded = (newFollowUp) => {
    console.log('Follow-Up added:', newFollowUp);
    // Update state if needed
  };


  const handleCloseAddFollowUpModal = () => {
    setIsAddFollowUpModalOpen(false);
  }


  const handleSaveDeal = (newDeal) => {
    setDeals((prevDeals) => [...prevDeals, newDeal]);
  };




  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  const toggleActionMenu = (dealId) => {
    setActionMenuVisible((prev) => (prev === dealId ? null : dealId));
  };



  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionMenuVisible(null);
    }
  };


  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {


      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  const handleViewFollowUp = (dealId) => {
    console.log('deal id is', dealId);
    setFollowUpDetails(dealId); // Set only the deal ID
    setIsFollowUpModalOpen(true); // Open the follow-up modal
  };




  const handleCloseFollowUpModal = () => {
    setIsFollowUpModalOpen(false);
    setFollowUpDetails(null);
    window.location.reload(); // Reloads the entire page
  };



  const filteredDeals = deals.filter((deal) => {
    return Object.keys(deal).some((key) =>
      String(deal[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });


  const toTitleCase = (str) => {
    if (!str) return 'N/A'; // Handle empty or undefined strings
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };



  // Loading animation.......................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className='main-container'>


      <div className="top-controls">
        <h1 className='title'>Deals
          <div className="underline"></div>
        </h1>


        <div className="buttons-row">


          <button className="color-button" onClick={handleAddDealClick}>
            Add Deal
          </button>


        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchQuery}
          onChange={handleSearch}
          style={{
            width: '100%',
            padding: '8px 10px',
            fontSize: '14px',
            border: '1px solid var(--border-color)',
            borderRadius: '4px',
            backgroundColor: 'var(--secondary-color)',
            color: 'var(--text-color)',
          }}
        />
      </div>
      {error && <p style={{ color: 'var(--accent-color-3)' }}>{error}</p>}
      {filteredDeals.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Company Name</th>
              <th>Client Name</th>
              <th>Client Email</th>
              <th>Status</th>
              <th>Assign to</th>
              <th>Created at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDeals.map((deal) => (
              <tr key={deal.id}>
                <td>{toTitleCase(deal.name)}</td>
                <td>{toTitleCase(deal.company_name)}</td>
                <td>{toTitleCase(deal.client_name)}</td>
                <td>{deal.client_email || 'N/A'}</td>
                <td>{toTitleCase(deal.status)}</td>
                <td>{toTitleCase(deal.assigned_user)}</td>
                <td>{new Date(deal.created_at).toLocaleDateString()}</td>
                <td>
                  <div className="action-container">
                    <div
                      className="action-dots"
                      onClick={() => toggleActionMenu(deal.id)}
                      style={{ cursor: 'pointer', position: 'relative', padding: '2px' }}
                    >
                      <MoreVertIcon />
                    </div>
                    {actionMenuVisible === deal.id && (
                      <div className="action-menu" ref={dropdownRef}>
                        <div className="action-item" onClick={() => handleViewFollowUp(deal.id)}>
                          Follow Up
                        </div>
                        <div className="action-item">Edit</div>
                        <div className="action-item">Delete</div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No deals found for this lead.</p>
      )}


      {/* Follow Up Modal */}
      <FollowUpModal
        isOpen={isFollowUpModalOpen}
        onClose={handleCloseFollowUpModal}
        dealId={followUpDetails} // Pass the deal ID
      />


      {/* Use the AddDealModal component */}
      <AddDealModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveDeal} />


      {/* <AddFollowUpModal isOpen={isAddFollowUpModalOpen} onClose={handleCloseAddFollowUpModal} onFollowUpAdded={handleFollowUpAdded}
        dealId={selectedDealId} /> */}
    </div>
  );
};




export default Deals;








