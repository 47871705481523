import { Box, Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ticketImage from '../assets/ticket.png'; // Import the ticket image
import closeIcon from '../assets/Icon.png'; // Import the close icon image

function RolePermissionSetting() {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [newRoleName, setNewRoleName] = useState(''); // State for new role input
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      const apiUrl = 'https://api.myntask.com/rolepermission/roles';
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setRoles(data);
        console.log('Roles data:', data);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setError(error.message);
      }
    };

    fetchRoles();
  }, []);

  const handleButtonClick = (role) => {
    navigate(`/employeecomponent/${role.role_id}`, { state: { role } });
  };

  const handleCreateRole = () => {
    setShowModal(true); // Show modal when "Create Role" is clicked
  };

  const handleSaveRole = async () => {
    const token = localStorage.getItem('authToken'); // Get the token from local storage
  
    if (!newRoleName.trim()) {
      alert('Role name cannot be empty!');
      return;
    }
  
    try {
      const response = await fetch('https://api.myntask.com/rolepermission/roles_create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newRoleName }), // Sending role name
      });
  
      if (!response.ok) {
        throw new Error('Failed to create role');
      }
  
      const result = await response.json();
      console.log('Role created successfully:', result);
  
      // Close the modal and reset the input
      setShowModal(false);
      setNewRoleName('');
  
      // Optional: Refresh the roles list or provide feedback
      alert('Role created successfully!');
    } catch (error) {
      console.error('Error creating role:', error);
      alert('Error creating role. Please try again.');
    }
  };

  return (
    <div>
      <div className="top-controls">
        <h1 className="title">
          Roles & Permission Setting
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleCreateRole}>
            Create Role
          </button>
        </div>
      </div>
      {error && <p>Error: {error}</p>}
      <div style={buttonContainerStyle}>
        {roles.length > 0 ? (
          roles.map((role) => (
            <button 
              key={role.role_id} 
              style={buttonStyle}
              onClick={() => handleButtonClick(role)}
            >
              {role.role_name} <span style={countStyle}>({role.member})</span>
            </button>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>

      <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="create-role-modal"
      aria-describedby="modal-to-create-new-role"
    >
      <Box className="designation-modal">
        <div className="designation-header">
          <h1 className="designation-title">Create New Role</h1>
          <img
            src={closeIcon} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            className="designation-close"
            onClick={() => setShowModal(false)}
          />
        </div>
        <img
              src={ticketImage}
              alt="Role"
             className='designation-image'
            />
        <div className="designation-form">
          <label className="designation-label">Role Name</label>
          <input
            type="text"
            placeholder="Enter role name"
            value={newRoleName}
            onChange={(e) => setNewRoleName(e.target.value)}
            className="designation-input"
          />
        </div>
       
          <button className="designation-save" onClick={handleSaveRole}>
            Save Role
          </button>

      </Box>
    </Modal>
    </div>
  );
}





export default RolePermissionSetting;

const buttonContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginTop: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
};

const countStyle = {
  marginLeft: '10px',
  fontWeight: 'bold',
};
