import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Tasks = ({ projectId }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken');
      const url = `https://api.myntask.com/project/tasks/${projectId}`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }


        const data = await response.json();
        setTasks(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setLoading(false);
      }
    };


    fetchTasks();
  }, [projectId]);


  const handleStatusChange = async (taskId, newStatus) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      toast.error('No token found');
      return;
    }
 
    const statusOption = statusOptions.find(option => option.status === newStatus);
    if (!statusOption) {
      setError('Invalid status selected');
      toast.error('Invalid status selected');
      return;
    }
 
    try {
      const response = await fetch('https://api.myntask.com/task/task_status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          task_id: taskId,
          status_id: statusOption.id,
        }),
      });
 
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
 
      const updatedTasks = tasks.map(task =>
        task.task_id === taskId ? { ...task, status: newStatus } : task
      );
      setTasks(updatedTasks);
     
      // Show success toast
      toast.success('Task status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      setError(error.message);
 
      // Show error toast
      toast.error(`Error: ${error.message}`);
    }
  };
 
  useEffect(() => {
    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/task/task_status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch status options');
        }


        const data = await response.json();
        setStatusOptions(data);
      } catch (error) {
        console.error('Error fetching status options:', error);
        setError(error.message);
      }
    };


    fetchStatusOptions();
  }, []);


  const truncateTaskName = (taskName) => {
    const words = taskName.split(' ');
    if (words.length > 6) {
      return words.slice(0, 6).join(' ') + '...'; // Truncate after 6 words
    }
    return taskName;
  };


  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ['Code', 'Task', 'Start Date', 'Due Date', 'Status', 'Assigned To'];
    const tableRows = [];
 
 
    tasks.forEach(task => {
      const assignedUsers = task.assigned_users.map(user => user.name).join(', ');
 
 
      const taskData = [
        task.code || 'N/A',
        truncateTaskName(task.task) || 'N/A',
        task.start_date || 'N/A',
        task.due_date || 'N/A',
        task.status || 'N/A',
        assignedUsers || 'N/A',
      ];
 
 
      tableRows.push(taskData);
    });
 
 
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });
 
 
    doc.save('tasks_table.pdf');
  };




  const statusColors = {
    "Incomplete": "red",
    "To Do": "blue",
    "Doing": "orange",
    "Completed": "green"
  };
 


  if (loading) {
    return <p>Loading tasks...</p>;
  }


  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">Task</h1>
        <div className="buttons-row">
          <button className="color-button" onClick={() => navigate('/new-task')}>Add Task</button>
          <button className="color-button" onClick={() => handleDownloadPdf()}>Download Table</button>
        </div>
      </div>
      
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Task</th>
            <th>Start Date</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Assigned To</th>
          </tr>
        </thead>
        <tbody>
          {tasks.length === 0 ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center', padding: '8px' }}>No data is available</td>
            </tr>
          ) : (
            tasks.map((task) => (
              <tr key={task.task_id}>
                <td>{task.code}</td>
                <td><strong>{truncateTaskName(task.task)}</strong></td>
                <td>{task.start_date}</td>
                <td>{task.due_date}</td>
                <td>
                  <div className="custom-select-container" style={{ position: 'relative', width: '100%' }}>
                    <div
                      className="selected-item"
                      style={{
                        padding: '4px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: statusColors[task.status],
                          borderRadius: '50%',
                          marginRight: '8px',
                        }}
                      />
                      {task.status}
                    </div>
                    <select
                      value={task.status}
                      onChange={(e) => handleStatusChange(task.task_id, e.target.value)}
                      style={{
                        width: '100%',
                        padding: '5px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                        appearance: 'none',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        opacity: 0,
                      }}
                    >
                      {statusOptions.map(option => (
                        <option key={option.id} value={option.status}>
                          {option.status}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td>
                  <div style={{ display: 'flex'}}>
                    {task.assigned_users.map((user, index) => (
                      <div key={index}>
                        <img src={user.image} alt={user.name} className="image" style={{margin:'0px'}}/>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};


export default Tasks;






