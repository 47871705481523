import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GridMoreVertIcon } from '@mui/x-data-grid';
import './../Components/Main.css';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from 'sweetalert2';
import { Modal, TextField, Button, Box, Typography, Stack } from "@mui/material";
import iconImage from '../assets/Icon.png';
import { Vortex } from 'react-loader-spinner';


const Clients = () => {
  const [clients, setClients] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClients, setSelectedClients] = useState({});
  const [showOptions1, setShowOptions1] = useState(false);
  const [dropdown1, setDropdown1] = useState("");
  const [notification, setNotification] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: 'client_name', direction: 'ascending' });
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteComment, setInviteComment] = useState("");
  const dropdownRef = useRef(null);


  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/client/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error("Failed to fetch clients");
        }


        const data = await response.json();
        setClients(data.data); // assuming client data is inside `data.data`
        setPermissions(data.client_permission); // storing permissions
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClients();
  }, []);



  const handleExportPDF = () => {
    const input = document.getElementById("client-table"); // The table to export

    if (!input) {
      console.error("Element with ID 'client-table' not found.");
      return;
    }

    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 190; // Width of the image in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

        pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
        pdf.save("Clients.pdf"); // Download the file as Clients.pdf
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };





  const handleOpenInviteModal = () => setIsInviteModalOpen(true);
  const handleCloseInviteModal = () => setIsInviteModalOpen(false);




  const handleSendInvite = async () => {
    const token = localStorage.getItem("authToken");


    if (!inviteEmail || !inviteComment) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all fields.',
      });
      return;
    }


    try {
      const response = await fetch("https://api.myntask.com/employee/invite_employee", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: inviteEmail, comment: inviteComment }),
      });


      if (!response.ok) {
        throw new Error("Failed to send invite");
      }


      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Invite sent successfully!',
      });
      setIsInviteModalOpen(false);
      setInviteEmail("");
      setInviteComment("");
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error: ${error.message}`,
      });
    }
  };



  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };




  const handleAddClient = () => {
    navigate("/addClient");
  };




  const handleCheckboxChange = (clientId, userId) => {
    setSelectedClients((prevState) => {
      const newState = { ...prevState, [clientId]: { selected: !prevState[clientId]?.selected, userId } };
      setShowOptions1(Object.keys(newState).some((id) => newState[id].selected));
      return newState;
    });
  };




  const handleDropdown1Change = (e) => {
    setDropdown1(e.target.value);
  };




  const handleApply = async () => {
    const selectedClientId = Object.keys(selectedClients).find(
      (clientId) => selectedClients[clientId].selected
    );


    if (selectedClientId) {
      const { userId } = selectedClients[selectedClientId];
      const token = localStorage.getItem("authToken");


      try {
        if (dropdown1 === "edit") {
          navigate(`/ClientEdit/${selectedClientId}`);
        } else if (dropdown1 === "delete") {
          // Show confirmation dialog using SweetAlert
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          });


          // If confirmed, proceed with deletion
          if (result.isConfirmed) {
            const response = await fetch("https://api.myntask.com/client/delete", {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                client: selectedClientId,
                user_id: userId,
              }),
            });


            if (!response.ok) {
              Swal.fire({
                icon: 'error',
                title: 'Failed to delete client',
                text: 'Please try again later.',
              });
              throw new Error("Failed to delete client");
            }


            setClients((prevClients) =>
              prevClients.filter((client) => client.id !== parseInt(selectedClientId))
            );
            Swal.fire(
              'Deleted!',
              'Client deleted successfully.',
              'success'
            );
          }
        }
      } catch (error) {
        setNotification(`Error: ${error.message}`);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'No client selected',
        text: 'Please select a client to proceed.',
      });
    }
  };






  const handleActionButtonClick = (e, clientId) => {
    e.stopPropagation();
    // Toggle the dropdown menu
    if (anchorEl && selectedClientId === clientId) {
      setAnchorEl(null);
      setSelectedClientId(null);
    } else {
      setAnchorEl(e.currentTarget);
      setSelectedClientId(clientId);
    }
  };




  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAnchorEl(null);
        setSelectedClientId(null);
      }
    };


    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  const handleAction = async (action, clientId, userId) => {
    if (action === "Edit") {
      navigate(`/ClientEdit/${clientId}`);
    } else if (action === "Delete") {
      // Show confirmation dialog using SweetAlert
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });


      // If confirmed, proceed with deletion
      if (result.isConfirmed) {
        const token = localStorage.getItem("authToken");


        try {
          const response = await fetch("https://api.myntask.com/client/delete", {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ client: clientId, user_id: userId }),
          });


          if (!response.ok) {
            Swal.fire({
              icon: 'error',
              title: 'Failed to delete client',
              text: 'Please try again later.',
            });
            throw new Error("Failed to delete client");
          }


          setClients((prevClients) =>
            prevClients.filter((client) => client.id !== parseInt(clientId))
          );
          Swal.fire(
            'Deleted!',
            'Client deleted successfully.',
            'success'
          );
        } catch (error) {
          setNotification(`Error: ${error.message}`);
        }
      }
    }


    // Close dropdown after action
    setAnchorEl(null);
    setSelectedClientId(null);
  };








  const filteredClients = clients.filter((client) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      client.client_name.toLowerCase().includes(searchLower) ||
      client.company_name.toLowerCase().includes(searchLower) ||
      (client.email && client.email.toLowerCase().includes(searchLower)) ||
      client.status.toLowerCase().includes(searchLower) ||
      client.created.includes(searchLower)
    );
  });


  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is zero-indexed
  };


  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const sortedClients = useMemo(() => {
    let sortableClients = [...filteredClients];
    if (sortConfig !== null) {
      sortableClients.sort((a, b) => {
        // Determine the values to compare
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];


        // Handle date sorting specifically
        if (sortConfig.key === 'created') {
          const aDate = parseDate(aValue);
          const bDate = parseDate(bValue);


          // Check if the dates are valid
          if (isNaN(aDate) || isNaN(bDate)) {
            return 0; // If either date is invalid, consider them equal
          }


          return sortConfig.direction === 'ascending' ? aDate - bDate : bDate - aDate;
        }


        // For string comparison, handle case-insensitivity
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }


        // Compare the values for string and numeric types
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }


    return sortableClients;
  }, [filteredClients, sortConfig]);


  const toTitleCase = (str) => {
    return str
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a string
  };









  if (error) {
    return <p>Error: {error}</p>;
  }
  const handleClientNameClick = (userId) => {
    navigate(`/ClientDetailTab/${userId}`);
  };


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <>
      <div className="main-container">
        <div className="top-controls">
          <h1 className='title'>Clients
            <div className="underline"></div>
          </h1>
          <div className="buttons-row">
            {showOptions1 && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <select onChange={handleDropdown1Change} value={dropdown1} className="color-button">
                  <option value="">Select Action</option>
                  {permissions.edit_clients === "all" && <option value="edit">Edit</option>}
                  {permissions.delete_clients === "all" && <option value="delete">Delete</option>}
                </select>
                <button onClick={handleApply} className="color-button">
                  Apply
                </button>
              </div>
            )}
            {permissions.add_clients === "all" && (
              <button className="color-button" onClick={handleAddClient}>
                Add client
              </button>
            )}
          </div>
        </div>
        <div className="top-controls">
          <div className="filters-and-search">


            <button className="border-button" onClick={handleExportPDF}>
              Export
            </button>
            {/* <button className="border-button" onClick={handleOpenInviteModal}>
              Invite
            </button> */}
            <input
              type="text"
              placeholder="Search by name or email..."
              value={searchTerm}
              onChange={handleSearch}
              className="searchbar"
            />
          </div>
        </div>
        {notification && <p>{notification}</p>}
        <table id="client-table">
          <thead>
            <tr>
              <th>Select</th>

              <th>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span onClick={() => handleSort('company_name')}>Company</span>
                  <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                    {sortConfig?.key === 'company_name' && sortConfig?.direction === 'ascending' ? (
                      <span onClick={() => handleSort('company_name')}>↑</span>
                    ) : sortConfig?.key === 'company_name' && sortConfig?.direction === 'descending' ? (
                      <span onClick={() => handleSort('company_name')}>↓</span>
                    ) : (
                      <span onClick={() => handleSort('company_name')}>↕</span>
                    )}
                  </div>
                </div>
              </th>

              <th>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                  <span onClick={() => handleSort('client_name')}>Contact person</span>
                  <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                    {sortConfig?.key === 'client_name' && sortConfig?.direction === 'ascending' ? (
                      <span onClick={() => handleSort('client_name')}>↑</span>
                    ) : sortConfig?.key === 'client_name' && sortConfig?.direction === 'descending' ? (
                      <span onClick={() => handleSort('client_name')}>↓</span>
                    ) : (
                      <span onClick={() => handleSort('client_name')}>↕</span>
                    )}
                  </div>
                </div>
              </th>

              <th>Email</th>
              <th>Status</th>
              <th>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span onClick={() => handleSort('created')}>Created</span>
                  <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                    {sortConfig?.key === 'created' && sortConfig?.direction === 'ascending' ? (
                      <span onClick={() => handleSort('created')}>↑</span>
                    ) : sortConfig?.key === 'created' && sortConfig?.direction === 'descending' ? (
                      <span onClick={() => handleSort('created')}>↓</span>
                    ) : (
                      <span onClick={() => handleSort('created')}>↕</span>
                    )}
                  </div>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>


          <tbody>
            {sortedClients.map((client, index) => (
              <tr key={client.id}>
                <td >
                  <input
                    type="checkbox"
                    checked={!!selectedClients[client.id]?.selected}
                    onChange={() => handleCheckboxChange(client.id, client.user_id)}
                  />
                </td>

                <td>
                  {client.company_name ? toTitleCase(client.company_name) : "N/A"}
                </td>
                <td data-label="Client Name">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={client.client_image ? client.client_image : 'default_client_image.jpg'}
                      alt={client.client_name || "Default"}
                      className="image"
                    />
                    <span
                      onClick={() => handleClientNameClick(client.user_id)}
                      style={{ cursor: 'pointer' }}
                    >
                      {client.client_name ? toTitleCase(client.client_name) : "N/A"}
                    </span>
                  </div>
                </td>
                <td>{client.email ? client.email : "N/A"}</td>
                <td >
                  <span
                    className={`status-indicator ${client.status === "active" ? "status-active" : "status-inactive"
                      }`}
                  ></span>
                  {client.status ? client.status.charAt(0).toUpperCase() + client.status.slice(1) : "N/A"}
                </td>
                <td >{client.created}</td>
                <td >
                  <div className="action-container">
                    <div
                      className="action-dots"
                      onClick={(e) => handleActionButtonClick(e, client.id)}
                    >
                      <GridMoreVertIcon />
                    </div>
                    {anchorEl && selectedClientId === client.id && (
                      <div className="action-menu" ref={dropdownRef}>
                        {permissions.edit_clients === "all" && (
                          <div className="action-item" onClick={() => handleAction('Edit', client.id, client.user_id)}>
                            Edit
                          </div>
                        )}
                        {permissions.delete_clients === "all" && (
                          <div className="action-item" onClick={() => handleAction('Delete', client.id, client.user_id)}>
                            Delete
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          open={isInviteModalOpen}
          onClose={handleCloseInviteModal}
          aria-labelledby="invite-modal-title"
          aria-describedby="invite-modal-description"
        >
          <Box
            className='designation-modal'
          >
            <div className='designation-header'>
              <h1 className="designation-title" >
                Invite Employee
              </h1>
              <img
                src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                alt="Close"
                className='designation-close'
                onClick={handleCloseInviteModal}
              />
            </div>

            <div className='designation-form'>
              <label className='designation-label'>
                Email:
              </label>
              <input
                type="email"
                id="invite-email"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                placeholder="Enter email"
                className='designation-input'
              />
            </div>

            {/* Comment Input Field */}
            <div >
              <label className='designation-label'>
                Comment:
              </label>
              <textarea
                id="invite-comment"
                value={inviteComment}
                onChange={(e) => setInviteComment(e.target.value)}
                placeholder="Enter comment"
                className='designation-input'
              />
            </div>


            <button
              onClick={handleSendInvite}
              className='designation-save'
            >
              Send Invite
            </button>

          </Box>
        </Modal>

      </div>
    </>


  );
};

export default Clients;
