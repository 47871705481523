import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ticketImage from '../assets/ticket.png'; // Import the ticket image
import closeIcon from '../assets/Icon.png'; // Import the close icon image
import { Box, Modal } from '@mui/material';


function LeadSettings() {
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [form, setForm] = useState({ type: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showActions, setShowActions] = useState(null); // Tracks which row's action menu is open


  const fetchLeadSourceList = async () => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      setError('No token found. Please log in.');
      setLoading(false);
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/setting/lead_source_list', {
        method: 'GET',
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });


      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('API Error Details:', errorDetails);
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }


      const result = await response.json();
      setLeadSourceData(result);
    } catch (err) {
      console.error('Error fetching lead source list:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const handleAddLeadSource = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire('Error', 'No token found. Please log in.', 'error');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/setting/lead_source_save', {
        method: 'POST',
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(form),
      });


      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error Saving Lead Source:', errorDetails);
        Swal.fire('Error', 'Failed to save the lead source.', 'error');
        return;
      }


      Swal.fire('Success', 'Lead source added successfully!', 'success');
      setIsModalOpen(false);
      setForm({ type: '' });
      fetchLeadSourceList(); // Reload the table
    } catch (err) {
      console.error('Error saving lead source:', err);
      Swal.fire('Error', 'Failed to save the lead source.', 'error');
    }
  };


  const handleDeleteLeadSource = async (leadSourceId) => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      Swal.fire('Error', 'No token found. Please log in.', 'error');
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/setting/lead_source_delete/${leadSourceId}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });


      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error Deleting Lead Source:', errorDetails);
        Swal.fire('Error', 'Failed to delete the lead source.', 'error');
        return;
      }


      setLeadSourceData(leadSourceData.filter((source) => source.id !== leadSourceId));
      Swal.fire('Success', 'Lead source deleted successfully!', 'success');
    } catch (err) {
      console.error('Error deleting lead source:', err);
      Swal.fire('Error', 'Failed to delete the lead source.', 'error');
    }
  };


  useEffect(() => {
    fetchLeadSourceList();
  }, []);


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  return (
    <div className="main-container">
       
    <div className="top-controls">
  <h1 className='title'>Lead Source Settings
    <div className="underline"></div>
  </h1>
  <div className="buttons-row">
  <button className='color-button' onClick={() => setIsModalOpen(true)}>Add Lead Source</button> </div>
   </div>

      <table >
        <thead>
          <tr>
            <th>ID</th>
            <th>Source Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {leadSourceData.map((source) => (
            <tr key={source.id}>
              <td >{source.id}</td>
              <td >{source.type}</td>
              <td >
                <div className="action-container">
                  <div className="action-dots"
                    onClick={() => setShowActions(source.id === showActions ? null : source.id)}
                  >
                   <GridMoreVertIcon/>
                  </div>
                  {showActions === source.id && (
                    <div  className="action-menu">
                      <div className="action-item"
                        onClick={() => handleDeleteLeadSource(source.id)}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="add-business-address-modal"
      aria-describedby="modal-to-add-business-address"
    >
      <Box className="designation-modal">
        <div className="designation-header">
          <h1 className="designation-title">Add Lead Source</h1>
          <img
            src={closeIcon} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            className="designation-close"
            onClick={() => setIsModalOpen(false)}
          />
        </div>

        <img
          src={ticketImage} // Replace 'designationImage' with the path or import for the desired image
          alt="Lead"
          className="designation-image"
        />

        <div className="designation-form">
          <label className="designation-label">Source</label>
          <input
            className="designation-input"
            name="type"
            placeholder="Source Type"
            value={form.type}
            onChange={(e) => setForm({ ...form, type: e.target.value })}
          />
        </div>

      
          <button className="designation-save" onClick={handleAddLeadSource}>
            Save
          </button>
       
      </Box>
    </Modal>
 

    </div>
  );
}


const styles = {
 
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    width: '400px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
};


export default LeadSettings;






