import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const LeadProfile = () => {
  const { id } = useParams();
  const [lead, setLead] = useState(null);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('profile');
  const [deals, setDeals] = useState([]);
  const [notice, setNotice] = useState([]);


  useEffect(() => {
    const fetchLeadData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {    
        const response = await fetch(`https://api.myntask.com/leads/lead_view/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch lead data');
        }


        const data = await response.json();
        setLead(data);
      } catch (err) {
        console.error('Error fetching lead data:', err);
        setError('Failed to load lead data.');
      }
    };


    fetchLeadData();
  }, [id]);


  useEffect(() => {
    const fetchNotices = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch(`https://api.myntask.com/leads/lead_notes/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch notice data');
        }


        const data = await response.json();
        console.log('Notice data:', data);
        setNotice(data);
      } catch (err) {
        console.error('Error fetching notices data:', err);
        setError('Failed to load notice data.');
      }
    };


    const fetchDeals = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch(`https://api.myntask.com/leads/deal_view/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch deals');
        }


        const data = await response.json();
        console.log('Deals data:', data);
        setDeals(data);
      } catch (err) {
        console.error('Error fetching deals:', err);
        setError('Failed to load deals.');
      }
    };


    if (activeTab === 'notice') {
      fetchNotices();
    } else if (activeTab === 'deals') {
      fetchDeals();
    }


  }, [id, activeTab]);


  const renderProfile = () => (
    <div style={profileContainerStyle}>
      {/* <h2 style={profileHeaderStyle}>Profile Info</h2> */}
      <div style={profileCardStyle}>
        {renderProfileItem('Name', lead.client_name)}
        {renderProfileItem('Email', lead.client_email)}
        {renderProfileItem('Company Name', lead.company_name || 'N/A')}
        {renderProfileItem('Website', lead.website || 'N/A')}
        {renderProfileItem('Mobile', lead.mobile || 'N/A')}
        {renderProfileItem('Address', lead.address || 'N/A')}
        {renderProfileItem('City', lead.city || 'N/A')}
        {renderProfileItem('State', lead.state || 'N/A')}
        {renderProfileItem('Country', lead.country || 'N/A')}
        {renderProfileItem('Postal Code', lead.postal_code || 'N/A')}
      </div>
    </div>
  );


  const renderProfileItem = (label, value) => (
    <div style={profileItemContainerStyle}>
      <p style={profileLabelStyle}>{label}</p>
      <p style={profileValueStyle}>{value}</p>
    </div>
  );


  const renderDeals = () => (
    <div>
      {/* <h2 style={sectionHeaderStyle}>Deals</h2> */}
      {deals.length > 0 ? (
        <table style={tableStyle}>
          <thead>
            <tr>
              {['Name', 'Company Name', 'Client Name', 'Client Email', 'Mobile', 'Value', 'Close Date', 'Stage'].map((header) => (
                <th key={header} style={tableHeaderStyle}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {deals.map(deal => (
              <tr key={deal.id} style={tableRowStyle}>
                <td style={tableCellStyle}>{deal.name}</td>
                <td style={tableCellStyle}>{deal.company_name}</td>
                <td style={tableCellStyle}>{deal.client_name}</td>
                <td style={tableCellStyle}>{deal.client_email}</td>
                <td style={tableCellStyle}>{deal.mobile}</td>
                <td style={tableCellStyle}>{deal.value}</td>
                <td style={tableCellStyle}>{deal.close_date}</td>
                <td style={tableCellStyle}>{deal.stage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={noDataStyle}>No deals found for this lead.</p>
      )}
    </div>
  );


  const renderNotice = () => (
    <div>
      {/* <h2 style={sectionHeaderStyle}>Notices</h2> */}
      {notice.length > 0 ? (
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Title</th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Created At</th>
            </tr>
          </thead>
          <tbody>
            {notice.map(notice => (
              <tr key={notice.id} style={tableRowStyle}>
                <td style={tableCellStyle}>{notice.title}</td>
                <td style={tableCellStyle}>{notice.type}</td>
                <td style={tableCellStyle}>{notice.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={noDataStyle}>No notices available for this lead.</p>
      )}
    </div>
  );


  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return renderProfile();
      case 'deals':
        return renderDeals();
      case 'notice':
        return renderNotice();
      default:
        return null;
    }
  };


  if (error) {
    return <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>;
  }


  if (!lead) {
    return <p style={loadingStyle}>Loading...</p>;
  }


  return (
    <div style={mainContainerStyle}>
      <h1 style={headerStyle}>Lead Profile</h1>
      <div style={tabContainerStyle}>
        <button onClick={() => setActiveTab('profile')} style={activeTab === 'profile' ? activeTabStyle : tabButtonStyle}>Profile</button>
        <button onClick={() => setActiveTab('deals')} style={activeTab === 'deals' ? activeTabStyle : tabButtonStyle}>Deals</button>
        <button onClick={() => setActiveTab('notice')} style={activeTab === 'notice' ? activeTabStyle : tabButtonStyle}>Notice</button>
      </div>
      <div style={tabContentStyle}>{renderTabContent()}</div>
    </div>
  );
};


export default LeadProfile;


const mainContainerStyle = {
  width: '100%',
  margin: '20px auto',
  padding: '10px',
 
  // backgroundColor: '#f8f9fc',
  // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
};


const headerStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  textAlign: 'center',
  marginBottom: '20px',
};


const tabContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#fff',
  padding: '10px',
  borderBottom: '1px solid #ddd',
};


const tabButtonStyle = {
  flex: 1,
  padding: '10px 15px',
  margin: '0 5px',
  border: '1px solid #ddd',
  borderRadius: '5px',
  backgroundColor: '#fff',
  color: '#333',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
};


const activeTabStyle = {
  ...tabButtonStyle,
  backgroundColor: '#007bff',
  color: '#fff',
  border: '1px solid #007bff',
};


const tabContentStyle = {
  padding: '20px',
  backgroundColor: '#fff',
};


const profileContainerStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
};


const profileHeaderStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '10px',
};


const profileCardStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '10px 20px',
};


const profileItemContainerStyle = {
  padding: '10px 15px',
  borderBottom: '1px solid #ddd',
  display: 'flex',
  flexDirection: 'column',
};


const profileLabelStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#666',
  marginBottom: '5px',
};


const profileValueStyle = {
  fontSize: '14px',
  color: '#333',
};


const sectionHeaderStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '10px',
};


const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '5px',
};


const tableHeaderStyle = {
  padding: '10px',
  // backgroundColor: '#007bff',
  color: '#fff',
  fontSize: '14px',
  textAlign: 'left',
};


const tableRowStyle = {
  borderBottom: '1px solid #ddd',
};


const tableCellStyle = {
  padding: '10px',
  textAlign: 'left',
  fontSize: '14px',
  color: '#333',
};


const noDataStyle = {
  color: '#666',
  textAlign: 'center',
  fontSize: '14px',
};


const loadingStyle = {
  textAlign: 'center',
  fontSize: '16px',
  color: '#555',
};




