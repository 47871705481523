import React, { useState, useEffect, useRef } from 'react';


function Assets({ userId }) {


    const [assets, setAssets] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProfileImage, setSelectedProfileImage] = useState('');


    const [actionMenuVisible, setActionMenuVisible] = useState(null);


    useEffect(() => {
        const fetchAssets = async () => {
            const token = localStorage.getItem('authToken'); // Get the token from local storage


            if (!token) {
                setError('No token found. Please log in.');
                return;
            }
            const apiUrl = `https://api.myntask.com/assets/list/${userId}`;
            console.log('Fetched userId:', userId);


            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });


                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }


                const data = await response.json();
                console.log('API Response:', data); // this line is only for printing on the console for checking the response from the API.
                setAssets(data);


            } catch (error) {
                console.error('Error fetching Assets:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchAssets();
    }, [userId]);




    const toggleActionMenu = (assetId) => {
        setActionMenuVisible((prev) => (prev === assetId ? null : assetId));
    };


    const handleActionSelect = (action, assetId, profileImage) => {
        if (action === 'view') {
            setSelectedProfileImage(profileImage || 'default-avatar.png');
            setIsModalOpen(true);
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProfileImage('');
    };


    return (
        <>
            <div className="assets-container">
                <h2>Assets Assigned</h2>
                {loading && <p>Loading assets...</p>}
                {error && <p className="error-message">{error}</p>}
                {!loading && !error && assets.length === 0 && <p>No assets found.</p>}
                {!loading && !error && assets.length > 0 && (
                    <table>
                        <thead>
                            <tr>
                                <th>Asset Name</th>
                                <th>Status</th>
                                <th>Assigned Date</th>
                                {/* <th>User ID</th> */}
                                <th>Lent To</th>
                                <th>Designation</th>
                                <th>Asset Img</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assets.map((asset, index) => (
                                <tr key={asset.assets_id} data-asset-id={asset.assets_id}>
                                    <td>{asset.asset_name}</td>
                                    <td style={{textAlign:'center'}}>{asset.assets_status}</td>
                                    <td style={{textAlign:'center'}}>{asset.date}</td>
                                    {/* <td>{asset.lent_to?.user_id || `${userId}`}</td>                 Display User ID */}
                                    <td>
                                        {asset.lent_to ? (
                                            <div className="info-container">
                                                <img
                                                    src={asset.lent_to.user_image || 'default-avatar.png'}
                                                    alt={asset.lent_to.user_name}
                                                    className="image"
                                                />
                                                <div>{asset.lent_to.user_name}</div>


                                            </div>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td style={{textAlign:'center'}}><div>{asset.lent_to.designation}</div></td>
                                    <td style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                       
                                            <button
                                                style={{ border: 'none', backgroundColor: 'transparent', color: '#3b82f6' }}
                                                onClick={() => handleActionSelect('view', asset.assets_id, asset.lent_to?.user_image)}
                                            >
                                                View
                                            </button>


                                       
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {isModalOpen && (
                <div onClick={closeModal} className="modal-overlay">
                    <div className="modal-content">
                        {/* <img
                            src={selectedProfileImage}
                            alt="User Profile"
                            style={styles.closeButton}
                        /> */}
                        <p>No Asset Image Found</p>
                        <button  onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};


export default Assets;

// Inline styles for the button, header layout, and modal
const styles = {

    closeButton: {
      marginTop: '20px',
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '10px 15px',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
   
    
  };