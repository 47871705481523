import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import noDataImage from '../assets/NoDataFound.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography, Modal } from '@mui/material';

function NoticeBoard() {
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);  // State to control modal visibility
  const [heading, setHeading] = useState('');  // State for heading input
  const [description, setDescription] = useState('');  // State for description input
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotices = async () => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch('https://api.myntask.com/notice/list', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notices');
      }

      const data = await response.json();
      setNotices(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching notices:', error);
      setError("Failed to load Notice Board");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  const handleViewClick = async (id) => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`https://api.myntask.com/notice/notice_view/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notice details');
      }

      const data = await response.json();
      setSelectedNotice(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching notice details:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotice(null);
  };

  // Toggle Modal Visibility
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  // Handle Form Submit (POST Request)
  const handleSubmit = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        title: 'Error',
        text: 'Authorization token is missing!',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    // Data to send in the POST request
    const noticeData = {
      heading: heading,
      description: description,
    };

    try {
      const response = await fetch('https://api.myntask.com/notice/add', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noticeData),
      });

      if (!response.ok) {
        throw new Error('Failed to add notice');
      }

      // Show success message
      Swal.fire({
        title: 'Success',
        text: 'Notice added successfully!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });

      // Close the modal after successful submission
      setShowModal(false);
      fetchNotices();

      // Clear the form fields
      setHeading('');
      setDescription('');

    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `Failed to add notice: ${error.message}`,
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      Swal.fire({
        title: 'Error',
        text: 'Authorization token is missing!',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    // Confirm delete action with the user
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this notice?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (!confirm.isConfirmed) return;

    try {
      const response = await fetch(`https://api.myntask.com/notice/delete/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete the notice');
      }

      Swal.fire({
        title: 'Deleted!',
        text: 'Notice has been deleted successfully.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });

      // Remove the deleted notice from the local state
      setNotices((prevNotices) => prevNotices.filter((notice) => notice.id !== id));
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `Failed to delete notice: ${error.message}`,
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }


  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Notice Board
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={toggleModal}>
            Add Notice
          </button>
        </div>
      </div>


      {notices.length > 0 ? (

        <table >
          <thead>
            <tr>
              <th>Heading</th>
              <th >Date</th>
              <th >View Notice</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {notices.map(notice => (
              <tr key={notice.id}>
                <td>{notice.heading}</td>
                <td >{notice.date}</td>
                <td >
                  <span onClick={() => handleViewClick(notice.id)} >View</span>
                </td>
                <td >
                  <span
                    onClick={() => handleDelete(notice.id)}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No Notice available</p>
        </div>
      )}






      {/* Modal for adding a new notice */}
      <Modal open={showModal} onClose={toggleModal}>
        <Box
          className='designation-modal'
        >
          {/* Close Button Replaced with Image */}
          <div className='designation-header'>
            <h1 className="designation-title" >
              Add Notice
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={toggleModal}
            />
          </div>


          <img
            src={designationImage}
            alt="Designation"
            className='designation-image'
          />


          {/* Form Fields */}
          <div className='designation-form'>
            <label className='designation-label'>Heading:</label>
            <input
              type="text"
              id="heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Enter notice heading"
              className='designation-input'
            />

            <label className='designation-label'>Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter notice description"
              className='designation-input'
            />
          </div>

          {/* Submit Button */}

          <button
            className='designation-save'
            onClick={handleSubmit}

          >
            Add Notice
          </button>

        </Box>
      </Modal>

      {isModalOpen && selectedNotice && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <h2>{selectedNotice.heading}</h2>
            <p>{selectedNotice.date}</p>
            <p>{selectedNotice.description}</p>
            <button onClick={closeModal} style={closeButtonStyle}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NoticeBoard;


const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '500px',  // Increase width for better content space
  maxWidth: '90%',
  maxHeight: '80%',  // Restrict max height
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  overflowY: 'auto',  // Add vertical scroll if content overflows
};

const closeButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'block',
  width: '100px',
  margin: '20px auto 0 auto',  // Center the button
};
