import React, { useEffect, useState } from 'react';

function LeaveInfo({ userId }) {
  const [leaves, setLeaves] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLeaves = async () => {
      const token = localStorage.getItem('authToken');
      const leaveUrl = `https://api.myntask.com/leave/list/${userId}`;

      console.log('Fetching Leave URL:', leaveUrl);

      if (!token) {
        console.error('No auth token found');
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(leaveUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log('Fetched leaves data:', responseData);

        if (Array.isArray(responseData.data)) {
          setLeaves(responseData.data);
        } else {
          setError('Unexpected data format');
        }

      } catch (err) {
        console.error('Error fetching leave data:', err);
        setError('Failed to fetch leave data. Please try again later.');
      }
    };

    fetchLeaves();
  }, [userId]);

  return (
    <div style={styles.container}>
      <h2>Leaves</h2>
      {error && <p style={styles.errorText}>{error}</p>}
      {leaves.length > 0 ? (
        <table >
          <thead>
            <tr>
              
              <th >Name</th>
              <th >Designation</th>
              <th >Duration</th>
              <th >Reason</th>
              <th >Status</th>
              <th >Date</th>
            </tr>
          </thead>
          <tbody>
            {leaves.map((leave, index) => (
              <tr key={leave.leave_id} style={styles.tableRow}>
               
                <td >
                  <img src={leave.image} alt={leave.name} style={styles.avatar} />
                  {leave.name}
                </td>
                <td >{leave.designation || 'N/A'}</td>
                <td >{leave.duration || 'N/A'}</td>
                <td >{leave.reason || 'N/A'}</td>
                <td >{leave.status || 'N/A'}</td>
                <td >
  {leave.leave_date
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(leave.leave_date))
    : 'N/A'}
</td>

              </tr>
            ))}
          </tbody>
        </table>
      ) : !error ? (
        <p style={styles.noDataText}>No leave data available.</p>
      ) : null}
    </div>
  );
}

// CSS-in-JS styles
const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
    width: '100%',
    boxSizing: 'border-box',
  },
  leaveTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  tableHeader: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    padding: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  tableRow: {
    borderBottom: '1px solid #dddddd',
    backgroundColor: '#f9f9f9',
  },
  tableCell: {
    padding: '10px',
    textAlign: 'left',
    fontSize: '0.9em',
  },
  avatar: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  errorText: {
    color: '#d9534f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  noDataText: {
    textAlign: 'center',
    color: '#777',
    fontSize: '0.9em',
  },
};

export default LeaveInfo;
