import React, { useEffect, useState } from 'react';
import { Modal, Box} from '@mui/material';
import Swal from 'sweetalert2';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';


function EditDepartmentModal({ open, handleClose, departmentId, onDepartmentUpdated}) {
  const [departmentName, setDepartmentName] = useState('');
  const [parentId, setParentId] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [error, setError] = useState('');


  useEffect(() => {
    const fetchDepartmentDetails = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found');
        return;
      }


      try {
        const response = await fetch(`https://api.myntask.com/employee/department_edit/${departmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error(`Failed to fetch department details: ${response.statusText}`);
        }


        const [data] = await response.json(); // Assuming the response is an array
   
        setDepartmentName(data.team_name || '');
        setParentId(data.parent_id || '');
      } catch (err) {
        console.error('Error fetching department details:', err);
        setError(`Error fetching department details: ${err.message}`);
      }
    };


    const fetchDepartmentOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/employee/department', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error(`Failed to fetch department options: ${response.statusText}`);
        }


        const data = await response.json();
        setDepartmentOptions(data);
      } catch (err) {
        console.error('Error fetching department options:', err);
        setError(`Error fetching department options: ${err.message}`);
      }
    };


    if (departmentId) {
      fetchDepartmentDetails();
    }
    fetchDepartmentOptions();
  }, [departmentId]);


  const handleSave = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        setError('No authentication token found');
        return;
    }

    try {
        const response = await fetch('https://api.myntask.com/employee/department_update', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                department_id: departmentId,
                name: departmentName,
                parent_id: parentId || 0, // Default to 0 if parentId is empty
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to update department: ${response.statusText}`);
        }

        // Call the update function to refresh the data in the parent component
        onDepartmentUpdated(); // Refresh department list
        handleClose(); // Close the modal
        Swal.fire('Success!', 'Department updated successfully!', 'success');
    } catch (err) {
        console.error('Error updating department:', err);
        setError(`Error updating department: ${err.message}`);
        
        // Display error message using SweetAlert
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Error updating department: ${err.message}`,
        });
    }
};



  return (
    <Modal open={open} onClose={handleClose}>
      <Box className='designation-modal'>
      <div className='designation-header'>
      <h1 className="designation-title" >
        Edit Department
      </h1> 
      <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className='designation-close'
        onClick={handleClose}
      />
    </div>
    <img
      src={designationImage}
      alt="Designation"
      className='designation-image'
    />

        {error && <p>{error}</p>}
        <div className='designation-form'>
      <label
       className='designation-label'
      >Department Name</label>
    <input
      type="text"
      id="departmentName"
      value={departmentName}
      onChange={(e) => setDepartmentName(e.target.value)}
       className='designation-input'
    />
   
    <label  className='designation-label'>Parent</label>
    <select
      id="parentId"
      value={parentId}
      onChange={(e) => setParentId(e.target.value)}
      className='designation-input'
    >
      <option value={0}>
        None
      </option>
      {departmentOptions.map((option) => (
        <option key={option.id} value={option.id}>
          {option.department}
        </option>
      ))}
    </select>
   
    <button
     className='designation-save'
     nClick={handleSave}
    >
      Save
    </button>
      
      </div>
      </Box>
    </Modal>
  );
}


export default EditDepartmentModal;


