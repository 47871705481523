
import React, { useEffect, useState } from 'react';
import {Modal,Box } from '@mui/material';
import swal from 'sweetalert';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';

const ProjectSetting = () => {

    const [categories, setCategories] = useState([]);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [newCategory, setNewCategory] = useState('');
   

    const fetchData = async (url, setter) => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setter(data || []);
        } catch (error) {
          console.error(`Error fetching data from ${url}:`, error);
          setter([]);
        }
      };
    
      useEffect(() => {
        fetchData('https://api.myntask.com/project/category', setCategories);
        
      }, []);

      const handleAddCategory = () => {
        setOpenCategoryModal(true);
      };
  
    
      const handleSaveCategory = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch('https://api.myntask.com/project/project_category_add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name: newCategory }),
          });
    
          if (!response.ok) {
            throw new Error('Failed to add category');
          }
          
          setOpenCategoryModal(false);
          setNewCategory('');
          fetchData('https://api.myntask.com/project/category', setCategories);
          // Success alert using SweetAlert
          swal({
            title: 'Success!',
            text: 'Category added successfully!',
            icon: 'success',
            timer: 1500,
            buttons: false,
          });
    
        } catch (error) {
          console.error('Error adding category:', error);
    
          // Error alert using SweetAlert
          swal({
            title: 'Error',
            text: 'Error adding category. Please try again.',
            icon: 'error',
            timer: 1500,
            buttons: false,
          });
        }
    };
    
    
    const handleDeleteCategory = async (categoryId) => {
      const token = localStorage.getItem('authToken');
    
      try {
        // Ask for confirmation using SweetAlert
        const willDelete = await swal({
          title: 'Are you sure?',
          text: 'Do you really want to delete this category? This action cannot be undone.',
          icon: 'warning',
          buttons: ['Cancel', 'Yes, delete it!'],
          dangerMode: true,
        });
    
        if (!willDelete) {
          return; // User canceled the deletion
        }
    
        const response = await fetch(`https://api.myntask.com/project/project_category_delete/${categoryId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error('Failed to delete category');
        }
    
        fetchData('https://api.myntask.com/project/category', setCategories);
        // Show success alert
        swal({
          title: 'Deleted!',
          text: 'Category deleted successfully!',
          icon: 'success',
          timer: 1500,
          buttons: false,
        });
    
      } catch (error) {
        console.error('Error deleting category:', error);
    
        // Show error alert
        swal({
          title: 'Error',
          text: 'Error deleting category. Please try again.',
          icon: 'error',
          timer: 1500,
          buttons: false,
        });
      }
    };

  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Project Category Setting
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleAddCategory} >
            Add Category
          </button>
        </div>
      </div>

      <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Categories</th>
                <th>Delete</th>
            </tr>
        </thead>
        <tbody>
            {categories.map((category) =>(
                <tr key={category.id}>
                    <td>{category.id}</td>
                    <td>{category.category_name}</td>
                    <td><span onClick={() => handleDeleteCategory(category.id)} style={{ cursor: "pointer", color: "red" }}>
  Delete
</span>
</td>
                </tr>
            ))
            }
        </tbody>
      </table>

       {/*Category Add Modal */}
      <Modal
  open={openCategoryModal}
  onClose={() => setOpenCategoryModal(false)}
>
  <Box className="designation-modal">
    {/* Header with title and close button */}
    <div className="designation-header">
      <h1 className="designation-title">Add Category</h1>
      <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className="designation-close"
        onClick={() => setOpenCategoryModal(false)}
      />
    </div>

    {/* Form Section */}
    <div className="designation-form">
      <div className="form-group">
        <input
          type="text"
          name="newCategory"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Category Name"
          className="designation-input"
          required
        />
      </div>

        <button className='designation-save' onClick={handleSaveCategory}>
          Save
        </button>
       
    </div>
  </Box>
</Modal>

    </div>
  );
};

export default ProjectSetting;
