import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert2


const EditProduct = () => {
  const { id } = useParams(); // Extract product ID from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [taxDropdownVisible, setTaxDropdownVisible] = useState(false);  // Control visibility of tax dropdown
  const [formData, setFormData] = useState({
    type: "Goods",
    name: "",
    sku: "",
    brand: "",
    unitType: "",
    unitTypeId: null,
    productCategory: "",
    productCategoryId: null,
    productSubCategory: "",
    productSubCategoryId: null,
    sellingPrice: "",
    costPrice: "",
    tax: [],
    hsnSac: "",
    clientCanPurchase: false,
    description: "",
  });
  
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);
  
  // Load product data and dropdown options when component mounts
  useEffect(() => {
    const fetchProduct = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found. Please log in again.');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/product/edit/${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch product details');
        }

        const [data] = await response.json(); // API returns array, destructure it
        setFormData({
            ...formData,
            type: data.type || "Goods",
            name: data.name,
            sku: data.sku,
            brand: data.brand_name,
            unitTypeId: data.unit_id,
            productCategoryId: data.category_id,
            productSubCategoryId: data.sub_category_id,
            sellingPrice: data.selling_price,
            costPrice: data.cost_price,
            tax: JSON.parse(data.taxes || "[]"), // Handle tax array
            hsnSac: data.hsn_code,
            clientCanPurchase: data.client_can_purchase === 1,
            description: data.description,
          });
      } catch (error) {
        setError('Error fetching product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleTaxSelect = (id) => {
    if (formData.tax.includes(id)) {
      setFormData({
        ...formData,
        tax: formData.tax.filter((taxId) => taxId !== id),
      });
    } else {
      setFormData({
        ...formData,
        tax: [...formData.tax, id],
      });
    }
  };
  

// Fetch Tax Options
useEffect(() => {
    const fetchTaxes = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) return;
  
      try {
        const response = await fetch("https://api.myntask.com/product/taxes", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
  
        if (!response.ok) throw new Error("Failed to fetch taxes");
  
        const data = await response.json();
        setTaxOptions(data);
      } catch (error) {
        console.error("Error fetching taxes:", error);
      }
    };
  
    fetchTaxes();
  }, []);
  
  
  // Fetch Category Options
  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) return;
  
  
      try {
        const response = await fetch("https://api.myntask.com/product/category", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
  
        if (!response.ok) throw new Error("Failed to fetch product categories");
  
  
        const data = await response.json();
        setCategoryOptions(data);
      } catch (error) {
        console.error("Error fetching product categories:", error);
      }
    };
  
    fetchCategories();
  }, []);
  
  
  
  // Fetch Unit Type Options
  useEffect(() => {
    const fetchUnitTypes = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) return;
  
      try {
        const response = await fetch("https://api.myntask.com/product/unit_types", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
  
        if (!response.ok) throw new Error("Failed to fetch unit types");
  
        const data = await response.json();
        setUnitTypeOptions(data);
      } catch (error) {
        console.error("Error fetching unit types:", error);
      }
    };
  
    fetchUnitTypes();
  }, []);
  
  
  
  // Fetch Sub-Category Options based on Product Category
  useEffect(() => {
    if (!formData.productCategoryId) return; // Skip if no category is selected
  
    const fetchSubCategories = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) return;
  
      try {
        const response = await fetch(
          `https://api.myntask.com/product/sub_category/${formData.productCategoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.ok) throw new Error("Failed to fetch sub-categories");
  
  
        const data = await response.json();
        setSubCategoryOptions(data);
      } catch (error) {
        console.error("Error fetching sub-categories:", error);
      }
    };
  
    fetchSubCategories();
  }, [formData.productCategoryId]);
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    console.log("FormData Before:", formData); // Log the current formData
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    console.log("FormData After:", formData); // Log after the update
  };
  



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare the updated product data
    const updatedProductData = {
      product_id: id,  // Assuming `id` is available in the component state or props
      category_id: formData.productCategoryId,
      sub_category_id: formData.subCategoryId,
      name: formData.name,
      sku: formData.sku,
      type: formData.type,  // "goods" or "service"
      unit_id: formData.unitTypeId,
      cost_price: formData.costPrice,
      selling_price: formData.sellingPrice,
      taxes: formData.tax,  // Array of selected tax IDs
      han_code: formData.hsnSac,
      brand: formData.brand,
      client_can_purchase: formData.clientCanPurchase ? 1 : 0,  // 1 for true, 0 for false
      description: formData.description,
    };
  
    // Get the auth token
    const token = localStorage.getItem("authToken");
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Authentication token missing!',
        timer: 3000,  // Close the alert after 3 seconds
        showConfirmButton: false,  // Hide the confirmation button
      });
      return;
    }
  
    try {
      const response = await fetch(`https://api.myntask.com/product/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedProductData),
      });
  
      // Log the raw response for debugging
      const responseText = await response.text(); // Get raw response as text
      console.log("Response Text:", responseText); // Log the raw response
  
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Product Updated!',
          text: 'Product updated successfully!',
          timer: 3000,  // Close the alert after 3 seconds
          showConfirmButton: false,  // Hide the confirmation button
        });
        navigate('/product');  // Redirect to product list page
      } 
      else {
        try {
          const errorData = JSON.parse(responseText);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorData.message || 'Failed to update product',
            timer: 3000,  // Close the alert after 3 seconds
            showConfirmButton: false,  // Hide the confirmation button
          });
        } catch (jsonError) {
          Swal.fire({
            icon: 'error',
            title: 'Unexpected Error',
            text: `Unexpected response: ${responseText}`,
            timer: 3000,  // Close the alert after 3 seconds
            showConfirmButton: false,  // Hide the confirmation button
          });
        }
      }
    } catch (error) {
      console.error('Error during product update:', error);
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'An error occurred while updating the product',
        timer: 3000,  // Close the alert after 3 seconds
        showConfirmButton: false,  // Hide the confirmation button
      });
    }
  };
  

  const handleCancel = () => {
    navigate('/product'); // Navigate to the product list page
  };
  

  return (
    <div className="main-container">
        <div className="top-controls">
       <h1 className='title'>Edit Product
         <div className="underline"></div>
       </h1>
     </div>

     {/* Radio Buttons */}
  <div className="radio-group">
    <label>
      <input
  
        type="radio"
        name="type"
        value="Goods"
        checked={formData.type === "Goods"}
        onChange={handleChange}
      />
      Goods
    </label>
    <label>
      <input
  
        type="radio"
        name="type"
        value="Service"
        checked={formData.type === "Service"}
        onChange={handleChange}
      />
      Service
    </label>
  </div>
     <div className="product-container">
  
  {/* Description Section */}
  <div className="section">
        <h3>Description</h3>
  <div >
    <label htmlFor="name">Name:</label>
    <input
  className="product-input"
      type="text"
      id="name"
      name="name"
      placeholder="Name"
      value={formData.name}
      onChange={handleChange}
      
      required
    />
  </div>

  <div>
    <label htmlFor="brand">Brand:</label>
    <input
  className="product-input"
      type="text"
      id="brand"
      name="brand"
      placeholder="Brand"
      value={formData.brand}
      onChange={handleChange}
   
    />
  </div>

    {/* Description */}
    <div>
    <label htmlFor="description">Description:</label>
    <textarea
    className="product-input"
      id="description"
      name="description"
      placeholder="Description"
      value={formData.description}
      onChange={handleChange}
    />
  </div>
</div>

       {/* Inventory Section */}
       <div className="section">
        <h3>Inventory</h3>
        <div>
    <label htmlFor="sku">SKU:</label>
    <input
  className="product-input"
      type="text"
      id="sku"
      name="sku"
      placeholder="SKU"
      value={formData.sku}
      onChange={handleChange}
     
    />
  </div>

   {/* HSN/SAC */}
   <div>
    <label htmlFor="hsnSac">HSN/SAC:</label>
    <input
  className="product-input"
      type="text"
      id="hsnSac"
      name="hsnSac"
      placeholder="HSN/SAC"
      value={formData.hsnSac}
      onChange={handleChange}
    />
  </div>

  {/* Unit Type Select */}
 <div>
    <label htmlFor="unitType">Unit Type:</label>
    <select
      id="unitType"
       className="product-input"
      name="unitTypeId"
      value={formData.unitTypeId}
      onChange={handleChange}
      required
    >
      <option value="">Select Unit Type</option>
      {unitTypeOptions.map((unitType) => (
        <option key={unitType.id} value={unitType.id}>
          {unitType.unit_type}
        </option>
      ))}
    </select>
  </div>

    {/* Checkbox */}
    <div className="checkbox-group">
    <label>
      <input
 
        type="checkbox"
        name="clientCanPurchase"
        checked={formData.clientCanPurchase}
        onChange={handleChange}
      />
      Client Can Purchase
    </label>
  </div>
  </div>

{/* Category Section */}
<div className="section">
        <h3>Category</h3>
  <div>
    <label htmlFor="category">Category:</label>
    <select
      id="category"
      name="productCategoryId"
      value={formData.productCategoryId}
      onChange={handleChange}
      required
      className="product-input"
    >
      <option value="">Select Category</option>
      {categoryOptions.map((category) => (
        <option key={category.id} value={category.id}>
          {category.category_name}
        </option>
      ))}
    </select>
  </div>

  {/* Sub-Category Select */}
  <div>
    <label htmlFor="subCategory">Sub-Category:</label>
    <select
      id="subCategory"
      name="productSubCategoryId"
      value={formData.productSubCategoryId}
      onChange={handleChange}
      required
      className="product-input"
    >
      <option value="">Select Sub-Category</option>
      {subCategoryOptions.map((subCategory) => (
        <option key={subCategory.id} value={subCategory.id}>
          {subCategory.category_name}
        </option>
      ))}
    </select>
  </div>
  </div>

   {/* Pricing Section */}
   <div className="section">
        <h3>Pricing</h3>
  <div>
    <label htmlFor="costPrice">Cost Price:</label>
    <input
  className="product-input"
      type="number"
      id="costPrice"
      name="costPrice"
      placeholder="Cost Price"
      value={formData.costPrice}
      onChange={handleChange}
      required
    />
  </div>

  <div>
    <label htmlFor="sellingPrice">Selling Price:</label>
    <input
  className="product-input"
      type="number"
      id="sellingPrice"
      name="sellingPrice"
      placeholder="Selling Price"
      value={formData.sellingPrice}
      onChange={handleChange}
      required
    />
  </div>

  {/* Tax Dropdown */}
<div className="multi-select">
    <label>Select Tax</label>
    <div
      className="multi-select-field"
      onClick={() => setTaxDropdownVisible(!taxDropdownVisible)}
    >
      {formData.tax.length === 0
        ? "Click to select taxes"
        : formData.tax
            .map((id) => taxOptions.find((tax) => tax.id === id)?.tax_name || id)
            .join(", ")}
    </div>

    {taxDropdownVisible && (
      <div className="multi-select-dropdown">
        {taxOptions.map((tax) => (
          <div
            key={tax.id}
            className={`dropdown-item ${
              formData.tax.includes(tax.id) ? "selected" : ""
            }`}
            onClick={() => handleTaxSelect(tax.id)}
          >
            <input
  className="product-input"
              type="checkbox"
              checked={formData.tax.includes(tax.id)}
              readOnly
            />
            {tax.tax_name}
          </div>
        ))}
      </div>
    )}
  </div>


  </div>

</div>



  {/* Buttons */}
  <div className="button-group">
    <button className='color-button' onClick={handleSubmit}>Save</button>
    <button className="border-button" onClick={handleCancel}>
      Cancel
    </button>
  </div>


    </div>
  );
};

export default EditProduct;
