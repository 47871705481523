import React, { useEffect, useState } from 'react';
import {
 Typography,
 Box,

} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useParams } from 'react-router-dom'; // Import useParams
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';


const Expense = () => {
 const { projectId } = useParams();
 const [expenses, setExpenses] = useState([]);
 const [loading, setLoading] = useState(true);
 const [statusTotals, setStatusTotals] = useState(null);
 const [openModal, setOpenModal] = useState(false); // Modal open state
 const [searchTerm, setSearchTerm] = useState('');
const [filteredExpenses, setFilteredExpenses] = useState([]);

useEffect(() => {
  const fetchExpenses = async () => {
    const token = localStorage.getItem('authToken');
    const url = `https://api.myntask.com/project/expense/${projectId}`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch expenses');
      }

      const data = await response.json();
      console.log('Expenses API Response:', data);

      setExpenses(data.expenses);
      setFilteredExpenses(data.expenses); // Initialize the filtered list
      setStatusTotals(data.status_totals);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching expenses:', error);
   
      setLoading(false);
    }
  };

  fetchExpenses();
}, [projectId]);



 if (loading) {
   return <Typography>Loading expenses...</Typography>;
 }


 const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();
  setSearchTerm(term);

  // Filter the expenses based on the search term
  const filtered = expenses.filter((expense) =>
    expense.item_name.toLowerCase().includes(term) || 
    expense.name.toLowerCase().includes(term) || 
    expense.purchase_from?.toLowerCase().includes(term) ||
    expense.status?.toLowerCase().includes(term)
  );
  setFilteredExpenses(filtered);
};


 // Data for Pie Chart
 const totalAmount = parseFloat(statusTotals.approved.replace(/[^0-9.-]+/g, "")) +
   parseFloat(statusTotals.paid.replace(/[^0-9.-]+/g, ""));
 const pieData = [
   { name: 'Pending', value: parseFloat(statusTotals.pending.replace(/[^0-9.-]+/g, "")) },
   { name: 'Approved', value: parseFloat(statusTotals.approved.replace(/[^0-9.-]+/g, "")) },
   { name: 'Rejected', value: parseFloat(statusTotals.rejected.replace(/[^0-9.-]+/g, "")) },
   { name: 'Paid', value: parseFloat(statusTotals.paid.replace(/[^0-9.-]+/g, "")) },
   { name: 'Total Expenses', value: totalAmount }
 ];


 // Colors for PieChart
 const COLORS = ['#FFBB28', '#0088FE', '#FF8042', '#00C49F', '#FF4500'];


 // Open and Close Modal Handlers
 const handleOpenModal = () => setOpenModal(true);
 const handleCloseModal = () => setOpenModal(false);


 // Handle Download PDF
 const handleDownloadPdf = () => {
   const input = document.getElementById('expensesTable');


   html2canvas(input).then((canvas) => {
     const imgData = canvas.toDataURL('image/png');
     const pdf = new jsPDF();
     pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
     pdf.save('expenses.pdf');
   });
 };


const handleExportToExcel = () => {
  if (expenses.length === 0) {
    Swal.fire('No data', 'No table data available to export.', 'info');
    return;
  }

  // Extract all table data, matching the displayed structure
  const exportData = filteredExpenses.map((expense, index) => ({
    Index: index + 1,
    "Item Name": expense.item_name,
    Price: expense.price,
    Employees: expense.name,
    "Purchased From": expense.purchase_from,
    "Purchase Date": expense.purchase_date,
    Status: expense.status,
  }));

  // Create a worksheet from the extracted data
  const worksheet = XLSX.utils.json_to_sheet(exportData);

  // Add column headers styled like the table
  const headerNames = [
    "Index",
    "Item Name",
    "Price",
    "Employees",
    "Purchased From",
    "Purchase Date",
    "Status",
  ];
  XLSX.utils.sheet_add_aoa(worksheet, [headerNames], { origin: "A1" });

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');

  // Trigger file download
  XLSX.writeFile(workbook, 'TableData.xlsx');
};


 return (
   <Box padding={2}>
     <div className="top-controls">
       <h1 className="title">
         {expenses.length > 0 && expenses[0].project_name ? (
           <>Expenses for {expenses[0].project_name}</>
         ) : (
           'Expenses'
         )}
         <div className="underline"></div>
       </h1>
     </div>
     <div className="top-controls">
     <div className="filters-and-search">
            <button className="border-button"  onClick={handleDownloadPdf}>
            Download PDF
            </button>
            <button className="border-button" onClick={handleExportToExcel}>
  Export
</button>

            <input
              type="text"
              placeholder="Search by name or email..."
              value={searchTerm}
              onChange={handleSearch}
              className="searchbar"
            />
          </div>
         </div>
   

     {/* Status Summary Section with Pie Chart and Text Summary */}
     {statusTotals && (
       <Box mb={4} p={2} border={1} borderRadius="8px" borderColor="#ddd" bgcolor="#f5f5f5">
         <Typography variant="h6" gutterBottom>Status Summary</Typography>
         <Box display="flex" justifyContent="space-between" alignItems="center">
           {/* Pie Chart Section */}
           <ResponsiveContainer width="50%" height={200}>
             <PieChart>
               <Pie
                 data={pieData}
                 cx="40%"
                 cy="50%"
                 labelLine={false}
                 label={({ name, value }) => `${name}: ₹${value.toLocaleString()}`}
                 outerRadius={60}
                 innerRadius={35}
                 fill="#8884d8"
                 dataKey="value"
               >
                 {pieData.map((entry, index) => (
                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                 ))}
               </Pie>
               <Tooltip formatter={(value) => `₹${value.toLocaleString()}`} />
             </PieChart>
           </ResponsiveContainer>


           {/* Status Summary Text Section */}
           <Box ml={4}>
             <Typography variant="body1" color="textPrimary" gutterBottom>
               <strong>Pending:</strong> ₹{parseFloat(statusTotals.pending.replace(/[^0-9.-]+/g, "")).toLocaleString()}
             </Typography>
             <Typography variant="body1" color="textPrimary" gutterBottom>
               <strong>Approved:</strong> ₹{parseFloat(statusTotals.approved.replace(/[^0-9.-]+/g, "")).toLocaleString()}
             </Typography>
             <Typography variant="body1" color="textPrimary" gutterBottom>
               <strong>Rejected:</strong> ₹{parseFloat(statusTotals.rejected.replace(/[^0-9.-]+/g, "")).toLocaleString()}
             </Typography>
             <Typography variant="body1" color="textPrimary" gutterBottom>
               <strong>Paid:</strong> ₹{parseFloat(statusTotals.paid.replace(/[^0-9.-]+/g, "")).toLocaleString()}
             </Typography>
             <Typography variant="body1" color="textPrimary" gutterBottom>
               <strong>Total Amount:</strong> ₹{totalAmount.toLocaleString()}
             </Typography>
           </Box>
         </Box>
       </Box>
     )}


     {/* Expenses Table */}
     <div id="expensesTable" >
       <table>
         <thead>
           <tr>
             <th>Index</th>
             <th>Item Name</th>
             <th>Price</th>
             <th>Employees</th>
             <th>Purchased From</th>
             <th style={{ textAlign: 'center' }}>Purchase Date</th>
             <th>Status</th>
           </tr>
         </thead>
         <tbody>
  {filteredExpenses.length === 0 ? (
    <tr>
      <td colSpan={7} style={{ textAlign: 'center', padding: '16px' }}>
        No expenses available
      </td>
    </tr>
  ) : (
    filteredExpenses.map((expense, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{expense.item_name}</td>
        <td>{`${expense.price}`}</td>
        <td style={{ padding: '8px 16px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={expense.user_avatar}
              alt={expense.name}
              className='image'
            />
            <div>
              <div>{expense.name}</div>
              <div>{expense.designation_name}</div>
            </div>
          </div>
        </td>
        <td>{expense.purchase_from}</td>
        <td>{expense.purchase_date}</td>
        <td>
          <div className='status-container'>
            <span
              className='status-dot'
              style={{
                backgroundColor:
                  expense.status === 'paid'
                    ? '#4caf50'
                    : expense.status === 'pending'
                    ? '#ff9800'
                    : expense.status === 'approved'
                    ? '#2196f3'
                    : '#f44336',
              }}
            ></span>
            <span>{expense.status}</span>
          </div>
        </td>
      </tr>
    ))
  )}
</tbody>

       </table>
     </div>
   </Box>
 );
};


export default Expense;




