import React, { useState } from "react";
import "./LandingPage.css"; // Add the CSS file for styling
import dashboardImage from "../assets/landing.png"; // Update the path if necessary
import partnerLogos from "../assets/sky.png"; // Add your partner logos if needed
import blackWhiteLogo from "../assets/black-white.png"; // Import the new logo image
import Login from "./Login"; // Import the Login component
import { Modal, Box } from "@mui/material"; // Material-UI for Modal


const LandingPage = () => {
 const [isModalOpen, setIsModalOpen] = useState(false);


 const handleOpenModal = () => {
   setIsModalOpen(true); // Open the modal
 };


 const handleCloseModal = () => {
   setIsModalOpen(false); // Close the modal
 };


 return (
   <div className="landing-page">
     {/* Top Navigation */}
     <div className="top-navigation">
       <div className="logo">
         <img src={blackWhiteLogo} alt="MynTask CRM Logo" className="logo-image" />
       </div>
       <button className="sign-in-button" onClick={handleOpenModal}>
         Sign In
       </button>
     </div>


     {/* Hero Section */}
     <section className="hero">
       <div className="hero-content">
         <h1>
           Manage Your <span className="highlight">Tasks</span>, Projects, and Teams with Ease
         </h1>
         <p className="hero-subtitle">
           MynTask CRM brings together all your business needs: HR, Project Management, Lead Management, and Purchase Orders — all in one platform.
         </p>
         <div className="cta-buttons">
           <button className="cta-button primary">Get Started Free</button>
           <button className="cta-button secondary">View Demo</button>
         </div>
       </div>
       <div className="hero-image">
         <img src={dashboardImage} alt="MynTask CRM Dashboard" />
       </div>
     </section>




  {/* Footer Section */}
<footer className="footer">
 <div className="footer-container">
   <div className="footer-column">
     <h3>Links</h3>
     <ul>
       <li>Home</li>
       <li>Contact</li>
       <li>About Us</li>
       <li>Get Started</li>
     </ul>
   </div>
   <div className="footer-column">
     <h3>Services</h3>
     <ul>
       <li>HR Management</li>
       <li>Project Management</li>
       <li>Lead Management</li>
       <li>Purchase Orders</li>
     </ul>
   </div>
   <div className="footer-column">
     <h3>Contact</h3>
     <ul>
       <li>Phone: +91 9876543210</li>
       <li>Email: support@myntaskcrm.com</li>
       <li>Address: 123 Business St, Jaipur, India</li>
       <li>Office Hours: Mon - Fri, 9:00 AM - 6:00 PM</li>
     </ul>
   </div>
 </div>
 <div className="footer-bottom">
   <p>
     Privacy Policy | Made with ❤️ by MynTask CRM
   </p>
 </div>
</footer>


     {/* Login Modal */}
     <Modal open={isModalOpen} onClose={handleCloseModal}>
       <Box
         sx={{
           position: "absolute",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)",
           width: "90%", // Adjusted width to 90%
           bgcolor: "background.paper",
           border: "2px solid #000",
           boxShadow: 24,
           p: 4,
           borderRadius: "10px",
           overflowY: "auto", // Enable scrolling for content if needed
         }}
       >
         <Login /> {/* Render the Login component inside the modal */}
       </Box>
     </Modal>
   </div>
 );
};


export default LandingPage;














