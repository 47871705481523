import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal,Pagination}from '@mui/material';
import Swal from 'sweetalert2';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';


const Members = ({ projectId }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false); // State for modal
  const [dropdownData, setDropdownData] = useState([]); // State for dropdown data
  const [selectedMembers, setSelectedMembers] = useState([]); // State for multiple selected members
  const [selectedRoles, setSelectedRoles] = useState({}); // State to manage roles for each member
  const [refreshTrigger, setRefreshTrigger] = useState(false); // State to trigger re-fetching of member data

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Function to handle role change and send API request
  const handleRoleChange = async (userId, e) => {
    const role = e.target.value;
    const isRemovingRole = role === null || role === undefined;
    const token = localStorage.getItem('authToken');

    const requestData = {
      project_id: projectId,
      user_id: isRemovingRole ? null : userId,
    };

    try {
      const response = await fetch('https://api.myntask.com/project/member_role_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Role updated successfully', data);
      setRefreshTrigger((prev) => !prev); // Toggle refresh trigger to re-fetch members
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  const handleRemoveAdmin = (userId) => {
    // Call handleRoleChange with null to remove the admin role
    handleRoleChange(null, { target: { value: null } });

    // Update the state to reflect that the user no longer has the admin role
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: null,
    }));
  };

  useEffect(() => {
    const fetchMembers = async () => {
      const token = localStorage.getItem('authToken');
      const url = `https://api.myntask.com/project/members/${projectId}`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch members data');
        }

        const data = await response.json();
        setMembers(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMembers();
  }, [projectId, refreshTrigger]); // Include refreshTrigger to re-fetch data when it changes

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddMember = async () => {
    const token = localStorage.getItem("authToken");
    const url = `https://api.myntask.com/project/add_project_member`;
  
    // Debugging: log the token, selected member, and project ID
    console.log("Token:", token);
    console.log("Selected Member:", selectedMembers);
    console.log("Project ID:", projectId);
  
    // Check if a member is selected
    if (!selectedMembers) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a member before saving.",
      });
      return;
    }
  
    const payload = {
      member_id: selectedMembers,
      project_id: projectId,
    };
  
    // Debugging: log the payload
    console.log("Payload:", payload);
  
    try {
      // Debugging: log before sending the request
      console.log("Sending request to add member...");
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      // Log the response status and handle errors
      console.log("Response Status:", response.status);
  
      if (!response.ok) {
        const errorText = await response.text();
        let errorMessage = "Error adding members";
        
        // Custom error messages for different status codes
        if (response.status === 401) {
          errorMessage = "Unauthorized: Please log in again.";
        } else if (response.status === 400) {
          errorMessage = "Bad Request: Invalid data sent.";
        }
  
        console.error("API Error:", errorText);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          timer: 2000, // Auto-close after 2 seconds
          showConfirmButton: false,
        });
        return;
      }
  
      const result = await response.json();
      console.log("API Result:", result);
  
      // If successful, show success message and reset state
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Members added successfully!",
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false,
      });
  
      handleClose(); // Close the modal
      setSelectedMembers([]); // Reset selected members
      setRefreshTrigger((prev) => !prev); // Toggle refresh trigger to re-fetch members
    } catch (err) {
      // If there's an error, log it and show error message
      console.error("Error:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error adding members",
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false,
      });
    }
  };
  

  const handleMemberChange = (event) => setSelectedMembers(event.target.value);

  // Fetch data for the dropdown when the user clicks on the dropdown
  const fetchDropdownData = async () => {
   
    const token = localStorage.getItem('authToken');
    const url = `https://api.myntask.com/employee/member`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch dropdown data');
      }

      const data = await response.json();
      setDropdownData(data); // Set the dropdown data
      
    } catch (err) {
      
      console.error('Error fetching dropdown data:', err);
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);


  const handleDeleteMember = async (memberId) => {
    const token = localStorage.getItem('authToken');
    const url = `https://api.myntask.com/project/delete_project_member`;
  
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_member_id: memberId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete member');
      }
  
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Member deleted successfully!',
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false,
      });
      setRefreshTrigger((prev) => !prev); // Toggle refresh trigger to re-fetch members
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message || 'Error deleting member',
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false,
      });
      console.error('Delete member error:', err);
    }
  };
  

  // Pagination functions
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const totalPages = Math.ceil(members.length / itemsPerPage);
  const currentMembers = members.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      {/* Button to open the modal */}
      <button onClick={handleOpen} className="color-button">
        Add Project Members
      </button>

      {/* Modal for adding project members */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.4)' },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            borderRadius: 2,
            boxShadow: 24,
            width: 500,
            maxHeight: '80vh',
            overflowY: 'auto',
            p: 3,
            zIndex: 1000,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
            Add Project Members
          </Typography>

          <RadioGroup row value={selectionType} onChange={handleSelectionChange}>
            <FormControlLabel value="members" control={<Radio />} label="Choose Members" />
            <FormControlLabel value="department" control={<Radio />} label="Choose Department" />
          </RadioGroup>

          {selectionType === 'members' && (
            <Box sx={{ mt: 2 }}>
              <Typography>Add Project Members</Typography>
              <Select
                multiple
                value={selectedMembers}
                onChange={handleMemberChange}
                onClick={handleDropdownClick}
                disabled={dropdownLoading}
                   className='designation-input'
              >
                {dropdownLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : dropdownData.length === 0 ? (
                  <MenuItem disabled>No data available</MenuItem>
                ) : (
                  dropdownData.map((member) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.member_name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          )}

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
            <Button onClick={handleAddMember} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      </Modal> */}

      <Modal open={open} onClose={handleClose}>
        <Box  className='designation-modal'>
        <div className='designation-header'>
        <h1 className="designation-title">Add Project Members
        </h1>
         
        <img
        src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
        alt="Close"
        className='designation-close'
        onClick={handleClose}
      />
      </div>

      <img
      src={designationImage}
      alt="Designation"
      className='designation-image'
    />

<div className='designation-form'>
          <label  className='designation-label'>Select Member</label>
          <select
  value={selectedMembers}
  onChange={handleMemberChange}
  className="designation-input"
>
  <option value="" disabled>
    Select a member
  </option>
  {dropdownData.map((member) => (
    <option key={member.id} value={member.id}>
      {member.member_name}
    </option>
  ))}
</select>

          <button
             className='designation-save'
             onClick={handleAddMember}
          >
            Save
          </button>
          </div>
        </Box>
      </Modal>

      {/* Members List Table */}
      <table style={{ marginTop: '15px' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Hourly Rate</th>
            <th>User Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentMembers.map((member, index) => {
            const isAdmin = member.project_admin === member.user_id;
            return (
              <tr key={member.user_id}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={member.image || 'default-image-url.jpg'}
                      alt={member.name || 'user'}
                      className="image"
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <span style={{ fontWeight: 'bold' }}>{member.name || 'N/A'}</span>
                      <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>
                        {member.designation_name || 'N/A'}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <input type="number" value="0" readOnly style={{ width: '50px' }} />
                </td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isAdmin && (
                      <label style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }}>
                        <input
                          type="radio"
                          name={`userRole_${member.user_id}`}
                          value="admin"
                          checked
                          readOnly
                        />
                        <span style={{ marginLeft: '4px' }}>Project Admin</span>
                        <button
                          onClick={() => handleRemoveAdmin(member.user_id)}
                          style={{
                            marginLeft: '10px',
                            backgroundColor: '#ff4d4d',
                            color: '#fff',
                            border: 'none',
                            padding: '5px 10px',
                            cursor: 'pointer',
                          }}
                        >
                          Remove
                        </button>
                      </label>
                    )}
                    {!isAdmin && (
                      <>
                        <label style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }}>
                          <input
                            type="radio"
                            name={`userRole_${member.user_id}`}
                            value="admin"
                            checked={selectedRoles[member.user_id] === 'admin'}
                            onChange={(e) => handleRoleChange(member.user_id, e)}
                          />
                          <span style={{ marginLeft: '4px' }}>Project Admin</span>
                        </label>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteMember(member.project_members_id)}
                    className="border-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default Members;
