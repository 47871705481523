// src/KnowledgeBase.js
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Button, Modal, Stack } from '@mui/material';
import Swal from 'sweetalert2';
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import { Vortex } from 'react-loader-spinner';



function KnowledgeBase() {
  const [categories, setCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [details, setDetails] = useState([]);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control add modal visibility
  const [categoryId, setCategoryId] = useState('');
  const [heading, setHeading] = useState(''); // State for heading
  const [description, setDescription] = useState(''); // State for description
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false); // Add Category modal
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCategories = async () => {
    const token = localStorage.getItem('authToken');


    if (!token) {
      setError('No token found. Please log in.');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/knowledge_base/category', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }


      const result = await response.json();
      console.log('Categories:', result);
      setCategories(result);


      if (result.length > 0) {
        fetchDetails(result[0].id);
      }

      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Failed to fetch categories');
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchCategories();
  }, []);


  const fetchDetails = async (id) => {
    const token = localStorage.getItem('authToken');


    try {
      const response = await fetch(`https://api.myntask.com/knowledge_base/list/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch details');
      }


      const result = await response.json();
      console.log(`Details for category ${id}:`, result);
      setDetails(result);
      setIsLoading(false);
    } catch (err) {
      console.error(`Error fetching details for category ${id}:`, err);
      setError('Failed to fetch details');
      setIsLoading(false);
    }
  };


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    fetchDetails(categories[newValue].id);
  };


  const handleViewClick = async (id) => {
    const token = localStorage.getItem('authToken');


    try {
      const response = await fetch(`https://api.myntask.com/knowledge_base/view/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch item details');
      }


      const result = await response.json();
      console.log(`Details for item ${id}:`, result); // Console log the data
      setModalData(result[0]); // Assuming the result is an array, take the first item
      setOpenModal(true);
    } catch (err) {
      console.error(`Error fetching item details for id ${id}:`, err);
    }
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData(null);
  };

  const toggleModal = () => setShowModal((prev) => !prev);


  const handleSubmit = async () => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      Swal.fire({
        title: 'Error',
        text: 'Authorization token is missing!',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }


    // Data to send in the POST request
    const knowledgeData = {
      category_id: categoryId,
      heading: heading,
      description: description,
    };

    try {
      const response = await fetch('https://api.myntask.com/knowledge_base/knowledge_add', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Correct string interpolation for token
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(knowledgeData),
      });

      if (!response.ok) {
        throw new Error('Failed to add knowledge base');
      }

      // Show success message
      Swal.fire({
        title: 'Success',
        text: 'Knowledge base added successfully!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });

      // Close the modal after successful submission
      setShowModal(false);

      // Clear the form fields
      setHeading('');
      setDescription('');
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `Failed to add knowledge base: ${error.message}`, // Correct string interpolation
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };


  const handleDelete = async (id) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      Swal.fire({
        title: 'Error',
        text: 'Authorization token is missing!',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    // Confirm deletion with SweetAlert
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this knowledge base item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.myntask.com/knowledge_base/delete/${id}/`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete item');
        }

        const data = await response.json();
        console.log('Item deleted successfully:', data);

        // Remove the deleted item from the list of details
        setDetails(prevDetails => prevDetails.filter(item => item.id !== id));

        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'The knowledge base item has been deleted.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error('Error deleting item:', error);

        // Show error message
        Swal.fire({
          title: 'Error',
          text: 'There was an error deleting the item. Please try again.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
  };

  const handleAddCategory = async () => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authorization token not found. Please log in.",
        toast: true,
        position: "top-right",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    const url = "https://api.myntask.com/knowledge_base/category_add";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCategoryName }),
      });

      if (response.ok) {
        const data = await response.json();
        setCategories((prevCategories) => [...prevCategories, data]); // Update categories list

        // Show a toast notification for success
        Swal.fire({
          icon: "success",
          title: "Category added successfully!",
          toast: true,
          position: "top-right",
          timer: 3000,
          showConfirmButton: false,
        });
        fetchCategories();
        setShowAddCategoryModal(false); // Close the Add Category modal
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: errorData.message || "Failed to add category",
          toast: true,
          position: "top-right",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error adding category:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred while adding the category.",
        toast: true,
        position: "top-right",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Knowledge Base
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={toggleModal}>
            Add Knowledge Base
          </button>
        </div>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Box sx={{ display: 'flex' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: '200px' }}
        >
          {categories.map((category, index) => (
            <Tab key={category.id} label={category.name} />
          ))}
        </Tabs>
        <div>
          {details && details.length > 0 ? (

            <table>
              <thead>
                <tr>
                  <th>Heading</th>
                  <th>Content</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {details.map((item, idx) => (
                  <tr key={item.id || idx} >
                    <td
                      onClick={() => handleViewClick(item.id)}
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#347cef',
                      }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = '#e1f5fe'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = '#f9f9f9'}
                    >
                      {item.heading || 'No Heading Available'}
                    </td>
                    <td >{item.to || 'No Content Available'}</td>
                    <td >
                      <button
                        className="color-button"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          ) : (
            <p>No data available</p>
          )}
        </div>

      </Box>


      {/* Modal for adding a new knowledge base */}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box className='designation-modal'>
          <div className='designation-header'>
            <h1 className="designation-title" >
              Add knowledge base
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={toggleModal}
            />
          </div>
          {/* Form Fields */}
          <div className='designation-form'>
            <label className='designation-label'>Category:</label>
            <div style={{ display: 'flex', gap: '10px' }}>
              <select
                id="categoryId"
                value={categoryId}
                onChange={(e) => setCategoryId(parseInt(e.target.value))}
                className='designation-input'
              >
                <option value="" disabled>
                  Select a Category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <button
                className="color-button" style={{ marginBottom: '15px' }}
                onClick={() => setShowAddCategoryModal(true)} // Open Add Category Modal
              >
                Add
              </button>
            </div>

            <label className='designation-label'>Heading:</label>
            <input
              type="text"
              id="heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Enter knowledge base heading"
              className='designation-input'
            />



            <label className='designation-label'>Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter knowledge base description"
              className='designation-input'
            />
          </div>

          <button
            className='designation-save'
            onClick={handleSubmit}

          >
            Add Knowledge Base
          </button>

        </Box>
      </Modal>

      {/* Add Category Modal */}
      <Modal
        open={showAddCategoryModal}
        onClose={() => setShowAddCategoryModal(false)}
      >
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            margin: "auto",
            mt: "15%",
            maxWidth: "350px",
            borderRadius: '16px',
          }}
        >
          {/* Close Button Replaced with Image */}
          <div className='designation-header'>
            <h1 className="designation-title" >
              Add New Category
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={() => setShowAddCategoryModal(false)}
            />
          </div>

          {/* New Category Name Input */}
          <div className='designation-form'>
            <label className='designation-label'>Category Name:</label>
            <input
              type="text"
              id="categoryName"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              className='designation-input'
            />
          </div>

          {/* Submit and Cancel Buttons */}

          <button className='designation-save' onClick={handleAddCategory}>
            Save
          </button>

        </Box>
      </Modal>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            p: 3,
            backgroundColor: 'white',
            margin: 'auto',
            marginTop: '5%',
            width: '50%',
            maxHeight: '80vh', // Set max height for the modal
            overflowY: 'auto'  // Enable scrolling within the modal
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalData?.heading || 'No Title Available'}
          </Typography>
          <Box
            id="modal-description"
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{ __html: modalData?.description || 'No Content Available' }}
          ></Box>
          <Button variant="contained" onClick={handleCloseModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}


export default KnowledgeBase;
