import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultimage from '../assets/asset.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const Asset = () => {
  const [assets, setAssets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    fetch('https://api.myntask.com/assets/list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setAssets(data);
        } else {
          console.error('Expected array but received:', data);
          setAssets([]); // Set as empty array to avoid issues
        }
        setIsLoading(false);
      })
      .catch(error => console.error('Error fetching assets:', error));
  }, []);


  const handleAssetClick = (id) => {
    navigate(`/assetDetail/${id}`);
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredAssets = assets.filter(asset => {
    const query = searchQuery.toLowerCase();
    return (
      asset.asset_name.toLowerCase().includes(query) ||
      asset.assets_status.toLowerCase().includes(query) ||
      (asset.lent_to && asset.lent_to.user_name.toLowerCase().includes(query)) ||
      (asset.date && asset.date.toLowerCase().includes(query))
    );
  });



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Assets
          <div className="underline"></div>
        </h1>
      </div>
      <div className="top-controls">
        <div className="filters-and-search">
          <input
            type="text"
            placeholder="Search assets..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="searchbar"
          />
        </div>
      </div>


      <table>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Status</th>
            <th>Lent To</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.length > 0 ? (
            filteredAssets.map((asset) => (
              <tr key={asset.assets_id}>
                <td>
                  <div className="info-container">
                    <img
                      src={asset.image || defaultimage}
                      alt={asset.asset_name}
                      className="image"
                    />
                    <div
                      className="info-text"
                      onClick={() => handleAssetClick(asset.assets_id)}
                      style={{
                        cursor: 'pointer',
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                    >
                      {asset.asset_name}
                    </div>
                  </div>
                </td>
                <td >{asset.assets_status}</td>
                <td>
                  {asset.assets_status === 'lent' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={asset.lent_to.user_image || defaultimage}
                        alt={asset.lent_to.user_name}
                        className="image"
                      />
                      <div>
                        <p className="user-name">{asset.lent_to.user_name}</p>
                        <p className="user-designation">{asset.lent_to.designation}</p>
                      </div>
                    </div>
                  ) : <p >None</p>}
                </td>
                <td s>{asset.date || 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No assets available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};


export default Asset;






