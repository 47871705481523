import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Box, Stack } from "@mui/material";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { Audio, Vortex } from 'react-loader-spinner';


const CRMDashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchDashboardData = async () => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch("https://api.myntask.com/dashboard/crm_dashboard", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch dashboard data");
        }
        const data = await response.json();


        console.log("API Data Received++++++++++++++++++crm+++++++++:", data); // Verify API response
        setDashboardData(data);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError("Failed to load dashboard data. Please try again later.");
        setIsLoading(false);
      }
    };


    fetchDashboardData();
  }, []);

  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }


  const handleCardClick = (route) => {
    navigate(route);
  };


  const leadSourceLabels = dashboardData?.lead_source_counts?.map((item) => item.source_name) || [];
  const leadSourceValues = dashboardData?.lead_source_counts?.map((item) => item.total) || [];
  const doughnutChartData = {
    labels: leadSourceLabels,
    datasets: [
      {
        data: leadSourceValues,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#9C27B0", "#FF5733"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#9C27B0", "#FF5733"],
      },
    ],
  };


  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Monthly Sales",
        data: dashboardData?.monthly_sales || [0, 0, 0, 0, 0, 0],
        fill: false,
        borderColor: "#42A5F5",
        tension: 0.1,
      },
    ],
  };


  const barChartData = {
    labels: ["Lead", "Deal", "Client", "Client Visit"],
    datasets: [
      {
        label: "Totals",
        data: [
          dashboardData?.total_leads?.total_all || 0,
          dashboardData?.total_deals?.total_all || 0,
          dashboardData?.total_clients?.total_all || 0,
          dashboardData?.total_client_visit || 0,
        ],
        backgroundColor: ["#42A5F5", "#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };



  return (
    // <Box sx={{ padding: "20px" }}>

    <div className="main-container">
      <h1 style={{ marginBottom: "20px" }}>CRM Dashboard</h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "20px",
          padding: "20px",
        }}
      >
        {[
          {
            label: "Lead",
            value: dashboardData?.total_leads?.total_all || 0,
            weeklyCount: dashboardData?.total_leads?.total_weekly || 0,
            route: "/lead-contact",
          },
          {
            label: "Deal",
            value: dashboardData?.total_deals?.total_all || 0,
            weeklyCount: dashboardData?.total_deals?.total_weekly || 0,
            route: "/deals",
          },
          {
            label: "Client",
            value: dashboardData?.total_clients?.total_all || 0,
            weeklyCount: dashboardData?.total_clients?.total_weekly || 0,
            route: "/clients",
          },
          {
            label: "Client Visit",
            value: dashboardData?.total_client_visit || 0,
            weeklyCount: dashboardData?.weekly_client_visits || 0,
            route: "/Client-Visit",
          },
        ].map((card, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(card.route)}
            style={{
              padding: "20px",
              border: "1px solid #e0e0e0",
              borderRadius: "12px",
              cursor: "pointer",
              backgroundColor: "#fff",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.boxShadow = "0 6px 10px rgba(0, 0, 0, 0.15)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
            }
          >
            <p style={{ fontSize: "18px", fontWeight: "500", marginBottom: "10px" }}>
              {card.label}
            </p>
            <p style={{ fontSize: "24px", fontWeight: "600", marginBottom: "10px" }}>
              {card.value}
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color:
                  card.weeklyCount > 0
                    ? "green"
                    : card.weeklyCount < 0
                      ? "red"
                      : "gray",
              }}
            >
              {card.weeklyCount > 0
                ? `+${card.weeklyCount} This week`
                : `${card.weeklyCount} This week`}
            </p>
          </div>
        ))}
      </div>


      <div style={{ marginTop: "20px", display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {/* Pie Chart (Doughnut Chart) */}
        <div
          style={{
            flex: "1 1 calc(50% - 20px)",
            padding: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3>Lead Source Distribution</h3>
          <div style={{ height: "300px" }}> <Doughnut data={doughnutChartData} /> </div>
        </div>

        {/* Line Chart (Monthly Sales) */}
        <div
          style={{
            flex: "1 1 calc(50% - 20px)",
            padding: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3>Monthly Sales (Line Chart)</h3>
          <div style={{ height: "300px" }}> <Line data={lineChartData} /></div>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h3>Overview (Bar Chart)</h3>
        <div><Bar data={barChartData} /></div>
      </div>

      {/* <Typography variant="h4" gutterBottom>
        CRM Dashboard
      </Typography>
      <Grid container spacing={2}>
        
        {[{
            label: "Lead",
            value: dashboardData?.total_leads?.total_all || 0,
            change: "+8.5%",
            route: "/lead-contact",
          },
          {
            label: "Deal",
            value: dashboardData?.total_deals?.total_all || 0,
            change: "-0.10%",
            route: "/deals",
          },
          {
            label: "Client",
            value: dashboardData?.total_clients?.total_all || 0,
            change: "+1.15%",
            route: "/clients",
          },
          {
            label: "Client Visit",
            value: dashboardData?.total_client_visit || 0,
            change: "-0.5%",
            route: "/Client-Visit",
          },
        ].map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card onClick={() => handleCardClick(card.route)} sx={{ cursor: "pointer" }}>
              <CardContent>
                <Typography variant="h6">{card.label}</Typography>
                <Typography variant="h4">{card.value}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {card.change} Since last week
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>


     
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
      
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Lead Source Distribution</Typography>
              <Box sx={{ height: "300px" }}>  
                <Doughnut data={doughnutChartData} />
              </Box>
            </CardContent>
          </Card>
        </Grid>


        
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Monthly Sales (Line Chart)</Typography>
              <Box sx={{ height: "300px" }}> 
                <Line data={lineChartData} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>


      <Card>
        <CardContent>
          <Typography variant="h6">Overview (Bar Chart)</Typography>
          <Bar data={barChartData} />
        </CardContent>
      </Card>
    </Box> */}

    </div>



  );
};


export default CRMDashboard;























