import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import blueImage from '../assets/blue.png';




const AddEmployee = () => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [role, setRole] = useState([]);
  const [member, setMembers] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    mobile: "",
    gender: "",
    status: "active",
    login: "enable",
    receive_email_notifications: 1,
    country_id: 99,


    date_of_birth: "",
    designation_id: '',
    department_id: '',
    joining_date: "",
    reporting_to: "",
    language: "",
    role_id: 1,
    address: "",
    about: "",
    probation_end_date: "",
    notice_period_start_date: "",
    notice_period_end_date: "",
    employment_type: "",
    marital_status: "",
    image: null, // Add an image field to the state
  });
  const [isModalOpen, setModalOpen] = useState(false);


  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);


  const navigate = useNavigate();


  useEffect(() => {
    // Fetch country list from the API
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data); // Assuming the API returns an array of countries








        // Assuming each country object has a `phonecode` property
        const codes = data.map(country => ({ id: country.id, code: country.phonecode, name: country.name }));
        setCountryCodes(codes);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };








    const fetchDesignations = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/employee/designation", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch designations");
        }
        const data = await response.json();
        setDesignations(data); // Set the designations list
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };








    const fetchDepartments = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/employee/department", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch departments");
        }
        const data = await response.json();
        setDepartments(data); // Set the departments list
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };








    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/employee/roles", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch roles");
        }
        const data = await response.json();
        setRole(data); // Set the roles list
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };








    const fetchMembers = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/employee/member", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch members");
        }
        const data = await response.json();
        setMembers(data); // Set the roles list
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };








    fetchDesignations();
    fetchRoles();
    fetchDepartments();
    fetchCountries();
    fetchMembers();
  }, []);




  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setFormData((prevState) => ({ ...prevState, image: file }));
    }
  };




  const handleCancel = () => {
    navigate('/employee');
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };






  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage


      const formDataToSend = new FormData();


      // Append each form field to FormData object
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });


      console.log("Form data being sent:", Array.from(formDataToSend.entries())); // Log FormData content


      const response = await fetch("https://api.myntask.com/employee/save", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header
        },
        body: formDataToSend, // Send FormData object
      });


      // If the response is successful
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Employee saved successfully!',
        });
        console.log('response is',response);
        console.log("Employee saved successfully.");
        navigate('/employee');
      } else {
        const errorResponse = await response.json(); // Get the error response
        console.error("Error response:", errorResponse);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorResponse.message || "Unknown error occurred.",
        });
      }
    } catch (error) {
      // Handle any errors during the request
      console.error("Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error saving employee: ${error.message}`,
      });
    }
  };






  return (
    <div className="main-container">
<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
  <div className="top-controls">
    <h1 className='title'>Add Team Member
      <div className="underline"></div>
    </h1>
  </div>
  <div style={{ position: 'relative', display: 'inline-block' }}>
        {/* Information Text */}
        <span
          className="help-text"
          style={{
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          Information
          {/* Tooltip */}
          <div
            className="tooltip"
            style={{
              visibility: 'hidden',
              position: 'absolute',
              backgroundColor: '#333',
              color: 'black',
              textAlign: 'center',
              borderRadius: '4px',
              padding: '5px 10px',
              zIndex: 1,
              top: '120%',
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
            }}
          >
            Click on the icon
          </div>
        </span>
        {/* Tooltip Visibility Trigger */}
        <style>
          {`
            .help-text:hover .tooltip {
              visibility: visible;
            }
          `}
        </style>


        {/* Icon */}
        <img
          src={blueImage}
          alt="Blue"
          className="team-image"
          onClick={openModal}
          style={{
            cursor: 'pointer',
            marginLeft: '8px',
          }}
        />
      </div>




</div>


   {/* Modal */}
   {isModalOpen && (
  <div className="modal-overlay" style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  }}>
    <div className="modal-content" style={{
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'left',
      position: 'relative',
      width: '80%',
      maxHeight: '80%',
      overflowY: 'auto',
    }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Team Member Details</h2>
     
      {/* Personal Details Section */}
      <h3>Personal Details</h3>
      <ul style={{ paddingLeft: '20px' }}>
        <li>Email: The team member's valid email address.</li>
        <li>Name: Full name of the team member.</li>
        <li>Mobile: Contact number for communication.</li>
        <li>Gender: Gender of the team member.</li>
        <li>Date of Birth: Date of birth in `dd-mm-yyyy` format.</li>
        <li>Country: Country of residence (e.g., India).</li>
        <li>Address: Residential address of the team member.</li>
        <li>Marital Status: Marital status of the team member.</li>
        <li>Profile Image: Upload a profile picture of the team member.</li>
      </ul>


      {/* Employment Info Section */}
      <h3>Employment Info</h3>
      <ul style={{ paddingLeft: '20px' }}>
        <li>Status: Employment status (e.g., Active, Inactive).</li>
        <li>Login: Enable or disable login access.</li>
        <li>Email Notification: Whether email notifications are enabled.</li>
        <li>Designation: Job title or position of the team member.</li>
        <li>Department: Department the team member belongs to.</li>
        <li>Employment Type: Type of employment (e.g., Full-time, Part-time).</li>
        <li>Roles: Roles assigned to the team member.</li>
      </ul>


      {/* Important Dates Section */}
      <h3>Important Dates</h3>
      <ul style={{ paddingLeft: '20px' }}>
        <li>Probation End Date: End date of the probation period.</li>
        <li>Joining Date: Date when the team member joined.</li>
        <li>Notice Period Start Date: Start date of the notice period.</li>
        <li>Notice Period End Date: End date of the notice period.</li>
      </ul>


      {/* Additional Details Section */}
      <h3>Additional Details</h3>
      <ul style={{ paddingLeft: '20px' }}>
        <li>About: Additional information about the team member (e.g., skills, achievements).</li>
        <li>Reporting To: Name of the person or role the team member reports to.</li>
      </ul>


      <button onClick={closeModal} style={{
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>Close</button>
    </div>
  </div>
)}










      <section className="section">
        <h3 className="sectionTitle">Personal Details</h3>
        <div className="formRow">
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">
                Email: <span className="required">*</span>
              </label>
              <input
                className="input"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="formGroup">
              <label className="label">
                Name: <span className="required">*</span>
              </label>
              <input
                className="input"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>




            <div className="formGroup">
              <label className="label">
                Mobile: <span className="required">*</span>
              </label>
              <input
                className="input"
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Enter your mobile number"
                required
              />
            </div>
            <div className="formGroup">
              <label className="label">Gender:</label>
              <select
                className="input"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="">Select your gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Date of Birth:</label>
              <input
                className="input"
                type="date"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleChange}


              />
            </div>
            <div className="formGroup">
              <label className="label">Country:</label>
              <select
                className="input select-box"  // Add 'select-box' class to control dropdown width
                name="country_id"
                value={formData.country_id}
                onChange={handleChange}


              >
                <option value="">Select your country</option>
                {countryCodes.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="formGroup">
              <label className="label">Address:</label>
              <input
                className="input"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Enter your address"


              />
            </div>
            <div className="formGroup">
              <label className="label">Marital Status:</label>
              <select
                className="input"
                name="marital_status"
                value={formData.marital_status}
                onChange={handleChange}
              >
                <option value="">Select your marital status</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
              </select>
            </div>
          </div>
        </div>




        <div className="formGroup">
          <label className="label">Profile Image:</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            className="input"
            onChange={handleImageChange} // Handle image file separately
          // Remove the required attribute to make it optional
          />
        </div>




      </section>








      <section className="section">
        <h3 className="sectionTitle">Employment Info</h3>
        <div className="formRow">
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Status:</label>
              <select className="input" name="status" value={formData.status} onChange={handleChange}>
                <option value="">Select a status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="formGroup">
              <label className="label">Login:</label>
              <select className="input" name="login" value={formData.login} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
              </select>
            </div>
            <div className="formGroup">
              <label className="label">Receive Email Notification:</label>
              <select className="input" name="receive_email_notifications" value={formData.receive_email_notifications} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="0">Enable</option>
                <option value="1">Disable</option>
              </select>
            </div>
            <div className="formGroup">
              <label className="label">
                Designation: <span className="required">*</span>
              </label>
              <select className="input" name="designation_id" value={formData.designation_id} onChange={handleChange} required>
                <option value="">Select a designation</option>
                {designations.map((designation) => (
                  <option key={designation.id} value={designation.id}>
                    {designation.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">
                Department: <span className="required">*</span>
              </label>
              <select className="input" name="department_id" value={formData.department_id} onChange={handleChange} required>
                <option value="">Select a department</option>
                {departments.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.department}
                  </option>
                ))}
              </select>
            </div>
            <div className="formGroup">
              <label className="label">Employment Type:</label>
              <select className="input" name="employment_type" value={formData.employment_type} onChange={handleChange}>
                <option value="">Select employment type</option>
                <option value="full-time">Full-Time</option>
                <option value="part-time">Part-Time</option>
                <option value="contract">Contract</option>
              </select>
            </div>
            <div className="formGroup">
              <label className="label">Roles:<span className="required">*</span></label>
              <select className="input" name="role_id" value={formData.role_id} onChange={handleChange} required>
                <option value="">Select a role</option>
                {role.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.role_name}
                  </option>
                ))}
              </select>
            </div>


          </div>
        </div>
      </section>






      <section className="section">
        <h3 className="sectionTitle">Important Dates</h3>
        <div className="formRow">
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Probation End Date:</label>
              <input className="input" type="date" name="probation_end_date" value={formData.probation_end_date} onChange={handleChange} required />
            </div>
            <div className="formGroup">
              <label className="label">
                Joining Date: <span className="required">*</span>
              </label>
              <input className="input" type="date" name="joining_date" value={formData.joining_date} onChange={handleChange} required />
            </div>
          </div>
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Notice Period Start Date:</label>
              <input className="input" type="date" name="notice_period_start_date" value={formData.notice_period_start_date} onChange={handleChange} required />
            </div>
            <div className="formGroup">
              <label className="label">Notice Period End Date:</label>
              <input className="input" type="date" name="notice_period_end_date" value={formData.notice_period_end_date} onChange={handleChange} required />
            </div>
          </div>
        </div>
      </section>




      <section className="section">
        <h3 className="sectionTitle">Additional Detail</h3>
        <div className="formRow">
          <div className="formColumn">


            <div className="formGroup">
              <label className="label">About:</label>
              <textarea
                className="input"
                name="about"
                value={formData.about}
                onChange={handleChange}
                placeholder="Tell us about yourself"
              />
            </div>
          </div>
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Reporting to:</label>
              <select
                className="input"
                name="reporting_to"
                value={formData.reporting_to}
                onChange={handleChange}


              >
                <option value="">Reporting to</option>
                {member.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.member_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </section>








      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <button onClick={handleSubmit} className="color-button">Save Employee</button>
        <button onClick={handleCancel} className="color-button">Cancel</button>
      </div>


    </div>
  );
};








export default AddEmployee;






























