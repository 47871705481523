import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal'; // Modal component from Material UI
import EditPayroll from '../Components/EditPayroll';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const Payroll = () => {
  const [payrollData, setPayrollData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [useAttendance, setUseAttendance] = useState(false);
  const [includeExpenses, setIncludeExpenses] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [payrollLoading, setPayrollLoading] = useState(true);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const actionMenuRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [payrollDetails, setPayrollDetails] = useState(null); // State to store fetched payroll slip details
  const [loadingDetails, setLoadingDetails] = useState(false); // State to track loading for the modal
  const [editModalOpen, setEditModalOpen] = useState(false); // State for edit modal
  const [selectedSlipId, setSelectedSlipId] = useState(null); // State to hold the selected slip id for editing


  // Function to toggle the action menu visibility for a specific row
  const toggleActionMenu = (payrollId) => {
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [payrollId]: !prevState[payrollId],
    }));
  };




  // Function to handle action clicks (edit, delete, view)
  const handleActionMenuClick = (action, payrollId) => {
    console.log(`Action: ${action}, Payroll ID: ${payrollId}`); // Log the action and payroll ID
    if (action === 'view') {
      fetchPayrollDetails(payrollId); // Fetch payroll details and open modal
    } else if (action === 'edit') {
      setSelectedSlipId(payrollId); // Set the selected slip ID
      setEditModalOpen(true); // Open the edit modal
    } else if (action === 'delete') {
      deletePayrollSlip(payrollId); // Call delete function with payrollId
    }
  };




  // Close the dropdown if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setActionMenuVisible({});
      }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuRef]);


  // Fetch the employees (assuming there's an API for this)
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('Authorization token not found.');
      return;
    }


    // You can add the correct API for fetching employees here
    // Example URL below (replace with actual API if available)
    fetch('https://api.myntask.com/employee/member', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEmployees(data); // Assuming the API returns a list of employees
      })
      .catch((error) => {
        setError('Failed to fetch employees');
      });
  }, []);


  const token = localStorage.getItem('authToken'); // Get the token once


  // Function to fetch payroll list
  const fetchPayrollList = () => {
    setIsLoading(true);
    setError(null);


    if (!token) {
      setError('Authorization token not found.');
      setIsLoading(false);
      return;
    }


    fetch('https://api.myntask.com/payroll/payroll_list', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch payroll data');
        }
        return response.json();
      })
      .then((data) => {
        setPayrollData(data); // Store the payroll data
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  };


  // Fetch payroll list when the component is mounted
  useEffect(() => {
    fetchPayrollList(); // Fetch the payroll list on component mount
  }, []);


  // Handle the payroll generation form submission


  const handleSubmit = (e) => {
    e.preventDefault();


    if (!token) {
      setError('Authorization token not found.');
      return;
    }


    if (!selectedEmployee) {
      setError('Please select an employee.');
      return;
    }


    setIsLoading(true);
    setError(null);


    const requestData = {
      user_id: selectedEmployee, // Dynamic employee ID
      use_attendance: useAttendance ? 1 : 0,
      include_expenses: includeExpenses ? 1 : 0,
    };


    fetch('https://api.myntask.com/payroll/generate_payroll', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to generate payroll');
        }
        return response.json();
      })
      .then(() => {
        setIsLoading(false);
        fetchPayrollList(); // Re-fetch the payroll list after successful generation


        // Show SweetAlert success notification with auto-close
        Swal.fire({
          title: 'Success!',
          text: 'Payroll generated successfully!',
          icon: 'success',
          timer: 2000, // Closes after 2 seconds
          showConfirmButton: false, // Removes the "OK" button
        });


        // Clear the form fields
        setSelectedEmployee('');
        setUseAttendance(false);
        setIncludeExpenses(false);
      })
      .catch((error) => {
        setIsLoading(false);


        // Show SweetAlert error notification
        Swal.fire({
          title: 'Error!',
          text: error.message || 'Failed to generate payroll',
          icon: 'error',
          timer: 2000, // Closes after 2 seconds
          showConfirmButton: false, // Removes the "OK" button
        });
      });
  };






  const deletePayrollSlip = async (payrollId) => {
    const token = localStorage.getItem('authToken');
    const url = `https://api.myntask.com/payroll/slip_delete/${payrollId}`;


    // Show confirmation dialog
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });


    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: 'Payroll deleted successfully!',
            icon: 'success',
            timer: 2000, // Closes after 2 seconds
            showConfirmButton: false, // Removes the "OK" button
          });
          fetchPayrollList(); // Refresh payroll list after successful deletion
        } else {
          throw new Error('Failed to delete payroll slip');
        }
      } catch (error) {
        console.error('Error deleting payroll slip:', error);
        Swal.fire({
          title: 'Error!',
          text: error.message || 'Failed to delete payroll',
          icon: 'error',
          timer: 2000, // Closes after 2 seconds
          showConfirmButton: false, // Removes the "OK" button
        });
      }
    }
  };




  // Function to fetch payroll slip details from API
  const fetchPayrollDetails = (payrollId) => {
    setLoadingDetails(true);
    setError(null);
    const token = localStorage.getItem('authToken');


    fetch(`https://api.myntask.com/payroll/slip_view/${payrollId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch payroll details');
        }
        return response.json();
      })
      .then((data) => {
        setPayrollDetails(data[0]); // Store the fetched data (assuming the data is an array)
        console.log(data);
        setLoadingDetails(false);
        setModalOpen(true); // Open the modal after successful data fetch
      })
      .catch((error) => {
        setError(error.message);
        setLoadingDetails(false);
      });
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className='main-container'>
      <div>
        <div className="top-controls">
          <h1 className='title'>Generate Payroll
            <div className="underline"></div>
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          {/* Checkboxes Row */}
          <div className="form-row">
            <div className="formGroup">
              <label>
                <input
                  type="checkbox"
                  checked={useAttendance}
                  onChange={(e) => setUseAttendance(e.target.checked)}
                />
                Use Attendance
              </label>
            </div>


            <div className="formGroup">
              <label>
                <input
                  type="checkbox"
                  checked={includeExpenses}
                  onChange={(e) => setIncludeExpenses(e.target.checked)}
                />
                Include Expenses
              </label>
            </div>
          </div>


          {/* Select Employee Row */}
          <div className="form-row">
            <div className="formGroup">
              <label className='label'>
                Select Employee:</label>
              <select
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                required
                className='input'
              >
                <option value="">Select an employee</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.member_name}
                  </option>
                ))}
              </select>


            </div>
          </div>


          {/* Submit Button */}
          <div className="form-row">
            <button onClick={handleSubmit} className='color-button' >
              Generate Payroll
            </button>
          </div>
        </form>
      </div>
      <div className="top-controls">
        <h1 className='title'>Payroll
          <div className="underline"></div>
        </h1>
      </div>
      <table>
        <thead>
          <tr>


            <th>Employee Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Net Salary</th>
            <th>Salary From</th>
            <th>Salary To</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {payrollData.map((payroll) => (
            <tr key={payroll.slip_id}>


              <td>{payroll.user_name}</td>
              <td>{payroll.designation}</td>
              <td>{payroll.department}</td>
              <td>{payroll.net_salary}</td>
              <td >{new Date(payroll.salary_from).toLocaleDateString()}</td>
              <td >{new Date(payroll.salary_to).toLocaleDateString()}</td>
              <td >{payroll.status}</td>
              <td>
                {/* Action button container */}
                <div className="action-container" style={{ position: 'relative' }}>
                  <div className="action-dots" onClick={() => toggleActionMenu(payroll.slip_id)}>
                    <GridMoreVertIcon />
                  </div>
                  {/* Dropdown menu */}
                  {actionMenuVisible[payroll.slip_id] && (
                    <div className="action-menu" ref={actionMenuRef} >
                      <div className="action-item" onClick={() => handleActionMenuClick('edit', payroll.slip_id)}>
                        Edit
                      </div>
                      <div className="action-item" onClick={() => handleActionMenuClick('delete', payroll.slip_id)}>
                        Delete
                      </div>
                      <div className="action-item" onClick={() => handleActionMenuClick('view', payroll.slip_id)}>
                        View
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {/* Modal for payroll details */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div style={styles.modalContent}>
          {loadingDetails ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : payrollDetails ? (
            <div style={styles.payrollContainer}>
              <h3>Salary Slip</h3>
              <p>Payslip For The Duration {new Date(payrollDetails.salary_from).toLocaleDateString()} to {new Date(payrollDetails.salary_to).toLocaleDateString()} (Monthly)</p>


              <div style={styles.detailsContainer}>
                <div style={styles.employeeInfo}>
                  <img src={payrollDetails.image} alt="Employee Avatar" style={styles.avatar} />
                  <p><strong>{payrollDetails.user_name}</strong></p>
                  <p>{payrollDetails.designation}</p>
                  <p>Department: {payrollDetails.department}HR</p> {/* Add actual department info if available */}
                  <p>Salary Payment Method: --</p> {/* Add actual payment method if available */}
                </div>


                <div style={styles.additionalInfo}>
                  <p><strong>Employee ID:</strong> {payrollDetails.employee_id}</p>
                  <p><strong>Joining Date:</strong> {new Date(payrollDetails.joining_date).toLocaleDateString()}</p>
                  <p><strong>Paid On:</strong> {payrollDetails.paid_on ? new Date(payrollDetails.paid_on).toLocaleDateString() : '--'}</p>
                  <p><strong>Status:</strong> {payrollDetails.status === "generated" ? "Generated" : "Not Generated"}</p>
                  <div style={styles.netPay}>
                    <p><strong>Employee Net Pay</strong></p>
                    <p style={{ color: '#007bff', fontSize: '1.5em' }}>₹{payrollDetails.net_salary}.00</p>
                  </div>
                </div>
              </div>


              <div style={styles.salaryDetails}>
                <div style={styles.section}>
                  <h4 style={styles.sectionTitle}>Earnings</h4>
                  <div style={styles.earnings}>
                    <p>Basic Salary</p>
                    <p>₹0.00</p>
                  </div>
                  <div style={styles.earnings}>
                    <p>Special Allowance</p>
                    <p>₹{payrollDetails.gross_salary}.00</p>
                  </div>
                  <p style={styles.totalEarnings}>Gross Earnings: ₹{payrollDetails.gross_salary}.00</p>
                </div>


                <div style={styles.section}>
                  <h4 style={styles.sectionTitle}>Deductions</h4>
                  <p>Total Deductions</p>
                  <p>₹0.00</p>
                </div>


                <div style={styles.section}>
                  <h4 style={styles.sectionTitle}>Reimbursements</h4>
                  <div style={styles.reimbursements}>
                    <p>Expense Claims</p>
                    <p>₹0.00</p>
                  </div>
                  <p>Total Reimbursements: ₹0.00</p>
                </div>
              </div>




              <h3>NET SALARY: ₹{payrollDetails.net_salary}.00</h3>
            </div>
          ) : (
            <p>No details available.</p>
          )}
        </div>
      </Modal>




      {/* Render the EditModal and pass the necessary props */}
      {selectedSlipId && (
        <EditPayroll
          slipId={selectedSlipId} // Pass the selected slip ID
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)} // Close the modal
        />
      )}
    </div>
  );
};


export default Payroll;




const styles = {
  modalContent: {
    padding: '20px',
    backgroundColor: '#e9edf2',
    borderRadius: '8px',
    maxWidth: '700px',
    margin: '40px auto',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    border: '1px solid #e0e0e0',
  },
  payrollContainer: {
    textAlign: 'center',
    paddingBottom: '20px',
  },
  detailsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: '20px',
    marginTop: '20px',
    alignItems: 'start',
  },
  employeeInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#f7f7f7',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  avatar: {
    width: '80px',
    height: '70px',
    borderRadius: '50%',
    marginBottom: '10px',
  },
  additionalInfo: {
    backgroundColor: '#fdfdfd',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  netPay: {
    marginTop: '20px',
    textAlign: 'center',
    border: '1px solid #007bff',
    padding: '15px',
    borderRadius: '4px',
    backgroundColor: '#e7f1ff',
    color: '#007bff',
    fontSize: '1.25em',
  },
  salaryDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    gap: '20px',
  },
  section: {
    flex: '1',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    padding: '15px',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1.1em',
    marginBottom: '10px',
    borderBottom: '1px solid #d0d0d0',
    paddingBottom: '5px',
  },
  earnings: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: '1px dashed #e0e0e0',
  },
  totalEarnings: {
    fontWeight: 'bold',
    marginTop: '10px',
    textAlign: 'center',
  },
  reimbursements: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: '1px dashed #e0e0e0',
  },
  footer: {
    marginTop: '30px',
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
};






