import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import noDataImage from '../assets/NoDataFound.png'; // Adjust path based on your folder structure
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../Components/Main.css';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { Box, Modal, Typography } from '@mui/material';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import { Vortex } from 'react-loader-spinner';
import './css/Designation.css';


function Product() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedFile, setSelectedFile] = useState(null);
  const productsPerPage = 20; // Show 20 products per page
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();


  const fetchProducts = async () => {
    setLoading(true);
    const token = localStorage.getItem('authToken');


    if (!token) {
      setError('No token found. Please log in again.');
      setLoading(false);
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/product/list', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error(`Network response was not ok, status: ${response.status}`);
      }


      const data = await response.json();
      setProducts(data); // Set the products state
      setIsLoading(false);
    } catch (error) {
      setError('Failed to fetch products.');
      setIsLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);


  // Filter products by search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);


  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };




  // Function to handle navigation to AddProduct screen
  const handleAddProduct = () => {
    navigate('/add-product');
  };




  const handleDeleteClick = async (productId) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from local storage

    // Display confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this product?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.myntask.com/product/delete/${productId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass token in the header
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete the product');
        }

        // Refetch the products list after successful deletion
        await fetchProducts();

        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'Product deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error('Error deleting product:', error);
        // Show error message
        Swal.fire({
          title: 'Error!',
          text: 'Error deleting product. Please try again.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
  };



  const truncateName = (name) => {
    const words = name.split(' '); // Split the name into an array of words
    return words.length > 10 ? `${words.slice(0, 10).join(' ')}...` : name; // Join the first 10 words and add ellipsis
  };


  const toTitleCase = (str) => {
    if (!str) return 'N/A';
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };

  const handleEditClick = (productId) => {
    navigate(`/edit-product/${productId}`); // Use React Router for navigation
  };

  // Function to open the modal
  const openModal = () => setShowModal(true);

  // Function to close the modal
  const closeModal = () => setShowModal(false);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleImport = async () => {
    if (!selectedFile) {
      Swal.fire({
        icon: "warning",
        title: "No File Selected",
        text: "Please select a file to import!",
      });
      return;
    }

    const token = localStorage.getItem("authToken"); // Retrieve token from local storage
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authorization Error",
        text: "Authorization token not found. Please log in.",
      });
      return;
    }

    const url = "https://api.myntask.com/product/product_import";

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Use the token from local storage
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        Swal.fire({
          icon: "success",
          title: "Import Successful",
          text: "File imported successfully!",
        });
        console.log("Response:", data);
        closeModal(); // Close the modal after successful import
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Import Failed",
          text: errorData.message || "An error occurred during the import.",
        });
      }
    } catch (error) {
      console.error("Error during import:", error);
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "An error occurred during the import. Please try again.",
      });
    }
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Product
          <div className="underline"></div>
        </h1>


        <div className="buttons-row">
          <button className="color-button" onClick={handleAddProduct}>
            Add Product
          </button>
        </div>
      </div>


      <div className="top-controls">

        <div className="filters-and-search">
          <button className="border-button" onClick={openModal}>
            Import
          </button>
          <input
            type="text"
            className="searchbar"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>


      {currentProducts.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Unit Type</th>
                <th>Client Can Purchase</th>
                <th>Price with Taxes</th>
                <th>Brand</th> {/* New column for Brand */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.map((product) => (
                <tr key={product.id}>
                  <td>
                    <span className="tooltip" data-tooltip={product.name}>
                      {truncateName(toTitleCase(product.name) || 'N/A')}
                    </span>
                  </td>
                  <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <div
                      className="status-container"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: product.status.toLowerCase() === 'active' ? 'green' : 'red',
                          marginRight: '5px',
                        }}
                      ></span>
                      <span>{toTitleCase(product.status || 'N/A')}</span>
                    </div>
                  </td>
                  <td >{toTitleCase(product.unit_type || 'N/A')}</td>
                  <td >{product.client_can_purchase ? 'Yes' : 'No'}</td>
                  <td >{product.price_with_taxes || 'N/A'}</td>
                  <td >{toTitleCase(product.brand || 'N/A')}</td> {/* New Brand column */}
                  <td>
                    <div className="action-container">
                      <div className="action-dots" onClick={() => toggleActionMenu(product.id)}>
                        <GridMoreVertIcon />
                      </div>
                      {actionMenuVisible === product.id && (
                        <div className="action-menu">
                          <div className="action-item"
                            onClick={() => handleEditClick(product.id)}
                          >Edit </div>
                          <div className="action-item" onClick={() => handleDeleteClick(product.id)}>Delete</div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>




          {/* Pagination with MUI */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Stack spacing={2} >
              <Pagination
                count={totalPages} // Total number of pages
                page={currentPage} // Current page
                onChange={(event, page) => paginate(page)} // Handle page change
                color="primary"

              />
            </Stack>
          </div>
        </>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No data available</p>
        </div>

      )}


      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="file-upload-modal"
        aria-describedby="modal-for-uploading-files"
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Import File</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={closeModal}
            />
          </div>

          {/* Main Image */}
          <img
            src={designationImage} // Replace 'designationImage' with the path or import for the desired image
            alt="Designation"
            className="designation-image"
          />

          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label">
              Select File
            </label>
            <input
              type="file"
              id="import-file"
              onChange={handleFileChange}
              accept=".xlsx"
              className="designation-input"
            />
          </div>

          {/* Sample File Link */}
          <a
            href="/Files/product_import_template.xlsx" // Path relative to the public directory
            download="product_import_template.xlsx"
            className="designation-save"
          >
            Sample File
          </a>

          {/* Submit Button */}
          <button
            onClick={handleImport}
            className="designation-save"
          >
            Submit
          </button>
        </Box>
      </Modal>

    </div>
  );
}


export default Product;


