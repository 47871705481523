import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '@mui/material/Modal'; // Correct MUI import
// import './css/Event.css'; // Add your custom CSS
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import iconImage from '../assets/Icon.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';







function Event() {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [deleteEvents, setDeleteEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");



  const [newEvent, setNewEvent] = useState({
    event_name: '',
    label_color: null,
    where: '',
    description: '',
    start_date_time: '',
    end_date_time: '',
    status: 'pending',
    repeat: 'no',
    repeat_every: 1,
    repeat_cycles: null,
    repeat_type: 'day',
    send_reminder: 'no',
    remind_time: 1,
    remind_type: 'day',
    event_link: '',
  });




  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error("Auth token is missing or invalid");
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/event/list', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch events');
        }
        const data = await response.json();
        console.log('API Response:', data);
        const formattedEvents = data.map(event => {
          // Convert the start_date_time to a Date object
          const dateParts = event.start_date_time.split(' ');
          const month = dateParts[0];
          const day = parseInt(dateParts[1]); // removes "th", "st", etc.
          const year = parseInt(dateParts[2]);
          // Correct date construction
          const startDate = new Date(`${month} ${day}, ${year}`);
          console.log('Formatted Date:', startDate);



          return {
            id: event.id,
            title: event.event_name,
            start: startDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
            allDay: true,
          };
        });


        console.log('Formatted Events:', formattedEvents);
        setEvents(formattedEvents);
        setDeleteEvents(formattedEvents);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching events:', error);
        setIsLoading(false);

      }
    };


    fetchEvents();
  }, []);








  const handleDateClick = (info) => {
    alert(`Clicked on: ${info.dateStr}`);
  };





  const handleAddEventClick = () => {
    setShowModal(true);
  };




  const handleModalClose = () => {
    setShowModal(false);
    setNewEvent({
      event_name: '',
      label_color: '#000000',
      where: '',
      description: '',
      start_date_time: '',
      end_date_time: '',
      status: 'pending',
      repeat: 'no',
      repeat_every: 1,
      repeat_cycles: null,
      repeat_type: 'day',
      send_reminder: 'no',
      remind_time: 1,
      remind_type: 'day',
      event_link: '',


    });
  };






  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent(prevEvent => ({ ...prevEvent, [name]: value }));
  };





  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting event :", newEvent);
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error("Auth token is missing or invalid");
      return;
    }
    try {
      console.log('Submitting event:', newEvent);
      const response = await fetch('https://api.myntask.com/event/save', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEvent),
      });
      if (!response.ok) {
        const error = await response.json();
        console.error("Error response from server:", error);
        alert(`Failed to add the event: ${error.message || 'Unknown error'}`);
        return;
      }
      const savedEvent = await response.json();
      console.log('Event saved:', savedEvent);
      if (savedEvent.success) {
        const addedEvent = {
          id: savedEvent.id || new Date().getTime(), // Fallback if no ID is returned
          title: newEvent.event_name,
          start: newEvent.start_date_time.split('T')[0],
          allDay: true,
        };
        setEvents((prevEvents) => [...prevEvents, addedEvent]);
        handleModalClose();
      } else {
        console.error('Unexpected success response:', savedEvent);
        alert('Event saved, but unable to update calendar.');
      }
    } catch (error) {
      console.error('Error adding event:', error);
      alert("An error occurred while adding the event. Please try again.");
    }
  };





  const handleEventClick = (id) => {
    // First, close the modal before showing the SweetAlert
    setShowDeleteModal(false);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this event? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem('authToken');
          if (!token) {
            Swal.fire({
              title: 'Error',
              text: 'Authorization token is missing!',
              icon: 'error',
              timer: 1500,
              showConfirmButton: false,
            });
            return;
          }





          // Make the DELETE API call
          const response = await fetch(`https://api.myntask.com/event/delete/${id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });


          if (!response.ok) {
            throw new Error('Failed to delete event');
          }


          // Optionally remove the event from the events array in state
          setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));




          // Show success message after deletion
          Swal.fire({
            title: 'Deleted!',
            text: 'The event has been deleted successfully.',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: `Failed to delete event: ${error.message}`,
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
          });
        }
      }
    });
  };








  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error("Auth token is missing or invalid");
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/task/calendar_task', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }
        const data = await response.json();
        console.log('API Response:', data);



        // Format tasks for the calendar
        const formattedTasks = data.map(task => {
          // Determine the background color based on status
          let backgroundColor = '';
          if (task.status === 'Pending') {
            backgroundColor = 'blue'; // Pending tasks
          } else if (task.status === 'Completed') {
            backgroundColor = 'green'; // Completed tasks
          } else if (new Date(task.due_date) < new Date()) {
            backgroundColor = 'red'; // Overdue tasks
          }


          return {
            id: task.task_id,
            title: task.name,
            start: task.start_date,
            end: task.due_date, // Optional end date
            allDay: true, // Assuming tasks are all-day events
            backgroundColor, // Assign color dynamically
          };
        });




        console.log('Formatted Tasks:', formattedTasks);
        setEvents(formattedTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };


    fetchTasks();
  }, []);




  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }





  return (
    <>
      <div className="main-container" >
        <div className="top-controls">
          <h1 className='title'>Events
            <div className="underline"></div>
          </h1>
          <div className="buttons-row">
            <button className="color-button" onClick={handleAddEventClick}>+ Add Event</button>
            <div className="delete-holiday-container" style={{ position: 'relative' }} >
              <button className="color-button" onClick={() => setDropdownVisible(!dropdownVisible)}>
                Delete Event
              </button>
              {dropdownVisible && (
                <div className="delete-holiday-dropdown" style={{
                  position: 'absolute',
                  top: '100%',  // Position it below the button
                  left: 0,
                  zIndex: 10,
                  backgroundColor: 'white',
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px'
                }}>
                  {deleteEvents.length > 0 ? (
                    deleteEvents.map((event) => (
                      <div
                        key={event.id}
                        className="dropdown-item"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <span>{event.title} </span>
                        <div
                          onClick={() => {
                            handleEventClick(event.id);
                            setDropdownVisible(false);
                          }}
















                        >
                          <DeleteIcon style={{ color: 'red' }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">No events available</div>
                  )}
                </div>
              )}
            </div>
































          </div>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          dateClick={handleDateClick}
          dayCellDidMount={(info) => {
            const date = new Date(info.date);
            if (date.getDay() === 0) { // Sunday
              info.el.style.backgroundColor = '#e9f5f3'; // Change to your desired color
            }
          }}
          height="parent"
          contentHeight="auto"
          eventDisplay="block" // Ensure events are displayed with colors
        />




























      </div>
































































      {/* .......................................... */}
      <Modal className='modal-overlay-deal' open={showModal} onClose={handleModalClose}>




        <div className="modal-content-deal">
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />




          <h2>Add New Event</h2>
          <form onSubmit={handleFormSubmit}>




            <div className="form-grid-deal">
              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  Event Name:
                  <input
                    type="text"
                    name="event_name"
                    className='input-field-event'
                    value={newEvent.event_name}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              {/* <label className='event-label' >
                Label Color:
                <input
                  type="color"
                  name="label_color"
                  value={newEvent.label_color}
                  onChange={handleInputChange}
                  className='input-color'
                />
              </label> */}
              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  Location:
                  <input
                    type="text"
                    name="where"
                    value={newEvent.where}
                    className='input-field-event'
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  Start Date & Time:
                  <input
                    type="datetime-local"
                    name="start_date_time"
                    value={newEvent.start_date_time}
                    onChange={handleInputChange}
                    className='input-field-event'
                    required
                  />
                </label>
              </div>
              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  End Date & Time:
                  <input
                    type="datetime-local"
                    name="end_date_time"
                    value={newEvent.end_date_time}
                    onChange={handleInputChange}
                    className='input-field-event'
                    required
                  />
                </label>
              </div>
              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  Event Link:
                  <input
                    type="url"
                    name="event_link"
                    value={newEvent.event_link}
                    onChange={handleInputChange}
                    className='input-field-event'
                  />
                </label>
              </div>
              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  Status :&nbsp;
                  <select
                    name="status"
                    value={newEvent.status}
                    onChange={handleInputChange}
                    className='input-field-event'
                    required
                  >
                    <option value="pending">Pending</option>
                    <option value="completed">Accepted</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </label>
              </div>
              <div className=".input-field-description">
                <label className=".input-field-description" >
                  Description:
                  <textarea
                    name="description"
                    value={newEvent.description}
                    onChange={handleInputChange}
                    className='input-field-event'
                  />
                </label>
              </div>
            </div>
            <div className="form-button-group-deal">
              <button type="submit" className="submit-button-deal">Add</button>
            </div>
          </form>




        </div>
      </Modal>
    </>
  );
}








export default Event;










