

import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import noDataImage from '../assets/NoDataFound.png';






function PurchaseOrder() {
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null); // For tracking selected row
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);




  const fetchPurchaseOrders = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found. Please log in.');
      return;
    }




    try {
      const response = await fetch('https://api.myntask.com/purchase_order/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });




      if (!response.ok) {
        throw new Error('Failed to fetch purchase orders');
      }




      const data = await response.json();
      setPurchaseOrders(data);
      setIsLoading(false);
    } catch (error) {
      setError('Error fetching purchase orders. Please try again later.');
      setIsLoading(false);
    }
  };




  useEffect(() => {
    fetchPurchaseOrders();
  }, []);




  const handleRowClick = (id) => {
    setSelectedRow(id); // Set selected row by ID
  };




  const handleDownload = async (orderId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found. Please log in.');
      return;
    }




    try {
      const response = await fetch(`https://api.myntask.com/purchase_order/download/${orderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });




      if (!response.ok) {
        throw new Error('Failed to download purchase order');
      }




      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `purchase_order_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      setError('Error downloading purchase order. Please try again later.');
    }
  };




  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };


  const handleDeleteClick = async (orderId) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from local storage


    // Display confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this order?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });


    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.myntask.com/purchase_order/delete/${orderId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass token in the header
          },
        });


        if (!response.ok) {
          throw new Error('Failed to delete the order');
        }


        // Refetch the products list after successful deletion
        await fetchPurchaseOrders();;


        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'Order deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error('Error deleting Order:', error);
        // Show error message
        Swal.fire({
          title: 'Error!',
          text: 'Error deleting Order. Please try again.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
  };


  // Filter purchase orders based on the search query
  const filteredOrders = purchaseOrders.filter((order) => {
    return (
      order.purchase_order_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.primary_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.status.toLowerCase().includes(searchQuery.toLowerCase()) // Include status in the search
    );
  });




  const toTitleCase = (str) => {
    if (!str) return 'N/A';
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };



  const handleStatusUpdate = async (id, status) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage

    if (!token) {
      console.error("No token found in local storage.");
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "No token found. Please log in again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    const requestBody = {
      id,
      status,
    };

    try {
      const response = await fetch(
        "https://api.myntask.com/purchase_order/status_update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error updating status:", errorData.message);
        Swal.fire({
          icon: "error",
          title: "Failed to Update Status",
          text: errorData.message || "An error occurred. Please try again.",
          toast: true,
          position: "top-right",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }

      const result = await response.json();
      console.log(`Status updated successfully to '${status}' .`);

      Swal.fire({
        icon: "success",
        title: "Status Updated",
        text: `Successfully updated status to '${status}' .`,
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Refresh the screen or reload the data
      window.location.reload(); // Refreshes the entire page (alternatively, update the state if using React state management)
    } catch (error) {
      console.error("Failed to update status:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update status. Please try again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }




  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Purchase Orders
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={() => window.location.href = 'AddOrder'}>
            Add Order
          </button>
        </div>
      </div>




      <div className="top-controls">
        <div className="filters-and-search">
          <input
            className="searchbar"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange} // Trigger search on input change
          />
        </div>
      </div>


      {filteredOrders.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Purchase Order Number</th>
              <th>Primary Name</th>
              <th>Purchase Date</th>
              <th>Expected Delivery Date</th>
              <th>Delivery Status</th>
              <th>Status</th>
              <th>Total Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr
                key={order.id}
                onClick={() => handleRowClick(order.id)}
                className={selectedRow === order.id ? 'active' : ''}
              >
                <td>{toTitleCase(order.purchase_order_number || 'N/A')}</td>
                <td>{toTitleCase(order.primary_name || 'N/A')}</td>
                <td>{order.purchase_date ? new Date(order.purchase_date).toLocaleDateString() : 'N/A'}</td>
                <td>{order.expected_delivery_date ? new Date(order.expected_delivery_date).toLocaleDateString() : 'N/A'}</td>
                <td>{toTitleCase(order.delivery_status || 'N/A')}</td>
                <td>{toTitleCase(order.status || 'N/A')}</td>
                <td>₹{order.total_amount || 'N/A'}</td>
                <td>
                  <div className="action-container">
                    <div className="action-dots" onClick={() => toggleActionMenu(order.id)}>
                      <GridMoreVertIcon />
                    </div>
                    {actionMenuVisible === order.id && (
                      <div className="action-menu">
                        <div
                          className="action-item"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            handleDownload(order.id);
                          }}
                        >
                          Download
                        </div>
                        <div
                          className="action-item"
                          onClick={() => handleDeleteClick(order.id)}
                        >
                          Delete
                        </div>
                        <div
                          className="action-item"
                          onClick={() => handleStatusUpdate(order.id, "approved")}
                        >
                          Approved
                        </div>
                        {/* <div
        className="action-item"
        onClick={() => handleStatusUpdate(order.id, "rejected")}
      >
        Rejected
      </div> */}
                      </div>
                    )}
                  </div>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No data found</p>
        </div>
      )}


    </div>
  );
}




export default PurchaseOrder;














