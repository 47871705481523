import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications




const Banner = () => {
 const [content, setContent] = useState('');
 const [message, setMessage] = useState('');




 const handleSave = async () => {
   const token = localStorage.getItem('authToken'); // Retrieving token from localStorage




   if (!token) {
     setMessage('Token not found. Please login again.');
     toast.error('Token not found. Please login again.');
     return;
   }




   try {
     const response = await fetch('https://api.myntask.com/home/banner_save', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}` // Passing token in header
       },
       body: JSON.stringify({ content }),
     });




     // Since the API response is plain text, we'll read it as text
     const result = await response.text();
     console.log('Save successful:', result);




     if (response.ok) {
       setMessage('Save Successful'); // Show success message
       toast.success('Content saved successfully!'); // Show success toast
     } else {
       setMessage('Error: ' + result); // Show error message if the request fails
       toast.error('Error saving content: ' + result); // Show error toast
     }
   } catch (error) {
     console.error('Error saving content:', error);
     setMessage('Error saving content');
     toast.error('Error saving content');
   }
 };




 return (
   <div>
     <h1>Save Banner Content</h1>
     <textarea
       value={content}
       onChange={(e) => setContent(e.target.value)}
       placeholder="Enter content"
     />
     <br />
     <button onClick={handleSave}>Save Content</button>
     {message && <p>{message}</p>}
     <ToastContainer /> {/* Toast container to display toasts */}
   </div>
 );
};




export default Banner;






