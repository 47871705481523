import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

const HRDashboard = () => {
  const [hrData, setHrData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHrData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/dashboard/advanced/hr', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch HR data.');
        }
        const data = await response.json();
        console.log('HR data fetched successfully:', data);
        setHrData(data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load HR data. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchHrData();

  }, []);



  const {
    total_employee = 0,
    today_attendance = 0,
    leave_approved = 0,
    employee_exits = 0,
    average_attendance = 0,
    department_wise_employee_counts = [],
    designation_wise_employee_counts = [],
    gender_wise_employee: { male = 0, female = 0 } = {},
    role_wise_employee = [],
    leave_today = [],
    probation_today = [],
    contract_date = null,
    internship_date = null,
    employee_appreciations = null,
    work_from_home_today = null,
    notice_period_duration = null,
    today_joinings = null,
  } = hrData || {};


  // Data for Department-wise Bar Chart
  const departmentData = {
    labels: department_wise_employee_counts.map(department => department.department_name),
    datasets: [
      {
        label: 'Employees',
        data: department_wise_employee_counts.map(department => department.total),
        backgroundColor: '#4BC0C0',
        borderColor: '#4BC0C0',
        borderWidth: 1,
      },
    ],
  };

  // Data for Designation-wise Bar Chart
  const designationData = {
    labels: designation_wise_employee_counts.map(designation => designation.designation_name),
    datasets: [
      {
        label: 'Employees',
        data: designation_wise_employee_counts.map(designation => designation.total),
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        borderWidth: 1,
      },
    ],
  };

  // Data for Role-wise Bar Chart
  const roleData = {
    labels: role_wise_employee.map(role => role.role_name),
    datasets: [
      {
        label: 'Employees',
        data: role_wise_employee.map(role => role.user_count),
        backgroundColor: '#FFCE56',
        borderColor: '#FFCE56',
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }




  return (
    <div className="main-container" style={{ backgroundColor: '#f3f3f7' }}>
      <div className="top-controls">
        <h1 className='title'>HR Dashboard
          <div className="underline"></div>
        </h1>
      </div>

      <div className="advance-hr-content">
        <div className="advance-row">
          <div className="advance-container-box" onClick={() => handleNavigation('/employee')}>
            <span className="advance-total">Total Employees:</span> <span className="advance-data">{total_employee}</span>
          </div>
          <div className="advance-container-box" onClick={() => handleNavigation('/attendnace')}>
            <span className="advance-total">Today's Attendance:</span> <span className="advance-data">{today_attendance}</span>
          </div>
          <div className="advance-container-box" onClick={() => handleNavigation('/leave')}>
            <span className="advance-total">Approved Leaves:</span> <span className="advance-data">{leave_approved}</span>
          </div>
          <div className="advance-container-box">
            <span className="advance-total">Employee Exits:</span> <span className="advance-data">{employee_exits}</span>
          </div>
          <div className="advance-container-box" onClick={() => handleNavigation('/attendnace')}>
            <span className="advance-total">Average Attendance:</span> <span className="advance-data">{average_attendance}</span>
          </div>
          <div className="advance-container-box">
            <span className="advance-total">Male Employees:</span> <span className="advance-data">{male}</span>
          </div>
          <div className="advance-container-box">
            <span className="advance-total">Female Employees:</span> <span className="advance-data">{female}</span>
          </div>
        </div>

        <div className="advance-row-large">
          <div className="advance-container-box">
            <h3>Department-wise Employee Counts</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Bar data={departmentData} options={barOptions} />
            </div>
          </div>
          <div className="advance-container-box">
            <h3>Designation-wise Employee Counts</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Bar data={designationData} options={barOptions} />
            </div>
          </div>
          <div className="advance-container-box">
            <h3>Role-wise Employee Counts</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Bar data={roleData} options={barOptions} />
            </div>
          </div>
        </div>

        <div className="advance-row-large">
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Leave Today</h4>
            {leave_today.length > 0 ? (
              <ul>
                {leave_today.map((leave, index) => (
                  <li key={index}>{leave.name}: {leave.leave_type}</li>
                ))}
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="advance-hr-detail-item advance-container-box" style={{ marginBottom: '' }}>
            <h4>Probation Today</h4>
            {probation_today.length > 0 ? (
              <ul>
                {probation_today.map((probation, index) => (
                  <li key={index}>{probation.name}: {probation.probation_status}</li>
                ))}
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>

        {/* New Section for Additional Data */}
        <div className="advance-row-large">
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Contract Date</h4>
            {contract_date ? (
              <ul>
                <li>{contract_date}</li>
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Internship Date</h4>
            {internship_date ? (
              <ul>
                <li>{internship_date}</li>
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Employee Appreciations</h4>
            {employee_appreciations ? (
              <ul>
                <li>{employee_appreciations}</li>
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
        <div className="advance-row-large">
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Work From Home Today</h4>
            {work_from_home_today ? (
              <ul>
                <li>{work_from_home_today}</li>
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Notice Period Duration</h4>
            {notice_period_duration ? (
              <ul>
                <li>{notice_period_duration}</li>
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Today Joinings</h4>
            {today_joinings ? (
              <ul>
                <li>{today_joinings}</li>
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};



export default HRDashboard;
