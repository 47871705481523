import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


// Firebase configuration object from your Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyCJzqzR5PPb4P0WGxchjpTPN3nmcHG86wc",
    authDomain: "web-myntask.firebaseapp.com",
    projectId: "web-myntask",
    storageBucket: "web-myntask.appspot.com",
    messagingSenderId: "33854504075",
    appId: "1:33854504075:web:240c6599203b0d9fffe88e",
    measurementId: "G-HFCH1HJ6T4"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);


// Function to request permission and get the FCM token
export const requestForToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: 'BNt8fh1nBo4k7T2UIX12fHHlMWfwdgTKJWz6b-6mhqi7C3LaxY4ydjlJop6hAg3L9TJr4_6mUVmvGqcSf364aUg' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('Client Token#########:', currentToken);
        localStorage.setItem('clientToken', currentToken); // Store the client token in localStorage
        setTokenFound(true);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      setTokenFound(false);
    });
};




// Function to listen for incoming messages when the app is in the foreground
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      resolve(payload);
    });
  });






